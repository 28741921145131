import React from 'react';
import styled from 'styled-components';
import ShortVacancies from './ShortVacancies/ShortVacancies';
import Carousel from '../Template/Carousel/Carousel';

const StyledImportant = styled.section`
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 29px;
      margin-bottom: 25px;
    }
  }
`;

const StyledActions = styled.section`
  margin-top: 25px;

  .title {
    display: flex;
    font-weight: bold;
    font-size: 17px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 20px;

    span {
      padding: 13px;
      padding-left: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: #000000;
      border-radius: 50%;
      margin-left: 19px;
    }

    svg {
      flex-shrink: 0;
    }
  }

  .owl-stage > div {
    height: 100%;
  }
`;

const MainPage: React.FunctionComponent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          <aside>
            <ShortVacancies />
          </aside>
        </div>
        <div className="col-xl-9 col-lg-8">
          <StyledImportant>
            <h2 className="title">Важное</h2>
            <Carousel type="important" />
          </StyledImportant>
          <StyledActions>
            <h3 className="title">
              Действия
              <span>
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.58706 4.12835L0.834483 0.751034C0.512722 0.461449 4.46685e-07 0.689795 4.27763e-07 1.12268L1.32509e-07 7.87732C1.13587e-07 8.3102 0.512722 8.53855 0.834483 8.24897L4.58706 4.87165C4.80775 4.67303 4.80775 4.32697 4.58706 4.12835Z"
                    fill="white"
                  />
                </svg>
              </span>
            </h3>
            <Carousel type="actions" />
          </StyledActions>
        </div>
      </div>
    </div>
  );
};
export default MainPage;
