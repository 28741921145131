import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useParams } from 'react-router';
import { useActions } from '../../../../../hooks/useActions';
import useTypedSelector from '../../../../../hooks/useTypedSelector';
import { IConfiguration } from '../../../../../types/connectorB24';
import StyledLink from '../../../../Template/StyledComponents/StyledLink';
import IntegrationSelect from '../IntegrationSelect/IntegrationSelect';
import IntegrationWebhook from '../IntegrationWebhook/IntegrationWebhook';
import * as Styled from './StyledIntegrationSettings';

interface IntegrationSettingProps {}

const IntegrationSetting: React.FunctionComponent<IntegrationSettingProps> = () => {
  const [webhookValue, setWebhookValue] = useState('');
  const [webhookSaved, setWebhookSaved] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const params: { id: string | undefined } = useParams();
  const companyId = params.id ? params.id : '';
  const {
    setCompanyWebhook,
    setCompanyConfiguration,
    getDirections,
    getStatuses,
    getVacancyFields,
    getCompanyConfiguration,
    resetCompanyConfiguration,
  } = useActions();

  const {
    configuration,
    directions,
    statuses,
    vacancyFields,
    errors,
    loading,
  } = useTypedSelector(r => r.connectorB24);

  const [config, setConfig] = useState<IConfiguration>(
    configuration !== null
      ? configuration
      : {
          company_id: companyId,
          funnel_id: '',
          stage_xml_id: '',
          vacancy_id_link: '',
        },
  );

  const getOptions = async () => {
    await getDirections();
    await getStatuses(configuration.funnel_id);
    await getVacancyFields();
  };

  useEffect(() => {
    getCompanyConfiguration();
    return () => {
      resetCompanyConfiguration();
    };
  }, []);

  useEffect(() => {
    if (
      configuration !== null &&
      configuration.webhook &&
      !configuration.funnel_id
    ) {
      setWebhookSaved(true);
      getDirections();
    }
    if (configuration !== null && configuration.funnel_id) {
      setWebhookSaved(true);
      setConfig(configuration);
      getOptions();
      setShowSaveBtn(true);
    }
  }, [configuration]);

  const setConfigWebhook = async () => {
    await setCompanyWebhook(
      {
        company_id: companyId,
        webhook: webhookValue,
      },
      setWebhookSaved,
    );
    if (!errors.webhook && !loading.webhook) getDirections();
  };

  const setDirection = (evt: React.FormEvent<HTMLSelectElement>) => {
    setConfig({
      ...config,
      [evt.currentTarget.name]: evt.currentTarget.value,
    });
    getStatuses(evt.currentTarget.value);
  };

  const setStatus = (evt: React.FormEvent<HTMLSelectElement>) => {
    setConfig({
      ...config,
      [evt.currentTarget.name]: evt.currentTarget.value,
    });
    getVacancyFields();
  };

  const setVacancyField = (evt: React.FormEvent<HTMLSelectElement>) => {
    setConfig({
      ...config,
      [evt.currentTarget.name]: evt.currentTarget.value,
    });
    setShowSaveBtn(true);
  };

  const saveConfiguration = () => {
    const { webhook, incoming_token: token, ...configSettings } = config;
    setCompanyConfiguration(configSettings);
  };

  return (
    <form className="form" action="">
      <IntegrationWebhook
        value={webhookValue}
        setValue={setWebhookValue}
        setConfigWebhook={setConfigWebhook}
        error={errors.webhook}
        loading={loading.webhook}
        webhookSaved={webhookSaved}
      />
      <IntegrationSelect
        label="Направление"
        name="funnel_id"
        placeholder="Выберите направление из списка"
        handleChange={setDirection}
        options={directions}
        error={errors.directions}
        loading={loading.directions}
        initialValue={config.funnel_id.toString()}
      />
      <IntegrationSelect
        label="Статус после прохождения тестирования"
        name="stage_xml_id"
        placeholder="Выберите статус из списка"
        handleChange={setStatus}
        options={statuses}
        error={errors.statuses}
        loading={loading.statuses}
        initialValue={config.stage_xml_id.toString()}
      />
      <IntegrationSelect
        label="Выбор поля вакансии"
        name="vacancy_id_link"
        placeholder="Выберите поле из списка"
        handleChange={setVacancyField}
        options={vacancyFields}
        error={errors.vacancyFields}
        loading={loading.vacancyFields}
        initialValue={config.vacancy_id_link.toString()}
      />
      {showSaveBtn && (
        <Styled.ButtonWrapper>
          <Styled.ButtonWrap>
            <StyledLink
              button
              text="Сохранить настройки"
              type="button"
              handleСlick={saveConfiguration}
              disabled={loading.configuration}
            />
            {loading.configuration && (
              <Loader type="Audio" color="#828282" height="16px" width="16px" />
            )}
          </Styled.ButtonWrap>
          {errors.configuration && (
            <p className="error">{errors.configuration}</p>
          )}
        </Styled.ButtonWrapper>
      )}
    </form>
  );
};

export default IntegrationSetting;
