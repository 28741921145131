import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 20px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    grid-template-columns: 400px 1fr;
    align-items: end;
  }

  @media (min-width: 992px) {
    gap: 24px;
    grid-template-columns: 550px 1fr;
  }

  .label {
    display: block;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  .text {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #828282;

    @media (min-width: 768px) {
      max-width: 400px;
      grid-column: 1 / 2;
    }
  }

  .btn-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 320px;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }

    @media (min-width: 768px) {
      max-width: 400px;
      grid-column: 1 / -1;
    }

    @media (min-width: 992px) {
      max-width: 550px;
    }
  }

  .token-input {
    @media (min-width: 768px) {
      grid-column: 2 / 3;
    }
  }

  .loader {
    display: block;
    padding: 10px;
  }

  .error {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    color: #ff6363;
  }
`;
