import React from 'react';

import Select from 'react-select';
import { ISelectType } from '../../../types/vacancies';

const SelectComponent: React.FunctionComponent<{
  multi: boolean;
  options: ISelectType[];
  handleChange: (val: ISelectType) => void;
  initialOption: ISelectType[];
}> = ({ multi, options, handleChange, initialOption }) => {
  return (
    <>
      <Select
        key={initialOption as any}
        defaultValue={initialOption}
        onChange={(e: any) => handleChange(e)}
        isMulti={multi}
        id="department-flag"
        placeholder="Выберите тест"
        options={options}
      />
    </>
  );
};
export default React.memo(SelectComponent);
