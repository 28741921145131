import React from 'react';
import styled from 'styled-components';

const StyledSearchComponent = styled.form`
  width: 100%;
  position: relative;

  input {
    display: block;
    width: 100%;
    font: inherit;
    font-size: 13px;
    line-height: 20px;
    text-shadow: none;
    border: 1px solid #cdcede;
    border-radius: 25px;
    padding: 10px 15px 10px 45px;
    outline: none;
    transition: 0.5s border-color;

    &:hover,
    &:focus {
      border-color: #828282;
    }

    &::placeholder {
      color: #bdbdbd;
    }

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 21px;
    }
  }

  .submit-btn {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

interface CustomStyles {
  width: string | number;
}

interface PropsInterface {
  text?: string;
  additionalClass?: string;
  inlineStyles?: CustomStyles;
  placeholder?: string;
}

const StyledSearch: React.FC<PropsInterface> = ({
  additionalClass,
  inlineStyles,
  placeholder,
}) => {
  return (
    <StyledSearchComponent className={additionalClass} style={inlineStyles}>
      <label htmlFor="search" className="visually-hidden">
        Поиск
      </label>
      <input id="search" type="text" name="search" placeholder={placeholder} />
      <button className="submit-btn" type="submit">
        <svg
          width="15"
          height="15"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4586 10.0193L7.84054 7.29639C8.51367 6.49619 8.88249 5.48938 8.88249 4.44125C8.88249 1.99238 6.89011 0 4.44125 0C1.99238 0 0 1.99238 0 4.44125C0 6.89011 1.99238 8.88249 4.44125 8.88249C5.36058 8.88249 6.23667 8.6052 6.98569 8.07882L9.6236 10.8224C9.73386 10.9369 9.88216 11 10.0411 11C10.1915 11 10.3342 10.9427 10.4425 10.8384C10.6727 10.6169 10.68 10.2496 10.4586 10.0193ZM4.44125 1.15859C6.25134 1.15859 7.72391 2.63115 7.72391 4.44125C7.72391 6.25134 6.25134 7.72391 4.44125 7.72391C2.63115 7.72391 1.15859 6.25134 1.15859 4.44125C1.15859 2.63115 2.63115 1.15859 4.44125 1.15859Z"
            fill="#828282"
          />
        </svg>
      </button>
    </StyledSearchComponent>
  );
};

export default StyledSearch;
