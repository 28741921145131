import produce from 'immer';
import { AutomaticActionTypes } from '../../types/automatic';

const automaticReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case AutomaticActionTypes.FETCH_AVAILABLE_COUNT: {
        draft.availible = action.payload;
        return draft;
      }
      case AutomaticActionTypes.SET_AUTOLOAN_COUNT: {
        draft.autoloanQuantity = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    availible: 0,
    autoloanQuantity: null,
  },
);

export default automaticReducer;
