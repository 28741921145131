import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledToggle from '../../Template/StyledComponents/StyledToggle';
import ShortVacanciesItem from './ShortVacanciesItem';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { Vacancy } from '../../../types/vacancies';
import VacancyDisplayHead from '../../Template/VacancyDisplay/VacancyDisplayHead';
import VacancyDisplayTitle from '../../Template/VacancyDisplay/VacancyDisplayTitle';
import VacancyDisplayList from '../../Template/VacancyDisplay/VacancyDisplayList';
import arrow from '../../../assets/image/arrow-down-green.svg';
import { useActions } from '../../../hooks/useActions';

const VacancyDisplaySection = styled.div`
  margin-top: 40px;
`;

const VacancyDisplayLoaderWrap = styled.div`
  min-height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VacancyDisplayShowAll = styled.button`
  display: flex;
  font: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 132px;
  margin: 10px auto 20px;
  color: #27ae60;
  cursor pointer;

  &:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    border: 1px solid #27ae60;
    border-radius: 50%;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px auto;
  }

  &:hover, 
  &:focus {
    color: #27ae60;
    text-decoration: underline;
  }
`;

const ShortVacancies: React.FunctionComponent = () => {
  const { vacancies, loaded, totalResult } = useTypedSelector(
    r => r.mainData.vacancies,
  );
  const [vacNotifications, setVacNotifications] = useState(false);

  const handleVacNotifications = () => {
    setVacNotifications(!vacNotifications);
  };

  const showMore = 10;
  const { fetchVacanciesMain } = useActions();
  const [limit, setLimit] = useState<number>(showMore);
  useEffect(() => {
    fetchVacanciesMain(limit, vacNotifications);
  }, [limit, vacNotifications]);

  return (
    <VacancyDisplaySection className="vacancy-display main__vacancy-display">
      <VacancyDisplayHead modifiedItem>
        <VacancyDisplayTitle count={totalResult} modifiedItem={false}>
          Мои вакансии
        </VacancyDisplayTitle>
        <StyledToggle
          handleCLick={handleVacNotifications}
          active={vacNotifications}
        />
      </VacancyDisplayHead>
      {loaded ? (
        <VacancyDisplayList id="example-1" modifiedItem={false}>
          {vacancies.map((r: Vacancy) => (
            <ShortVacanciesItem vacancy={r} key={r.id} />
          ))}
        </VacancyDisplayList>
      ) : (
        <VacancyDisplayLoaderWrap>
          <Loader type="TailSpin" color="#000" height={40} width={40} />
        </VacancyDisplayLoaderWrap>
      )}
      {totalResult - limit > 0 && (
        <VacancyDisplayShowAll
          type="button"
          onClick={() => setLimit(prev => prev + showMore)}
        >
          <span>ещё</span>
          <span className="show-number">
            &nbsp;{totalResult - limit > 10 ? 10 : totalResult - limit}&nbsp;
          </span>
          вакансии
        </VacancyDisplayShowAll>
      )}
      <StyledLink text="Смотреть остальные вакансии" path="/"></StyledLink>
    </VacancyDisplaySection>
  );
};

export default ShortVacancies;
