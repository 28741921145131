export interface IntegrationService {
  path: string;
  name: string;
  text: string;
  color: string;
  logo: string;
  background: string;
}

export interface MyFormValues {
  name: string;
  city: string;
  time_zone: string;
  address: string;
  email: string;
  phone: string;
  description: string;
  color: string;
  image: string;
  logo:
    | {
        body: string;
        name: string;
      }
    | string
    | boolean;
  video:
    | {
        body: string;
        name: string;
      }
    | string
    | boolean;
}

export interface CompaniesDataType {
  id: string;
  name: string;
  city: string;
  time_zone: string;
  address: string;
  email: string;
  phone: string;
  description: string;
  color: string;
  image: string;
  logo: string | boolean;
  video: string | boolean;
}

export interface CompanyCity {
  id: string;
  name: string;
}

export interface CompanyTomeZone {
  TITLE: string;
  VALUE: string;
}

export interface StaffRuleList {
  id: string;
  name: string;
}

export interface CompanyColorsType {
  id: string;
  color: string;
}

export interface StaticDataType {
  ruleList: StaffRuleList[];
  ruleAction: StaffRuleList[];
  companyColors: CompanyColorsType[];
}

export interface EditCompany {
  id: number;
  name: string;
  city: string;
  email: string;
  time_zone: string;
  address: string;
  phone: string;
  description: string;
  color: string;
  image: string;
}

export interface CompanyStaff {
  user_id: string;
  type: string;
  name: string;
  last_name: string;
  rule_vacancy: string;
  rule_resume: string;
  rule_interview: string;
  ualias_0: string;
  companyId: string;
  ualias_1: string;
}

export enum CompaniesActionTypes {
  FETCH_COMPANIES = 'FETCH_COMPANIES',
  FETCH_COMPANIES_STAFF = 'FETCH_COMPANIES_STAFF',
  FETCH_COMPANY_EDIT = 'FETCH_COMPANY_EDIT',
  SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS',
  RESET_COMPANY_SUCCESS = 'RESET_COMPANY_SUCCESS',
  RESET_COMPANY_STAFF = 'RESET_COMPANY_STAFF',
  FETCH_COMPANY_STAFF_ID = 'FETCH_COMPANY_STAFF_ID',
  SET_COMPANY_STAFF_CHANGE = 'SET_COMPANY_STAFF_CHANGE',
  FETCH_COMPANY_CITY = 'FETCH_COMPANY_CITY',
}

interface EditCompanyActionsSuccess {
  type: CompaniesActionTypes.FETCH_COMPANY_EDIT;
  payload: EditCompany;
}

interface EditCompanyActionsChangeSuccess {
  type: CompaniesActionTypes.SET_COMPANY_SUCCESS;
}

interface ResetCompanyEditActions {
  type: CompaniesActionTypes.RESET_COMPANY_SUCCESS;
}

interface FetchCompanies {
  type: CompaniesActionTypes.FETCH_COMPANIES;
  payload: CompaniesDataType;
}

interface FetchCompaniesStaffId {
  type: CompaniesActionTypes.FETCH_COMPANY_STAFF_ID;
  payload: string;
}

interface FetchCompaniesStaffDelete {
  type: CompaniesActionTypes.SET_COMPANY_STAFF_CHANGE;
  payload: string;
}

interface ResetCompanies {
  type: CompaniesActionTypes.RESET_COMPANY_STAFF;
}

interface FetchViewCompanyStaff {
  type: CompaniesActionTypes.FETCH_COMPANIES_STAFF;
  payload: {
    result: CompanyStaff[];
  };
}

interface FetchCompanyCityAction {
  type: CompaniesActionTypes.FETCH_COMPANY_CITY;
  payload: CompanyCity[];
}

export type CompaniesActions =
  | FetchCompanies
  | FetchViewCompanyStaff
  | EditCompanyActionsSuccess
  | EditCompanyActionsChangeSuccess
  | ResetCompanyEditActions
  | FetchCompaniesStaffId
  | FetchCompaniesStaffDelete
  | ResetCompanies
  | FetchCompanyCityAction;
