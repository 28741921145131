import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  StyledTitle,
  StyledSubmitBtnWrap,
  StyledLoginText,
  StyledItemWrap,
  StyledAuthFormSection,
  StyledAuthFormDiv,
} from '../StyledLoginComponents/StyledLoginComponents';
import StyledLabel from '../../Template/StyledComponents/StyledLabel';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import LoginHeader from '../../LoginHeader/LoginHeader';
import { phoneRegExp } from '../../Common/Data';
import { getValidation } from '../RegisterConfirmation/RegistrationForm';
import { setAuthorization } from '../../../store/actions/auth';
import { IAuthorization, ILoginProps } from '../../../types/mainPage';
import useTypedSelector from '../../../hooks/useTypedSelector';

const StyledHelpLink = styled.a`
  display: block;
  margin-bottom: 27px;
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
  color: #828282;

  @media (min-width: 992px) {
    margin-bottom: 38px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: #828282;
  }
`;

const StyledResultText = styled.p<{
  success?: boolean;
}>`
  margin: 0;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  color: ${props => (props.success ? '#27ae60' : '#ff6363')};

  svg {
    margin-right: 10px;
  }
`;

const errorIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#FF6363" />
    <path
      d="M5.68701 2.56787L5.81592 7.76709H7.16943L7.29834 2.56787H5.68701ZM6.49268 10.4526C6.98145 10.4526 7.36816 10.082 7.36816 9.60938C7.36816 9.13672 6.98145 8.76611 6.49268 8.76611C6.00391 8.76611 5.61719 9.13672 5.61719 9.60938C5.61719 10.082 6.00391 10.4526 6.49268 10.4526Z"
      fill="white"
    />
  </svg>
);

const AuthorizationForm: React.FunctionComponent<ILoginProps> = ({
  setAuth,
}) => {
  const dispatch = useDispatch();
  const handleClickLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setAuth(true);
  };
  const { authError } = useTypedSelector(r => r.auth);
  return (
    <>
      <div className="container">
        <Formik
          validationSchema={Yup.object().shape({
            password: Yup.string().required('Вы не заполнили поле'),
            login: Yup.string().required('Вы не заполнили поле'),
          })}
          enableReinitialize
          onSubmit={(values: IAuthorization) => {
            dispatch(setAuthorization(values));
          }}
          initialValues={{
            login: '',
            password: '',
          }}
        >
          {({ errors, touched }) => (
            <Form className="pt-lg-5">
              <StyledAuthFormSection>
                <StyledTitle>Войти</StyledTitle>
                <StyledAuthFormDiv>
                  <StyledItemWrap>
                    <StyledLabel text="Логин" htmlFor="authorize-tel" />
                    <StyledInput
                      field
                      id="authorize-tel"
                      type="text"
                      name="login"
                      placeholder="Логин"
                      inlineStyles={{
                        padding: '16px 20px 12px 20px',
                      }}
                    />
                  </StyledItemWrap>
                  <StyledItemWrap>
                    <StyledLabel text="Пароль" htmlFor="authorize-password" />
                    <StyledInput
                      field
                      id="authorize-password"
                      type="password"
                      name="password"
                      placeholder="**********"
                      inlineStyles={{
                        padding: '16px 20px 12px 20px',
                      }}
                    />
                  </StyledItemWrap>
                  <StyledHelpLink href="/">Забыли пароль?</StyledHelpLink>
                  {getValidation(
                    !Object.keys(errors).length,
                    !Object.keys(touched).length,
                  )}
                  {authError.type === 'LOGIN' ? (
                    <StyledResultText>
                      {errorIcon}
                      <span>{authError.message}</span>
                    </StyledResultText>
                  ) : (
                    ''
                  )}
                  <StyledSubmitBtnWrap>
                    <StyledLink text="Войти" button type="submit" />
                  </StyledSubmitBtnWrap>
                  <StyledLoginText>
                    Нет аккаунта?{' '}
                    <Link
                      to="/"
                      onClick={(
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                      ) => handleClickLink(e)}
                    >
                      Зарегистрироваться
                    </Link>
                  </StyledLoginText>
                </StyledAuthFormDiv>
              </StyledAuthFormSection>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default AuthorizationForm;
