import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import VacancyQuestionnaireItem from './VacancyQuestionnaireItem';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { ICVRes } from '../../../types/vacancies';
import Pagination from '../../Common/pagination';
import StyledSearch from '../../Template/StyledComponents/StyledSearch';
import Loader from '../../Template/Loader/Loader';
import { useFetching } from '../../../hooks/useFetching';
import { useActions } from '../../../hooks/useActions';
import DataStatus from '../../Common/DataStatus/DataStatus';

const StyledSection = styled.section`
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;

const StyledTitleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 20px;
  margin-bottom: 13px;
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 457px;
    grid-column-gap: 100px;
    margin-bottom: 37px;
  }
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 0;
  }
`;

const StyledCardsWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 20px;
  margin-bottom: 20px;
`;

const VacancyQuestionnaire: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const params: { vacancy_id?: string; id?: string } = useParams();
  const [opened, setOpened] = useState(0);
  const { cvs } = useTypedSelector(r => r.vacancies.vacancy);
  const [count, setCount] = useState(0);
  const limit = 4;
  const { fetchListCVS } = useActions();

  const [fetchListCvsNew, isCvsLoading, cvsError] = useFetching(() =>
    fetchListCVS(count, limit, {
      vacancy_id: params.vacancy_id,
      id: params.id,
      status: 'success',
    }),
  );

  useEffect(() => {
    fetchListCvsNew();
  }, [count]);

  // const handleChangeCount = (value: number) => {
  //   if (count === cvs.cvs.length + 1) return;
  //   setCount(prev => (prev === 0 && value < 0 ? 0 : prev + value));
  // };

  const handleChangeCount = (value: number) => {
    setCount(prev => {
      switch (value) {
        case 1:
          if (prev + 1 === Math.ceil(Number(cvs.count) / limit)) {
            return prev;
          }
          return prev + 1;

        case -1:
          if (prev === 0) {
            return prev;
          }
          return prev - 1;
        default:
          return prev;
      }
    });
  };

  const handleOpen = (id: number) => {
    setOpened(id);
  };

  console.log(cvs);

  return (
    <StyledSection className="container content-system-section">
      <StyledTitleWrap>
        <StyledTitle>Анкеты кандидатов</StyledTitle>
        <StyledSearch />
      </StyledTitleWrap>
      <DataStatus loading={isCvsLoading} error={cvsError} items={cvs.cvs}>
        <StyledCardsWrapper>
          {cvs.cvs.map((n: ICVRes) => (
            <VacancyQuestionnaireItem
              key={n.id}
              item={n}
              opened={opened}
              handleOpen={handleOpen}
            />
          ))}
        </StyledCardsWrapper>
        {cvs.count > limit && (
          <Pagination
            companiesPage={Math.ceil(Number(cvs.count) / limit)}
            count={count}
            handleChangeCount={handleChangeCount}
          />
        )}
      </DataStatus>
    </StyledSection>
  );
};

export default VacancyQuestionnaire;
