import React from 'react';

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }).then(data => data);
};

export const getPictureSize = (base64string: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64string;
    image.onload = (event: any) => {
      resolve({
        width: event.currentTarget.width,
        height: event.currentTarget.height,
      });
    };
  }).then(data => data);
};

export const checkFileSize = (
  fileSize: any,
  size: {
    width: number;
    height: number;
  },
): boolean => {
  if (fileSize.width === size.width && fileSize.height === size.height) {
    return false;
  }
  return true;
};
export const FILE_TYPES = ['png', 'jpg', 'jpeg', 'svg'];

export const checkDataType = (fileName: string, formats: string[]) => {
  return formats.some(it => {
    return fileName.endsWith(it);
  });
};
