import * as React from 'react';
import image2 from '../../../assets/image/build-2.png';
import image1 from '../../../assets/image/build.png';
import image3 from '../../../assets/image/build-3.png';
import image4 from '../../../assets/image/build-4.png';
import image5 from '../../../assets/image/build-5.png';

export const colorsData = [
  { color: 'blue', index: '1' },
  { color: 'green', index: '2' },
  { color: 'violet', index: '3' },
  { color: 'darkBlue', index: '4' },
  { color: 'darkGreen', index: '5' },
];
export const companiesBg = [
  { image: image2, index: '2' },
  { image: image1, index: '1' },
  { image: image3, index: '3' },
  { image: image4, index: '4' },
  { image: image5, index: '5' },
];
