import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import useTypedSelector from '../../../../../hooks/useTypedSelector';
import StyledInput from '../../../../Template/StyledComponents/StyledInput';
import StyledLink from '../../../../Template/StyledComponents/StyledLink';
import * as Styled from './StyledIntegrationWebhook';

interface IntegrationWebhookProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setConfigWebhook: () => void;
  error: string;
  loading: boolean;
  webhookSaved: boolean;
}

const IntegrationWebhook: React.FunctionComponent<IntegrationWebhookProps> = ({
  value,
  setValue,
  setConfigWebhook,
  error,
  loading,
  webhookSaved,
}) => {
  const [emptyField, setEmptyField] = useState(false);

  const { configuration } = useTypedSelector(r => r.connectorB24);

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.currentTarget.value);
  };

  const handleResetInput = () => {
    setValue('');
  };

  const handleButtonClick = () => {
    if (!value) {
      setEmptyField(true);
    } else {
      setEmptyField(false);
      setConfigWebhook();
    }
  };

  return (
    <Styled.Wrapper>
      <div className="input-wrapper">
        <label className="label" htmlFor="webhook">
          Входящий вебхук
        </label>
        <StyledInput
          input
          id="webhook"
          placeholder="www.site.ru/khfkdsfjlsdjflsdfjksdlfkdslfksd..."
          type="text"
          name="webhook"
          value={value}
          handleChange={handleInputChange}
          errorFlag={emptyField}
        />
      </div>
      <div className="token-input">
        {loading && (
          <span className="loader">
            <Loader type="Oval" color="#828282" height={25} width={25} />
          </span>
        )}
        {error && <p className="error">{error}</p>}
        {webhookSaved && (
          <StyledInput
            input
            id="token"
            placeholder="www.site.ru"
            type="text"
            name="token"
            readonlyFlag
            value={configuration ? configuration.incoming_token : ''}
          />
        )}
      </div>
      <p className="text">
        Вставьте ссылку, полученную на странице создания входящего вебхука в
        Битрикс24.
      </p>

      {!webhookSaved && (
        <div className="btn-wrapper">
          <StyledLink
            button
            text="Следующий шаг"
            type="button"
            handleСlick={handleButtonClick}
          />
          <StyledLink
            button
            text="Отменить"
            type="reset"
            $whiteBackground
            $border
            handleСlick={handleResetInput}
          />
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default IntegrationWebhook;
