import styled from 'styled-components';

export const StyledAddForm = styled.section`
  padding-top: 38px;
  padding-bottom: 19px;

  @media (max-width: 768px) {
    padding-top: 24px;
  }
`;

export const StyledWrap = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }
`;

export const StyledItemWrap = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const StyledUploadWrap = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
`;

export const StyledBlocks = styled.div`
  display: flex;
  max-width: 340px;
  justify-content: space-between;
  margin-bottom: 21px;
`;

export const StyledColorBlock = styled.button`
  display: block;
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  border: none;

  &:hover,
  &:focus {
    filter: brightness(1.1);
  }

  span {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    display: block;
  }
  &.active {
    border: 4px solid #fedc2d;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #ffffff;
    span {
      width: 36px;
      height: 36px;
    }
  }

  @media (max-width: 768px) {
    width: 44px;
    height: 44px;
    span {
      width: 44px;
      height: 44px;
    }
    &.active {
      border: 3px solid #fedc2d;

      span {
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const StyledCompanyBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;

  @media (min-width: 768px) {
    grid-column-gap: 15px;
    grid-row-gap: 24px;
  }
`;

export const StyledCompanyBlock = styled.button`
  position: relative;
  display: block;
  min-height: 92px;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;

  @media (min-width: 768px) {
    min-height: 175px;
  }

  &:hover,
  &:focus {
    filter: brightness(1.1);
  }

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(254, 220, 45, 1);

    @media (min-width: 768px) {
      border: 4px solid rgba(254, 220, 45, 1);
    }
  }

  .wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #67b7f0;
    border-radius: 5px;
  }

  &.active .wrapper {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 85px;
    height: 85px;

    @media (min-width: 768px) {
      width: 180px;
      height: 177px;
    }
  }

  &.active img {
    right: -12px;
    bottom: -12px;
  }
`;

export const StyledButtonWrap = styled.div`
  position: relative;
  max-width: 293px;
  margin-left: auto;
  margin-right: auto;

  & > div {
    position: absolute;
    right: 40px;
    top: 10px;
  }

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`;

export const StyledErrorMessageWrap = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0;
  font-size: 12px;
  line-height: 14px;
  color: #ff6363;
`;
