import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { Currency, Vacancy } from '../../../types/vacancies';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledInputRadio from '../../Template/StyledComponents/StyledInputRadio';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  StyledTitle,
  StyledLabel,
  StyledInputWrap,
  StyledInputWrapper,
  StyledSmallCol,
  StyledRadioInputFieldset,
  StyledRadioInputWrap,
  StyledRadioInputLabel,
  StyledButtonWrap,
  StyledContainer,
} from '../StyledVacanciesComponents/StyledVacanciesComponents';
import { fetchCompanies } from '../../../store/actions/mainPage';

const EditVacancy: React.FunctionComponent = () => {
  const { singleVacancy } = useTypedSelector(r => r.vacancies.vacancy);
  const { companies } = useTypedSelector(r => r.mainData.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const { updateVacancy } = useActions();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [vacancy, setVacancy] = useState(singleVacancy);
  useEffect(() => {
    setVacancy(singleVacancy);
  }, [singleVacancy]);
  const handleEditInput = (name: string, value: string | number) => {
    setVacancy((prevState: Vacancy) => {
      const eo = {
        [name]: value,
      };
      return { ...prevState, ...eo };
    });
  };

  const handleSaveVacancy = () => {
    setButtonLoader(true);
    const updWithLoader = async () => {
      try {
        await updateVacancy(singleVacancy.id, vacancy);
      } finally {
        setButtonLoader(false);
      }
    };

    setTimeout(() => {
      updWithLoader();
    }, 2000);
  };

  return (
    <>
      <StyledContainer className="container">
        <div className="row align-items-center">
          <div className="col-lg-3">
            <StyledTitle>О вакансии</StyledTitle>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-lg-6">
              <StyledInputWrap>
                <StyledLabel htmlFor="vacancy-name">
                  Название вакансии
                </StyledLabel>
                <StyledInput
                  input
                  id="vacancy-name"
                  type="text"
                  name="name"
                  placeholder="Название вакансии"
                  defaultValue={vacancy.name}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleEditInput(e.target.name, e.target.value);
                  }}
                />
              </StyledInputWrap>
              <StyledInputWrap>
                <StyledLabel htmlFor="company-name">Компания</StyledLabel>
                <StyledSelect id="company-name" name="company">
                  {companies.map((cmp: any) => {
                    console.log(vacancy.company_id, cmp.id);
                    return (
                      <option
                        value={cmp.id}
                        selected={vacancy.company_id === cmp.id}
                      >
                        {cmp.name}
                      </option>
                    );
                  })}
                </StyledSelect>
              </StyledInputWrap>
              <StyledInputWrap as="fieldset">
                <StyledLabel as="legend">Заработная плата</StyledLabel>
                <div className="row">
                  <StyledSmallCol className="col-xl-5 col-sm-4 col-4">
                    <label className="visually-hidden" htmlFor="id-from">
                      От
                    </label>
                    <StyledInput
                      input
                      id="id-from"
                      type="number"
                      name="salary_from"
                      placeholder="от"
                      value={vacancy.salary_from}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleEditInput(e.target.name, e.target.value);
                      }}
                    />
                  </StyledSmallCol>
                  <StyledSmallCol className="col-xl-5 col-sm-4 col-4">
                    <label className="visually-hidden" htmlFor="id-to">
                      До
                    </label>
                    <StyledInput
                      input
                      id="id-to"
                      type="number"
                      name="salary_to"
                      placeholder="до"
                      value={vacancy.salary_to}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleEditInput(e.target.name, e.target.value);
                      }}
                    />
                  </StyledSmallCol>
                  <div className="col-xl-2 col-sm-4 col-4">
                    <label className="visually-hidden" htmlFor="id-currency">
                      Валюта
                    </label>
                    <StyledSelect
                      id="id-currency"
                      handleChange={(
                        e: React.ChangeEvent<HTMLSelectElement>,
                      ) => {
                        handleEditInput(e.target.name, e.target.value);
                      }}
                      name="currency_code"
                      value={vacancy.currency_code}
                      inlineStyles={{ padding: '14px 26px 14px 8px' }}
                    >
                      <option value={Currency.RUB}>РУБ</option>
                      <option value={Currency.EUR}>EUR</option>
                      <option value={Currency.USD}>USD</option>
                    </StyledSelect>
                  </div>
                </div>
              </StyledInputWrap>
              <StyledInputWrap>
                <StyledLabel htmlFor="vacancy-link">
                  Ссылка на источник размещения
                </StyledLabel>
                <StyledInput
                  input
                  id="vacancy-link"
                  textarea
                  name="url"
                  inlineStyles={{ height: 92 }}
                  placeholder="Ссылка на источник размещения"
                  value={vacancy.url}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleEditInput(e.target.name, e.target.value);
                  }}
                />
                <StyledRadioInputFieldset>
                  <legend className="visually-hidden">
                    Источник размещения
                  </legend>
                  <StyledRadioInputWrap>
                    <input
                      type="radio"
                      id="linkHH"
                      name="vacancyInfo6"
                      defaultChecked
                      className="visually-hidden"
                    />
                    <StyledRadioInputLabel htmlFor="linkHH">
                      hh
                    </StyledRadioInputLabel>
                  </StyledRadioInputWrap>
                  <StyledRadioInputWrap>
                    <input
                      type="radio"
                      id="linkSuperjob"
                      name="vacancyInfo6"
                      className="visually-hidden"
                    />
                    <StyledRadioInputLabel htmlFor="linkSuperjob">
                      superjob
                    </StyledRadioInputLabel>
                  </StyledRadioInputWrap>
                  <StyledRadioInputWrap>
                    <input
                      type="radio"
                      id="linkRabota"
                      name="vacancyInfo6"
                      className="visually-hidden"
                    />
                    <StyledRadioInputLabel htmlFor="linkRabota">
                      rabota
                    </StyledRadioInputLabel>
                  </StyledRadioInputWrap>
                  <StyledRadioInputWrap>
                    <input
                      type="radio"
                      id="linkOther"
                      name="vacancyInfo6"
                      className="visually-hidden"
                    />
                    <StyledRadioInputLabel htmlFor="linkOther">
                      Другое
                    </StyledRadioInputLabel>
                  </StyledRadioInputWrap>
                </StyledRadioInputFieldset>
              </StyledInputWrap>
              <StyledInputWrap>
                <StyledLabel htmlFor="vacancy-requirements">
                  Требования
                </StyledLabel>
                <StyledInput
                  input
                  id="vacancy-requirements"
                  textarea
                  name="text_requirements"
                  value={vacancy.text_requirements}
                  inlineStyles={{ height: 179 }}
                  placeholder="Правила организации вашего коллектива, необходимые знания..."
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleEditInput(e.target.name, e.target.value);
                  }}
                />
              </StyledInputWrap>
            </div>
            <div className="col-lg-6">
              <StyledInputWrap as="fieldset">
                <StyledLabel as="legend">График</StyledLabel>
                <StyledInputWrapper>
                  {dictionaries.vacancySchedule?.map(
                    (item: { id: number; title: string | null }) => {
                      return (
                        <StyledInputRadio
                          key={item.id}
                          id={item.id}
                          idPrefix="schedule"
                          name="type_schedule"
                          flag={vacancy.type_schedule}
                          handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            handleEditInput(
                              e.target.name,
                              Number(e.target.value),
                            );
                          }}
                          text={item.title}
                        />
                      );
                    },
                  )}
                </StyledInputWrapper>
              </StyledInputWrap>
              <StyledInputWrap as="fieldset">
                <StyledLabel as="legend">Тип вакансии</StyledLabel>
                <StyledInputWrapper>
                  {dictionaries.vacancyLocation?.map(
                    (item: { id: number; title: string | null }) => (
                      <StyledInputRadio
                        key={item.id}
                        id={item.id}
                        idPrefix="vacancytype"
                        name="type_location"
                        flag={vacancy.type_location}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleEditInput(
                            e.target.name,
                            Number(e.target.value),
                          );
                        }}
                        text={item.title}
                      />
                    ),
                  )}
                </StyledInputWrapper>
              </StyledInputWrap>
              <StyledInputWrap as="fieldset">
                <StyledLabel as="legend">Тип найма</StyledLabel>
                <StyledInputWrapper>
                  {dictionaries.vacancyFrequency?.map(
                    (item: { id: number; title: string | null }) => (
                      <StyledInputRadio
                        key={item.id}
                        id={item.id}
                        idPrefix="worktype"
                        name="type_frequency"
                        flag={vacancy.type_frequency}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleEditInput(
                            e.target.name,
                            Number(e.target.value),
                          );
                        }}
                        text={item.title}
                      />
                    ),
                  )}
                </StyledInputWrapper>
              </StyledInputWrap>
              <StyledInputWrap>
                <StyledLabel htmlFor="vacancy-conditions">
                  Условия работы
                </StyledLabel>
                <StyledInput
                  input
                  id="vacancy-conditions"
                  textarea
                  name="text_conditions"
                  value={vacancy.text_conditions}
                  inlineStyles={{ height: 142 }}
                  placeholder="Опишите режим работы, местонахождение компании..."
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleEditInput(e.target.name, e.target.value);
                  }}
                />
              </StyledInputWrap>
              <StyledInputWrap>
                <StyledLabel htmlFor="vacancy-duties">
                  Перечислите обязанности
                </StyledLabel>
                <StyledInput
                  input
                  id="vacancy-duties"
                  textarea
                  name="text_responsibilities"
                  inlineStyles={{ height: 252 }}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleEditInput(e.target.name, e.target.value);
                  }}
                  placeholder="Какими навыками и знаниями должен обладать кандидат..."
                  value={vacancy.text_responsibilities}
                />
              </StyledInputWrap>
            </div>
            <div className="col-12">
              <StyledButtonWrap style={{ opacity: buttonLoader ? 0.5 : 1 }}>
                <StyledLink
                  type="button"
                  button
                  handleСlick={handleSaveVacancy}
                  disabled={buttonLoader}
                  text="Сохранить"
                ></StyledLink>
                {buttonLoader && (
                  <Loader
                    type="Audio"
                    color="#000"
                    height="16px"
                    width="16px"
                  />
                )}
              </StyledButtonWrap>
            </div>
          </div>
        </form>
      </StyledContainer>
    </>
  );
};
export default EditVacancy;
