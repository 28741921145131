import React from 'react';
import Loader from '../../Template/Loader/Loader';

interface DataStatusProps {
  loading: boolean;
  error: boolean;
  items: any[];
  children: any;
  noItemsText?: string;
}

const DataStatus: React.FC<DataStatusProps> = ({
  loading,
  error,
  items,
  children,
  noItemsText,
}) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="container mt-4">
        <h4 className="text-center">Произошла ошибка при загрузке данных</h4>
      </div>
    );
  }

  if (
    (!loading && items?.length === 0) ||
    (!loading && Object.keys(items).length === 0)
  ) {
    return (
      <div className="container mt-4">
        <h4 className="text-center">
          {noItemsText !== undefined ? noItemsText : 'Данные отсутствуют'}
        </h4>
      </div>
    );
  }

  return children;
};

export default DataStatus;
