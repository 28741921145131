import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetAuthorization } from '../../../../store/actions/auth';
import footerLogo from '../../../../assets/image/footer-logo.svg';
import icon1 from '../../../../assets/image/icon-1.svg';
import icon2 from '../../../../assets/image/icon-2.svg';
import icon3 from '../../../../assets/image/icon-3.svg';
import icon4 from '../../../../assets/image/icon-4.svg';
import icon5 from '../../../../assets/image/icon-5.svg';
import mailFooter from '../../../../assets/image/mail-footer.svg';
import telFooter from '../../../../assets/image/tel-footer.svg';

import CompaniesSelector from '../CompaniesSelector/CompaniesSelector';
import MobileProfile from './MobileProfile';
import MobileLink from './MobileLink';
import MobileItem from './MobileItem';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
`;

const MobileMenuWrap = styled.div`
  justify-content: space-between;
  display: none;
  background: #333333;
  box-shadow: 0px 4px 6px rgba(52, 59, 118, 0.06);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  display: none;
  height: 54px;

  @media (max-width: 991px) {
    display: flex;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 103;
  }
`;

const MobileNav = styled.nav`
  display: none;
  height: auto;
  z-index: 1004;
  align-items: center;
  @media (max-width: 991px) {
    display: flex;
  }
`;

const MenuToggle = styled.div`
  display: block;
  position: relative;
  top: 3px;
  left: 16px;
  z-index: 1;
  user-select: none;

  a {
    transition: color 0.3s ease;
  }
  input {
    display: block;
    width: 21px;
    height: 17px;
    position: absolute;
    top: -1.5px;
    left: -1px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }

  .toggle-block span {
    display: block;
    width: 19px;
    height: 2px;
    margin-bottom: 4px;
    position: relative;
  
    background: #fff;
    border-radius: 3px;
  
    z-index: 1;
  
    transform-origin: 4px 0px;
  
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .toggle-block span:first-child {
    transform-origin: 0% 0%;
  }

  .toggle-block span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  input:checked ~ .toggle-block {
    transform: translate(215px,0px);
    z-index: 104;
    position: relative;
    pointer-events: none;
    animation: ${opacity} 0.3s;
  }

  input:checked {
    transform: translate(215px,0px);
  }

  input:checked ~ .toggle-block span {
    opacity: 1;
    transform: rotate(45deg) translate(3px, -1px);
    width: 17px;
    background: #fff;
  }

  input:checked ~ .number {
    display: none;
  }

  input:checked ~ .toggle-block span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ .toggle-block span:last-child {
    transform: rotate(-45deg) translate(1px, -1px);
    width: 17px;
  }

  input:checked ~ ul {
    transform: none;
  }

  input:checked ~ #menu {
    transform: none;
  }

  .number {
    position: absolute;
    background: #ff7557;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: -12px;
    right: -9px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 11px;
    pointer-events: none;
  }
`;

const MobileMenuContent = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  max-width: 265px;
  width: 100%;
  padding-bottom: 47px;
  left: 0px;
  list-style-type: none;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background-color: #f0f1fa;
  &.show {
    box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
  }
`;

const MobileLinksWrap = styled.div`
  background: #ffffff;
  padding: 93px 16px 22px 16px;
  position: relative;
  margin-top: -66px;
`;

const MobileLogoWrap = styled.div`
  height: 19px;
  max-width: 75px;
  width: 100%;
  margin-bottom: 3px;
`;

const MobileBottomWrap = styled.div`
  background: #f0f1fa;
  padding: 19px 16px 47px 16px;
`;

const MobileMenu: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickLogOut = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    dispatch(resetAuthorization());
    history.push('/login');
  };

  return (
    <MobileMenuWrap>
      <CompaniesSelector mobile={false} />
      <MobileNav role="navigation">
        <MenuToggle id="menuToggle">
          <input
            type="checkbox"
            onChange={() => setOpen(!open)}
            checked={open}
          />
          <div className="number">5</div>
          <div className="toggle-block">
            <span />
            <span />
            <span />
          </div>
          <MobileMenuContent id="menu">
            <MobileProfile />
            <CompaniesSelector mobile />
            <MobileLinksWrap>
              <MobileLink
                icon={icon1}
                text="Главная"
                href="/"
                active={false}
                handleClick={() => setOpen(false)}
              />
              <MobileLink
                icon={icon2}
                text="Вакансии"
                activeNumber={5}
                href="/vacancies"
                active
                handleClick={() => setOpen(false)}
              />
              <MobileLink
                icon={icon3}
                text="Компании"
                activeNumber={2}
                href="/companies"
                active={false}
                handleClick={() => setOpen(false)}
              />
              <MobileLink
                icon={icon4}
                text="О системе"
                href="/"
                active={false}
                handleClick={() => setOpen(false)}
              />
              <MobileLink
                icon={icon5}
                text="Выход"
                href="/"
                active={false}
                handleClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ) => handleClickLogOut(e)}
              />
            </MobileLinksWrap>
            <MobileBottomWrap>
              <MobileLogoWrap>
                <img src={footerLogo} alt="#" />
              </MobileLogoWrap>
              <MobileItem
                href="mailto:info@mebot24.ru"
                image={mailFooter}
                text="info@mebot24.ru"
              />
              <MobileItem
                href="tel:+74951183619"
                image={telFooter}
                text="8 (495)-118-36-19"
              />
            </MobileBottomWrap>
          </MobileMenuContent>
        </MenuToggle>
      </MobileNav>
    </MobileMenuWrap>
  );
};

export default MobileMenu;
