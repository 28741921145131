import React, { useState } from 'react';
import styled from 'styled-components';

import {
  StyledTitle,
  StyledBlock,
  StyledLinkWrap,
} from './StyledVacancyComponents';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import QuoteApp from './DragDrop/Dragable';
import { IVacancyProcess } from '../../../types/vacancies';

const StyledText = styled.p`
  margin: 0;
  margin-bottom: 24px;
`;

const VacancyProcess: React.FunctionComponent<IVacancyProcess> = ({
  todoValues,
  setValue,
}) => {
  return (
    <StyledBlock id="setting">
      <StyledTitle>Настройки процесса найма</StyledTitle>
      <StyledText>
        Здесь отображаются промежуточные шаги вашего найма. Вы можете поменять
        их последовательность, добавить или убрать шаг.
      </StyledText>

      <QuoteApp todoValues={todoValues} setValue={setValue} />
      <StyledLinkWrap>
        <StyledLink text="Сохранить" path="/" />
      </StyledLinkWrap>
    </StyledBlock>
  );
};
export default VacancyProcess;
