import React from 'react';
import styled from 'styled-components';
import { Vacancy } from '../../types/vacancies';
import StyledSwitch from '../Template/StyledComponents/StyledSwitch';

const StyledCard = styled.article`
  width: 100%;
  max-width: 360px;
  border: 1px solid #cdcede;
  border-radius: 10px;
  padding: 16px 18px;
  color: #4f4f4f;
  min-height: 84px;

  @media (min-width: 768px) {
    min-height: 101px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 43px;
    grid-column-gap: 10px;
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
  }

  .text {
    font-size: 12px;
    line-height: 29px;
  }
`;

interface ArchivedItem {
  vacancy: Vacancy;
  handleVacancy: (vacancy: Vacancy) => void;
}

const VacanciesCardArchive: React.FC<ArchivedItem> = ({
  vacancy,
  handleVacancy,
}) => {
  return (
    <StyledCard>
      <div className="wrapper">
        <h2 className="title">{vacancy.name}</h2>
        <StyledSwitch
          handleChange={() => {
            handleVacancy(vacancy);
          }}
          id={`archiveVacancies-${vacancy.id}`}
          checked={vacancy.status === 'Y'}
        />
      </div>
      <span className="text">
        Перенесена в архив<span>12.12.2019</span>
      </span>
    </StyledCard>
  );
};
export default VacanciesCardArchive;
