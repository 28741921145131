import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.a`
  width: 260px;
  position: relative;
  display: block;
  height: 185px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: right top, center;
  background-size: 100% auto, cover;

  .wrapper {
    position: absolute;
    left: 15px;
    bottom: 20px;
    display: block;
  }

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    background: #ffffff;
    color: #000000;
  }

  .text {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    max-width: 141px;
    width: 100%;
    min-height: 34px;
  }

  @media (max-width: 575px) {
    width: 100px;

    .wrapper {
      left: 7px;
      bottom: 13px;
    }

    .number {
      font-size: 13px;
    }

    .text {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      min-height: 59px;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
`;

interface CarouselItemProps {
  icon: string;
  color: string;
  count: number;
  text: string;
}

const CarouselImportantItem: React.FunctionComponent<CarouselItemProps> = ({
  icon,
  color,
  count,
  text,
}) => {
  return (
    <StyledCard
      className="important-slider-item important-slider-item_1"
      href="/"
      style={{
        backgroundColor: color,
        backgroundImage: `url(${icon})`,
      }}
    >
      <div className="wrapper">
        <span className="number">+{count}</span>
        <p className="text">{text}</p>
      </div>
    </StyledCard>
  );
};

export default CarouselImportantItem;
