import axios from 'axios';
import { Dispatch } from 'redux';
import { config, url } from './actionsAssets';
import { AutomaticActions, AutomaticActionTypes } from '../../types/automatic';
import useTypedSelector from '../../hooks/useTypedSelector';

export const fetchAvailibleCount = (vacancy_id: string | number) => {
  return async (dispatch: Dispatch<AutomaticActions>) => {
    const resume = await axios.get(
      `${url}resume/list/?vacancy_id=${vacancy_id}&status=new`,
      config,
    );
    dispatch({
      type: AutomaticActionTypes.FETCH_AVAILABLE_COUNT,
      payload: resume.data.totalResultCount,
    });
  };
};

export const setAutoloanCount = (count: number) => {
  return async (dispatch: Dispatch<AutomaticActions>) => {
    dispatch({
      type: AutomaticActionTypes.SET_AUTOLOAN_COUNT,
      payload: count,
    });
  };
};

export const sendAutomaticResume = (
  count_resume: number,
  vacancy_id: string | number,
) => {
  return async (dispatch: Dispatch<AutomaticActions>) => {
    axios
      .post(
        `${url}authorize/send_invite/${vacancy_id}/`,
        { count: count_resume },
        config,
      )
      .then(res => {
        alert('Резюме отправлены в обработку');
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };
};
