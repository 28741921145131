import React, { useEffect, useState } from 'react';
import StyledTitle from '../../../Template/StyledComponents/StyledTitle';
import FormHeader from '../../FormHeader/FormHeader';
import * as Styled from './StyledIntegrationBitrix';
import IntegrationSettings from './IntegrationSettings/IntegrationSettings';
import IntegrationTable from './IntegrationTable/IntegrationTable';
import useTypedSelector from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import StyledLink from '../../../Template/StyledComponents/StyledLink';

interface IntegrationBitrixProps {}

const IntegrationBitrix: React.FunctionComponent<IntegrationBitrixProps> = () => {
  // const { configuration } = useTypedSelector(r => r.connectorB24);
  // const { getCompanyConfiguration, resetCompanyConfiguration } = useActions();
  const [showVacancyTable, setShowVacancyTable] = useState(false);

  // useEffect(() => {
  //   getCompanyConfiguration();
  //   return () => {
  //     resetCompanyConfiguration();
  //   };
  // }, []);

  const handleButtonClick = () => {
    setShowVacancyTable(!showVacancyTable);
  };

  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany></FormHeader>
      <Styled.Section>
        <StyledTitle color="#358dcc" text="Интеграция с Битрикс 24">
          <Styled.BtnWrap>
            <StyledLink
              type="button"
              button
              handleСlick={handleButtonClick}
              text={!showVacancyTable ? 'Таблица вакансий' : 'Настройки'}
              $whiteBackground
              inlineStyles={{
                height: '36px',
              }}
            />
          </Styled.BtnWrap>
        </StyledTitle>
        <div className="container wrapper">
          {!showVacancyTable ? <IntegrationSettings /> : <IntegrationTable />}
        </div>
      </Styled.Section>
    </>
  );
};

export default IntegrationBitrix;
