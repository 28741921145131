import React from 'react';
import styled from 'styled-components';

const StyledInputComponent = styled.input`
  &:checked ~ label {
    color: #000000;
  }
  &:checked ~ label::before {
    border-color: #fedc2d;
    background-color: #fedc2d;
  }

  &:checked ~ label:hover::before,
  &:checked ~ label:focus::before {
    opacity: 1;
  }
`;

const StyledLabelCopmonent = styled.label`
  position: relative;
  display: block;
  margin: 0;
  color: #4f4f4f;
  padding-left: 25px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 13px;
    height: 13px;
    top: 3px;
    left: 0;
    border-radius: 50%;
    border: 1px solid #828282;
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus {
    color: #000000;
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.5;
  }
`;

interface PropsInterface {
  additionalClass?: string;
  id?: string | number;
  idPrefix?: string;
  name?: string;
  handleChange?: any;
  flag?: string | number;
  text?: string | null;
}

const StyledInputRadio: React.FC<PropsInterface> = ({
  id,
  idPrefix,
  name,
  handleChange,
  flag,
  text,
}) => {
  let elementId;
  if (idPrefix) {
    elementId = `${idPrefix}${id}`;
  } else {
    elementId = `${id}`;
  }

  return flag ? (
    <div>
      <StyledInputComponent
        className="visually-hidden"
        type="radio"
        id={elementId}
        name={name}
        value={id}
        checked={flag === id}
        onChange={handleChange}
      />
      <StyledLabelCopmonent htmlFor={elementId}>{text}</StyledLabelCopmonent>
    </div>
  ) : (
    <div>
      <StyledInputComponent
        className="visually-hidden"
        type="radio"
        id={elementId}
        name={name}
        value={id}
        onChange={handleChange}
      />
      <StyledLabelCopmonent htmlFor={elementId}>{text}</StyledLabelCopmonent>
    </div>
  );
};

export default StyledInputRadio;
