import React from 'react';
import styled from 'styled-components';

const StyledTitleComponent = styled.div<{ $color: string }>`
  background-color: ${props => (props.$color ? props.$color : '#D0E5FA')};
  color: #ffffff;
  padding: 15px 0;

  .title-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    gap: 10px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin: 0;
  }

  @media (min-width: 576px) {
    .title-wrapper {
      grid-template-columns: 300px 1fr;
      align-items: center;
    }
  }

  @media (min-width: 992px) {
    padding: 20px 0;

    .title {
      font-size: 25px;
      line-height: 29px;
    }
  }
`;

interface PropsInterface {
  color: string;
  text: string;
}

const StyledTitle: React.FC<PropsInterface> = ({ color, text, children }) => {
  return (
    <StyledTitleComponent $color={color}>
      <div className="container title-wrapper">
        <h2 className="title">{text}</h2>
        {children && children}
      </div>
    </StyledTitleComponent>
  );
};

export default StyledTitle;
