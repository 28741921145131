import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { CVS } from '../../../types/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';

const clipIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="bi bi-paperclip"
    viewBox="0 0 16 16"
  >
    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
  </svg>
);

const StyledTableRow = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 14px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledTableRowWrap = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 40% 15% 18% 15% 12% 1fr;

  .link {
    text-decoration: none;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 25% 18% 22% 20% 15%;

    .years {
      display: none;
    }
  }

  @media (max-width: 449px) {
    .date {
      display: block;
      width: 42px;
      word-break: break-all;
    }
  }
`;

const StyledSelectWrap = styled.div`
  width: 230px;

  @media (max-width: 1200px) {
    width: 154px;
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    select {
      width: 154px;
    }
  }
`;

interface CvsProp {
  item: CVS;
  handleModal: (e: CVS) => void;
}

const VacancyCVsTableRow: React.FC<CvsProp> = ({ item, handleModal }) => {
  return (
    <StyledTableRow>
      <StyledTableRowWrap>
        <span>
          {item.first_name} {item.last_name}
        </span>
        <span>
          {moment().diff(
            moment(item.year_of_birthday, DateTimeFormats.DATE_FORMAT_API),
            'years',
          )}
          &nbsp;
          <span className="years">
            {wordDeclination(
              moment().diff(
                moment(item.year_of_birthday, DateTimeFormats.DATE_FORMAT_API),
                'years',
              ),
              wordsDict.YEARS_FORMS,
            )}
          </span>
        </span>
        <div>
          <span className="date">
            {moment(item.date_create, 'DD.MM.YYYY').format('DD.MM.YYYY')}
          </span>
        </div>
        <div>
          <span className="date">
            {item.last_update
              ? moment(item.last_update, 'DD.MM.YYYY').format('DD.MM.YYYY')
              : '-'}
          </span>
        </div>
        <span className="link">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={item.url} target="_blank">
            {clipIcon}
          </a>
        </span>
      </StyledTableRowWrap>
      <StyledSelectWrap>
        <StyledSelect
          handleChange={() => handleModal(item)}
          inlineStyles={{ height: 33, padding: '5px 29px 5px 5px' }}
          defaultValue="example"
        >
          <option value="example" disabled>
            Действия
          </option>
          <option value="invite">Пригласить на должность</option>
          <option value="test">Тестировать</option>
          <option value="interview">Собеседовать</option>
        </StyledSelect>
      </StyledSelectWrap>
    </StyledTableRow>
  );
};
export default VacancyCVsTableRow;
