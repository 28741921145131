import styled from 'styled-components';
import { Link } from 'react-router-dom';
import btnBottom from '../../../assets/image/btn-bottom.svg';
import statisticDisplayHeadingIcon from '../../../assets/image/statistic-display-heading-icon.svg';
import nearestInterviewsHeadingIcon from '../../../assets/image/nearest-interviews-heading-icon.svg';

export const StyledVacanciesHeader = styled.section`
  position: relative;
  padding: 0;
  background: #606770;
  z-index: 1;
  min-height: 50px;
  transition: all 0.3s;

  &.active,
  &.novacancy-section {
    padding: 35px 0px 50px 0px;
  }

  &.novacancy-section {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    padding-top: 0px;
    padding-bottom: 1px;

    &.active {
      padding-top: 0px;
      padding-bottom: 35px;
    }

    &.active,
    &.novacancy-section {
      padding-top: 0px;
      padding-bottom: 35px;
    }

    &.novacancy-section {
      margin-bottom: 36px;
    }
  }
`;

export const Novacancy = styled.section`
  position: absolute;
  content: '';
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(96, 103, 112, 0.85);

  & > .container {
    height: 100%;
  }

  .novacancy-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .novacancy-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 37px;
    text-align: center;
    color: #fff;
    margin-bottom: 16px;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 17px;
    }
  }

  .novacancy-desc {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 26px;
    max-width: 466px;
    width: 100%;
    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 35px;
    }
  }
`;

export const StyledVacanciesHeaderNavline = styled.div`
  @media (min-width: 992px) {
    padding: 15px 0px 23px 0px;
  }
`;

export const StyledVacanciesHeaderContent = styled.div`
  z-index: 1;
`;

export const StyledTitle = styled.h1`
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  z-index: 2;
  pointer-events: none;

  &.active {
    cursor: pointer;
    pointer-events: auto;
  }

  .arrow-wrap {
    margin-left: 18px;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    font-size: 20px;
    line-height: 29px;
  }
`;

export const StyledTitleButton = styled.button`
  font: inherit;
  display: block;
  background-color: transparent;
  border: none;
  text-shadow: none;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

export const StyledArrowButton = styled.button`
  display: block;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border: none;
  text-shadow: none;
  background-image: url(${btnBottom});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const StyledLinkWrap = styled.div`
  width: 203px;
  margin-top: 10px;

  a {
    flex-direction: row-reverse;
  }

  a svg {
    margin-right: 0;
    margin-left: 10px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

export const StyledDropdown = styled.div`
  position: absolute;
  content: '';
  margin-bottom: 0px;
  top: 45px;
  left: 0px;
  width: 100%;
  box-shadow: 0px 1px 10px rgb(2 1 72 / 16%);
  border-radius: 5px;
  padding-left: 0px;
  padding-top: 8px;
  background-color: #fff;
  padding-bottom: 14px;
  z-index: 3;
  max-width: 399px;
  width: 100%;
  display: none;
  cursor: pointer;
  transition: all 0.3s ease;

  a {
    display: block;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      background-color: #f3f3f7;
    }
  }

  .item {
    list-style-type: none;
    min-height: 31px;
    padding: 5px 17px;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
  }

  &.active {
    display: block;
  }

  @media (max-width: 991px) {
    max-width: 329px;
  }
`;

export const StyledStatisticHeader = styled.div`
  position: relative;
  padding-left: 65px;
  margin-bottom: 30px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 46px;
    height: 46px;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #ffffff;
    background-image: url(${statisticDisplayHeadingIcon});
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 991px) {
    padding-left: 55px;
    margin-bottom: 20px;

    &::before {
      width: 41px;
      height: 41px;
    }
  }
`;

export const StyledStatisticTitle = styled.p`
  margin: 0 0 3px;
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  color: #fff;

  @media (max-width: 991px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const StyledStatisticBtn = styled.button`
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  text-shadow: none;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #e0e0e0;
  transition: opacity 0.5s;

  &:hover,
  &:focus {
    opacity: 0.7;
    text-decoration: none;
  }

  svg {
    margin-left: 5px;
  }

  @media (max-width: 991px) {
    font-size: 13px;
    line-height: 17px;
  }
`;

export const StyledStatisticLink = styled(Link)`
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  text-shadow: none;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: #e0e0e0;
  transition: opacity 0.5s;

  &:hover,
  &:focus {
    opacity: 0.7;
    color: #ffffff;
    text-decoration: none;
  }

  svg {
    margin-left: 5px;
  }

  @media (max-width: 991px) {
    font-size: 13px;
    line-height: 17px;
  }
`;

export const StyledStatisticList = styled.ul`
  margin: 0;
  padding: 0 30px 0 0;
  list-style-type: none;

  @media (max-width: 991px) {
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const StyledStatisticBar = styled.span`
  position: relative;
  display: block;
  width: 55%;
  height: 7px;
  margin-right: 5%;

  span {
    display: block;
    height: 100%;
    border-radius: 15px;
  }

  span:first-child {
    width: 100%;
    background: #4f4f4f;
  }

  span:last-child {
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 1200px) {
    width: 45%;
  }

  @media (max-width: 991px) {
    margin-right: 25px;
  }

  @media (max-width: 380px) {
    max-width: 87px;
    width: 100%;
  }
`;

export const StyledStatisticItemInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;

  .mark {
    flex-shrink: 0;
    display: block;
    width: 11px;
    height: 11px;
    margin-right: 15px;
    border-radius: 50%;
  }

  .title,
  .count {
    @media (max-width: 991px) {
      font-size: 13px;
      line-height: 20px;
    }
    color: #ffffff;
  }

  .title {
    flex-grow: 1;
    display: block;
  }

  .count span {
    font-weight: 700;
  }

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 991px) {
    width: 55%;

    .mark {
      display: none;
    }
  }

  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const StyledNearestInterviews = styled.div`
  @media (max-width: 991px) {
    margin-top: 35px;
  }
`;

export const StyledNearestInterviewsHead = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding-left: 65px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 46px;
    height: 46px;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #ffffff;
    background-image: url(${nearestInterviewsHeadingIcon});
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;
    padding-left: 55px;

    &::before {
      width: 41px;
      height: 41px;
    }
  }
`;

export const StyledSchedule = styled.div<{
  transparent?: boolean;
}>`
  color: ${props => (props.transparent ? '#ffffff' : '#333333')};
  .schedule-head {
    padding: 6px 25px;
    border-radius: 12px 12px 0px 0px;
    font-size: 13px;
    line-height: 20px;
    background-color: ${props =>
      props.transparent ? 'transparent' : '#fedc2d'};
    border: ${props => (props.transparent ? '1px solid #ffffff' : '0')};
  }

  .schedule-content {
    display: flex;
    align-items: stretch;
    min-height: 140px;
    padding: 15px 0;
    border-radius: 0px 0px 12px 12px;
    background-color: ${props =>
      props.transparent ? 'transparent' : '#ffffff'};
    max-height: 100px;
    overflow-y: auto;
    border: ${props => (props.transparent ? '1px solid #ffffff' : '0')};
    border-top: 0;
  }

  .schedule-column {
    flex-grow: 1;
    padding: 0 10px;
  }

  .schedule-column:first-of-type {
    border-right: 1px solid #cdcede;
  }

  .schedule-row {
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
`;

export const StyledNavLinksList = styled.ul`
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  list-style-type: none;
  padding-top: 30px;

  li {
    display: inline-block;
  }

  a {
    position: relative;
    display: block;
    padding: 7px 15px;
    border: 1px solid rgba(130, 130, 130, 0.5);
    border-radius: 19px;
    color: #828282;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: #000000;
    }

    &.current {
      border: 2px solid #fedc2d;
    }
  }

  li:not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: 991px) {
    padding-top: 20px;

    li:not(:last-child) {
      margin-right: 10px;
    }

    a {
      padding: 5px 13px;
    }
  }
`;
