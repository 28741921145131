import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  StyledTitle,
  StyledBlock,
  StyledGrayWrapper,
  StyledLinkWrap,
} from './StyledVacancyComponents';
import StyledInputRadio from '../../Template/StyledComponents/StyledInputRadio';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

const StyledRadioWrapper = styled.div`
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 24px;
`;

const StyledResumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (min-width: 992px) {
    margin-bottom: 28px;
  }
`;

const StyledResumeWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`;

const StyledResumeInputWrap = styled.div`
  width: 100%;
  margin-bottom: 15px;
  @media (min-width: 576px) {
    width: 264px;
    margin-right: 24px;
    margin-bottom: 0;
  }
`;

const StyledResumeBtnWrap = styled.div`
  width: 100%;
  @media (min-width: 576px) {
    width: 168px;
  }
`;

const StyledText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  color: #000000;
  margin-bottom: 12px;
`;

const VacancyAutomatic: React.FunctionComponent<{
  vacancyId: string | number;
}> = ({ vacancyId }) => {
  const { availible } = useTypedSelector(r => r.automatic);
  const { fetchAvailibleCount, sendAutomaticResume } = useActions();
  const [count, setCount] = useState<number>(availible);

  useEffect(() => {
    fetchAvailibleCount(vacancyId);
  }, [vacancyId]);

  const handleChangeAutoloan = (count_resume: number) => {
    if (count_resume > availible) {
      setCount(availible);
      return alert('Введено предельное значение');
    }

    return setCount(count_resume);
  };

  async function submitResumeToAutomatic() {
    const send = await sendAutomaticResume(count, vacancyId);
    const fetch = await fetchAvailibleCount(vacancyId);
    setCount(0);
  }

  return (
    <StyledBlock id="automatic">
      <StyledTitle>Автоматическое управление</StyledTitle>
      {/*<StyledRadioWrapper>
        <StyledInputRadio id="turn-on" name="AutoManagement" text="Включено" />
        <StyledInputRadio
          id="turn-off"
          name="AutoManagement"
          text="Выключено"
        />
      </StyledRadioWrapper>*/}
      <StyledResumeWrapper>
        <StyledText>
          Доступно:<span>&nbsp;{availible}&nbsp;</span>резюме
        </StyledText>
        <StyledResumeWrap>
          <StyledResumeInputWrap>
            <StyledInput
              // type="number"
              placeholder="Кол-во резюме в работу"
              input
              value={count}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeAutoloan(+e.target.value);
              }}
              disabled={availible === 0}
            />
          </StyledResumeInputWrap>
          <StyledResumeBtnWrap>
            <StyledLink
              button
              type="button"
              handleСlick={submitResumeToAutomatic}
              text="Запустить"
              disabled={availible === 0}
            />
          </StyledResumeBtnWrap>
        </StyledResumeWrap>
      </StyledResumeWrapper>
      <StyledGrayWrapper>
        <p className="title">Запуск процессов</p>
        <p className="text">
          В автоматическом режиме процессы запускаются при добавлении нового
          резюме. Если автоматический режим выключен, то запуск происходит на
          данной странице
        </p>
      </StyledGrayWrapper>
      <StyledLinkWrap>
        <StyledLink text="Сохранить" path="/" />
      </StyledLinkWrap>
    </StyledBlock>
  );
};
export default VacancyAutomatic;
