import React from 'react';
import { useHistory } from 'react-router-dom';
import VacancyDisplayItem from '../../Template/VacancyDisplay/VacancyDisplayItem';
import { ShortVacanciesItemInterface } from '../../../types/mainPage';

const ShortVacanciesItem: React.FunctionComponent<ShortVacanciesItemInterface> = ({
  vacancy,
}) => {
  const history = useHistory();
  const handleHistoryVacancy = (id: string) => {
    history.push(`/vacancies/${id}`);
  };

  return (
    <>
      <VacancyDisplayItem
        vacancy={vacancy}
        vacancyTitle={vacancy.name}
        candidates={vacancy.statistic.resume}
        questionnaires={vacancy.statistic.finished}
        handleClick={() => {
          handleHistoryVacancy(vacancy.id);
        }}
        aria-hidden
      ></VacancyDisplayItem>
    </>
  );
};
export default ShortVacanciesItem;
