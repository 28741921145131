import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import Calendar from '../../Template/Calendar/Calendar';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { Vacancy, VacancyInterviewUnixTime } from '../../../types/vacancies';
import { fetchSvInterview, StatDates } from '../../../store/actions/vacancies';
import NoVacancy from './NoVacancy';
import AddNewVacancy from '../AddNewVacancy/AddNewVacancy';
import { useActions } from '../../../hooks/useActions';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  StyledVacanciesHeader,
  StyledVacanciesHeaderNavline,
  StyledVacanciesHeaderContent,
  StyledTitle,
  StyledTitleButton,
  StyledArrowButton,
  StyledLinkWrap,
  StyledDropdown,
  StyledStatisticHeader,
  StyledStatisticTitle,
  StyledStatisticBtn,
  StyledStatisticList,
  StyledStatisticBar,
  StyledStatisticItemInfo,
  StyledNearestInterviews,
  StyledNearestInterviewsHead,
  StyledSchedule,
  StyledStatisticLink,
  StyledNavLinksList,
} from './StyledVacanciesHeaderComponents';
import arrow from '../../../assets/image/arrow.svg';

const StyledShowBtn = styled.button`
  position: relative;
  display: block;
  background-color: transparent;
  border: none;
  font: inherit;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  padding-right: 15px;
  transition: opacity 0.5s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 5px;
    right: 0;
    top: 50%;
    margin-top: -3px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: 10px 5px;
    filter: invert(100%);
  }

  &:hover,
  &:focus-visible {
    text-decoration: none;
    opacity: 0.7;
  }
`;

interface VacanciesItemHeaderProps {
  id: string;
}

const VacanciesItemHeader: React.FunctionComponent<VacanciesItemHeaderProps> = ({
  id,
}) => {
  const dispatch = useDispatch();
  const { fetchSingleVacancyStatistics } = useActions();
  const [titleClass, setTitleClass] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const sticky = useRef(null);
  const { vacancy } = useTypedSelector(r => r.vacancies);
  const { vacancies } = useTypedSelector(r => r.mainData);
  const stat = vacancy.statistic;
  const percent = vacancy.statistic.resume_load / 100;
  const [datesString, setDatesString] = useState({
    dateFrom: Moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    dateTo: Moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  });
  useEffect(() => {
    dispatch(fetchSvInterview(id, { today: true }));
    dispatch(fetchSvInterview(id, { today: true }, undefined, true));
    fetchSingleVacancyStatistics(id);
  }, [id]);

  useEffect(() => {
    const show = window.localStorage.getItem('header-open');

    if (show === 'true') {
      setShowContent(true);
    }
  }, []);

  const handleTitleClass = () => {
    setTitleClass(!titleClass);
  };

  const handleShowDateSelector = {
    show: () => setShowDateSelector(true),
    close: () => setShowDateSelector(false),
    saveAndClose: (dates: StatDates) => {
      fetchSingleVacancyStatistics(id, dates);
      setShowDateSelector(false);
      setDatesString(dates);
    },
  };

  interface PropsInterface {
    barBackground: string;
    count: number;
    text: string;
  }

  const handleShowBtn = () => {
    setShowContent(!showContent);

    if (!showContent) {
      window.localStorage.setItem('header-open', 'true');
    } else {
      window.localStorage.setItem('header-open', '');
    }
  };

  const StatisticItem: React.FC<PropsInterface> = ({
    barBackground,
    count,
    text,
  }) => {
    const barWidth = count / percent;
    return (
      <li>
        <StyledStatisticBar>
          <span />
          <span
            style={{
              width: `${barWidth}%`,
              background: `${barBackground}`,
            }}
          />
        </StyledStatisticBar>
        <StyledStatisticItemInfo>
          <span className="mark" style={{ background: `${barBackground}` }} />
          <span className="title">{text}</span>
          <span className="count">
            <span>{count}</span> чел.
          </span>
        </StyledStatisticItemInfo>
      </li>
    );
  };

  return (
    <>
      <Modal
        show={showDateSelector}
        onHide={handleShowDateSelector.close}
        className="modal fade calendar statistics-dates"
      >
        <Modal.Body>
          <Calendar
            handleShowDateSelector={handleShowDateSelector}
            options={{ mode: 'range', inline: true }}
          />
        </Modal.Body>
      </Modal>
      {vacancy.loaded && !vacancy.error ? (
        <>
          <StyledVacanciesHeader
            ref={sticky}
            className={showContent ? 'active' : ''}
          >
            <StyledVacanciesHeaderNavline>
              <div className="container">
                <div className="row align-items-center mb-md-3 mb-lg-0">
                  <div className="col-xl-5 col-lg-6 col-12">
                    <StyledTitle className={vacancies.loaded ? 'active' : ''}>
                      <StyledTitleButton
                        onClick={handleTitleClass}
                        type="button"
                      >
                        {vacancy.singleVacancy.name}
                      </StyledTitleButton>

                      {vacancies.loaded && (
                        <>
                          <span className="arrow-wrap">
                            <StyledArrowButton
                              onClick={handleTitleClass}
                              type="button"
                            />
                          </span>
                          <StyledDropdown
                            className={`${titleClass && 'active'}`}
                          >
                            {vacancies.vacancies.map((v: Vacancy) => {
                              return (
                                <Link
                                  onClick={() => setTitleClass(false)}
                                  to={`/vacancies/${v.id}`}
                                  href="/#"
                                  key={v.id}
                                >
                                  <li className="item">{v.name}</li>
                                </Link>
                              );
                            })}
                          </StyledDropdown>
                        </>
                      )}
                    </StyledTitle>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <StyledLinkWrap>
                      <StyledLink
                        path={`/vacancies/${vacancy.singleVacancy.id}/actions`}
                        text="Запустить найм"
                        inlineStyles={{ height: '38px', padding: '10px' }}
                      >
                        <svg
                          width="7"
                          height="9"
                          viewBox="0 0 7 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M-3.11727e-07 1.86852L-7.84686e-08 7.20485C-4.42203e-08 7.98836 0.860163 8.46752 1.52635 8.05511L5.67684 5.48577C6.29591 5.10253 6.31099 4.20733 5.70518 3.80345L1.5547 1.03647C0.890145 0.59343 -3.46639e-07 1.06982 -3.11727e-07 1.86852Z"
                            fill="black"
                          />
                        </svg>
                      </StyledLink>
                    </StyledLinkWrap>
                  </div>
                  <div className="col-xl-4 col-lg-3 col-md-6 col-12 mt-3 mt-lg-0 mb-3 mb-lg-0">
                    <StyledShowBtn
                      className="ml-md-auto"
                      type="button"
                      onClick={handleShowBtn}
                    >
                      {showContent ? 'Свернуть ' : 'Подробнее'}
                    </StyledShowBtn>
                  </div>
                </div>
              </div>
            </StyledVacanciesHeaderNavline>
            {showContent && (
              <StyledVacanciesHeaderContent>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div>
                        <StyledStatisticHeader>
                          <StyledStatisticTitle>
                            Статистика с {datesString.dateFrom} по{' '}
                            {datesString.dateTo}
                          </StyledStatisticTitle>
                          <StyledStatisticBtn
                            type="button"
                            onClick={handleShowDateSelector.show}
                          >
                            Изменить дату
                            <svg
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </StyledStatisticBtn>
                        </StyledStatisticHeader>
                        <StyledStatisticList>
                          <StatisticItem
                            barBackground="#ffd441"
                            count={vacancy.statistic.resume_load}
                            text="Резюме"
                          />
                          <StatisticItem
                            barBackground="#ffbf41"
                            count={stat.contact_start}
                            text="Связались"
                          />
                          <StatisticItem
                            barBackground="#FFAC41"
                            count={stat.contact_finish}
                            text="Заинтересованы"
                          />
                          <StatisticItem
                            barBackground="#FF9A41"
                            count={stat.test_finish}
                            text="Тестирование"
                          />
                          <StatisticItem
                            barBackground="#FF8841"
                            count={stat.task_finish}
                            text="Собеседование"
                          />
                          <StatisticItem
                            barBackground="#FF6F41"
                            count={stat.interview_finish}
                            text="Выход на работу"
                          />
                        </StyledStatisticList>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <StyledNearestInterviews>
                        <StyledNearestInterviewsHead>
                          <StyledStatisticTitle>
                            Ближайшие собеседования
                          </StyledStatisticTitle>
                          <StyledStatisticLink
                            to={`/vacancies/${vacancy.singleVacancy.id}/interviews`}
                          >
                            Смотреть все собеседования
                            <svg
                              width="6"
                              height="10"
                              viewBox="0 0 6 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </StyledStatisticLink>
                        </StyledNearestInterviewsHead>
                        <div className="row">
                          <div className="col-xl-8 col-lg-7">
                            <StyledSchedule>
                              <div className="schedule-head">
                                <span>Сегодня</span>
                              </div>
                              <div className="schedule-content">
                                <div className="schedule-column">
                                  {vacancy.interviews.interviewsToday.map(
                                    (r: VacancyInterviewUnixTime) => (
                                      <div className="schedule-row" key={r.id}>
                                        <span>
                                          {Moment(r.date).format('HH:MM')}
                                        </span>
                                        <span>{r.max_place} чел.</span>
                                      </div>
                                    ),
                                  )}
                                  {/*  <div className="schedule-row">*/}
                                  {/*    <span>17:00</span>*/}
                                  {/*    <span>5 чел.</span>*/}
                                  {/*  </div>*/}
                                  {/*  <div className="schedule-row">*/}
                                  {/*    <span>18:00</span>*/}
                                  {/*    <span>3 чел.</span>*/}
                                  {/*  </div>*/}
                                  {/* </div>*/}
                                  {/* <div className="schedule-column">*/}
                                  {/*  <div className="schedule-row">*/}
                                  {/*    <span>19:00</span>*/}
                                  {/*    <span>0 чел.</span>*/}
                                  {/*  </div>*/}
                                  {/*  <div className="schedule-row">*/}
                                  {/*    <span>20:00</span>*/}
                                  {/*    <span>5 чел.</span>*/}
                                  {/*  </div>*/}
                                  {/*  <div className="schedule-row">*/}
                                  {/*    <span>16:00</span>*/}
                                  {/*    <span>3 чел.</span>*/}
                                  {/*  </div>*/}
                                </div>
                              </div>
                            </StyledSchedule>
                          </div>
                          <div className="col-xl-4 col-lg-5 d-none d-lg-block">
                            <StyledSchedule transparent>
                              <div className="schedule-head">
                                <span>Завтра</span>
                              </div>
                              <div className="schedule-content">
                                <div className="schedule-column">
                                  {vacancy.interviews.interviewsNextDay.map(
                                    (r: VacancyInterviewUnixTime) => (
                                      <div className="schedule-row" key={r.id}>
                                        <span>
                                          {Moment(r.date).format('HH:MM')}
                                        </span>
                                        <span>{r.max_place} чел.</span>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </StyledSchedule>
                          </div>
                        </div>
                      </StyledNearestInterviews>
                    </div>
                  </div>
                </div>
              </StyledVacanciesHeaderContent>
            )}
          </StyledVacanciesHeader>
          <div className="container">
            <StyledNavLinksList>
              <li>
                <Link to={`/vacancies/${vacancy.singleVacancy.id}/cvs`}>
                  <span>Резюме</span>
                </Link>
              </li>
              <li>
                <Link
                  to={`/vacancies/${vacancy.singleVacancy.id}/questionnaire`}
                >
                  <span>Анкеты</span>
                </Link>
              </li>
              <li>
                <Link to={`/vacancies/${vacancy.singleVacancy.id}/interviews`}>
                  <span>График собеседований</span>
                </Link>
              </li>
              <li>
                <Link to={`/vacancies/${id}/edit`}>
                  <span>О вакансии</span>
                </Link>
              </li>
              <li>
                <Link to={`/vacancies/${id}/actions`}>
                  <span>Действия</span>
                </Link>
              </li>
            </StyledNavLinksList>
          </div>
        </>
      ) : (
        vacancy.error && (
          <>
            <NoVacancy />
            <AddNewVacancy />
          </>
        )
      )}
    </>
  );
};
export default VacanciesItemHeader;
