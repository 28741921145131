import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useTypedSelector from '../../../../hooks/useTypedSelector';
import { Company } from '../../../../types/mainPage';
import { useActions } from '../../../../hooks/useActions';
import arrow from '../../../../assets/image/arrow.svg';
import { sliceText } from '../../../../helpers/sliceText';

const setFuncColor = (value: string | boolean) => {
  switch (true) {
    case value === '1': {
      return '#67b7f0';
    }
    case value === '2': {
      return '#41bf75';
    }
    case value === '3': {
      return '#a173cb';
    }
    case value === '4': {
      return '#546ec8';
    }
    case value === '5': {
      return '#00a7b1';
    }
    default: {
      return {};
    }
  }
};

const StyledLoaderContainer = styled.div<{
  mobile: boolean;
}>`
  width: ${props => (props.mobile ? '233px' : '250px')};
  margin-left: auto;
  margin-right: ${props => (props.mobile ? '16px' : '0')};
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.mobile ? 'rgba(235, 236, 249, 1)' : '#4f4f4f'};
  border-radius: ${props => (props.mobile ? '30px' : '20px')};
  height: 30px;

  @media (min-width: 991px) {
    width: 260px;
    margin-right: 0;
  }
`;

const StyledSelectContainer = styled.div<{
  mobile: boolean;
}>`
  position: relative;
  width: ${props => (props.mobile ? '233px' : '250px')};
  margin-left: auto;
  margin-right: ${props => (props.mobile ? '16px' : '0')};
  order: 1;
  z-index: ${props => (props.mobile ? '1' : 'auto')};

  @media (min-width: 991px) {
    width: 260px;
    margin-right: 0;
  }
`;

const StyledButton = styled.button<{
  companyColor: string;
  openFlag: boolean;
  mobile: boolean;
}>`
  position: relative;
  z-index: 1002;
  display: block;
  background-color: ${props =>
    props.mobile ? 'rgba(235, 236, 249, 1)' : '#4f4f4f'};
  border-radius: ${props => (props.mobile ? '30px' : '20px')};
  width: 100%;
  height: ${props => (props.mobile ? '48px' : '30px')};
  color: ${props => (props.mobile ? '#333333' : '#ffffff')};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: calc(50% - 7px);
    left: 9px;
    background-color: ${props =>
      props.companyColor ? setFuncColor(props.companyColor) : '#67b7f0'};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: calc(50% - 10px);
    right: 7px;
    background-color: #ffffff;
    background-image: url(${arrow});
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: ${props =>
      props.openFlag ? 'rotate(-180deg)' : 'rotate(0deg)'};
    transition: transform 0.5s ease-out;
  }
`;

const StyledOptionListWrap = styled.div<{
  mobile: boolean;
}>`
  position: absolute;
  width: 100%;
  z-index: 1001;
  top: calc(100% - 20px);
  left: 0;
  background-color: ${props => (props.mobile ? '#ffffff' : '#333333')};
  padding-top: 20px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 10px;
    bottom: -10px;
    left: 0;
    background-color: ${props => (props.mobile ? '#ffffff' : '#333333')};
    border-radius: 0 0 22px 22px;
  }
`;

const StyledOptionList = styled.div`
  max-height: 135px;
  overflow-y: auto;

  @media (min-width: 991px) {
    max-height: 60vh;
  }
`;

const StyledOptionButton = styled.button<{
  companyColor: string;
  mobile: boolean;
}>`
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 19px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 25px;
  text-transform: uppercase;
  color: ${props => (props.mobile ? '#333333' : '#ffffff')};
  text-align: left;

  &:hover,
  &:focus {
    background-color: ${props => (props.mobile ? 'transparent' : '#4f4f4f')};
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    background-color: ${props =>
      props.companyColor ? setFuncColor(props.companyColor) : '#67b7f0'};
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    border-radius: 50%;
  }
`;

const StyledOverlay = styled.div<{
  mobile: boolean;
}>`
  position: fixed;
  display: ${props => (props.mobile ? 'none' : 'block')};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 1000;
`;

interface PropsInterface {
  mobile: boolean;
}

const CompaniesSelector: React.FC<PropsInterface> = ({ mobile }) => {
  const { companies, loaded, active } = useTypedSelector(
    r => r.mainData.companies,
  );
  const { setActiveCompany } = useActions();
  const history = useHistory();
  const handleChangeActiveCompany = (id: string) => {
    setActiveCompany(id);
  };

  const [open, setOpen] = useState(false);

  const handleSelectClick = () => {
    setOpen(!open);
  };

  const handleUpdate = () => {
    const path = window.location.pathname;
    history.push('/');
    history.replace(path);
  };

  const handleOptionClick = async (evt: any) => {
    await setOpen(false);
    await handleChangeActiveCompany(evt.target.dataset.value);
    await handleUpdate();
  };

  return (
    <>
      {!loaded ? (
        <StyledLoaderContainer mobile={mobile}>
          <Loader type="ThreeDots" color="#fff" height={30} width={20} />
        </StyledLoaderContainer>
      ) : (
        <>
          <StyledSelectContainer mobile={mobile}>
            {companies.map((company: Company) =>
              active === company.id ? (
                <StyledButton
                  type="button"
                  key={company.id}
                  aria-label="Выбор компании"
                  onClick={handleSelectClick}
                  companyColor={String(company.color)}
                  openFlag={open}
                  mobile={mobile}
                >
                  {sliceText(24, company.name)}
                </StyledButton>
              ) : (
                ''
              ),
            )}
            {open && (
              <StyledOptionListWrap mobile={mobile}>
                <StyledOptionList>
                  {companies.map((company: Company) => (
                    <StyledOptionButton
                      type="button"
                      key={company.id}
                      data-value={company.id}
                      onClick={handleOptionClick}
                      companyColor={String(company.color)}
                      mobile={mobile}
                    >
                      {company.name}
                    </StyledOptionButton>
                  ))}
                </StyledOptionList>
              </StyledOptionListWrap>
            )}
          </StyledSelectContainer>
          {open && (
            <StyledOverlay onClick={() => setOpen(false)} mobile={mobile} />
          )}
        </>
      )}
    </>
  );
};
export default CompaniesSelector;
