import React from 'react';
import styled from 'styled-components';
import { sliceText } from '../../../helpers/sliceText';
import StyledLink from '../StyledComponents/StyledLink';

const StyledCard = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding: 25px 25px 30px;
  border: 1px solid rgba(205, 206, 222, 0.8);
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 325px;

  .image-wrap {
    position: relative;
    flex-shrink: 0;
    width: 85px;
    height: 85px;
    margin: 0 auto 20px;

    &::before {
      content: attr(data-count);
      position: absolute;
      left: 63px;
      top: 5px;
      z-index: 10;
      display: block;
      min-width: 21px;
      padding: 3px 7px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      background-color: #ff6363;
      color: #ffffff;
    }
  }

  .image {
    display: block;
    width: 100%;
  }

  .title {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    white-space: nowrap;
  }

  .info {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #333333;
  }

  .button {
    width: 100%;
    margin-top: auto;
  }
`;

interface CarouselItemProps {
  icon: string;
  title: string;
  desc: string;
  count: number;
}

const CarouselActionsItem: React.FunctionComponent<CarouselItemProps> = ({
  icon,
  title,
  desc,
  count,
}) => {
  const DESC_LENGTH = 28;
  return (
    <StyledCard>
      <div className="image-wrap" data-count={count}>
        <img className="image" src={icon} alt="#" />
      </div>
      <span className="title">{title}</span>
      <span className="info">
        {sliceText(DESC_LENGTH, desc)}
        <br />
        Доступно {count} шт.
      </span>
      <div className="button">
        <StyledLink
          text="Получить"
          path="/"
          inlineStyles={{
            padding: '10px',
          }}
        ></StyledLink>
      </div>
    </StyledCard>
  );
};

export default CarouselActionsItem;
