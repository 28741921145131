import { Dispatch } from 'redux';
import axios from 'axios';
import Moment from 'moment';
import { config, url } from './actionsAssets';
import {
  CVI,
  ID,
  InterviewState,
  NewVacancy,
  SeekerReport,
  VacanciesActions,
  VacanciesActionTypes,
  Vacancy,
  //  VacancyActionsI,
  VacancyTestTaskI,
} from '../../types/vacancies';
import { DateTimeFormats } from '../../helpers/formatEnum';
import { getQueryString } from '../queryUtils';

export const clearSingleVacancy = () => {
  return (dispatch: Dispatch<VacanciesActions>) => {
    dispatch({
      type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY,
    });
  };
};

export interface StatDates {
  dateFrom: string;
  dateTo: string;
}

export const fetchSingleVacancyStatistics = (
  id: string | number,
  dates: StatDates = {
    dateFrom: Moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    dateTo: Moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  },
) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    try {
      const statistics = await axios.get(
        `${url}vacancy/${id}/statistics/?date_from=${dates.dateFrom}&date_to=${dates.dateTo}`,
        config,
      );
      return dispatch({
        type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_STATISTIC,
        payload: statistics.data,
      });
    } catch (e) {
      return e; //console.log(e);
    }
  };
};

export const fetchListCVS = (
  page = 0,
  limit = 20,
  //id?: string,
  //status?: string,
  filter: any,
) => {
  const paginator = `&offset=${limit * page}`;
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const list = await axios.get(
      `${url}resume/list/?limit=${limit}${paginator}&metaInfo=1&${getQueryString(
        filter,
      )}`,
      config,
    );
    return dispatch({
      type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_CVS,
      payload: list.data,
    });
  };
};

export const fetchSummaryReport = (id: string) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const list = await axios.get(`${url}report/${id}/`, config);

    return dispatch({
      type: VacanciesActionTypes.FETCH_SUMMARY_REPORT,
      payload: list.data,
    });
  };
};

export const fetchListVacancies = (page = 0, limit = 6, archived = false) => {
  let paginator = '';

  if (page >= 0) {
    paginator = `&offset=${limit * page}`;
  }

  return async (dispatch: Dispatch<VacanciesActions>) => {
    const list = await axios.get(
      `${url}vacancy/list/?meta[]=statistic&limit=${limit}${paginator}&status=${
        archived ? 'N' : 'Y'
      }`,
      config,
    );
    return dispatch({
      type: archived
        ? VacanciesActionTypes.FETCH_LIST_VACANCIES_ARCHIVE
        : VacanciesActionTypes.FETCH_LIST_VACANCIES,
      payload: list.data,
    });
  };
};
export const fetchSingleVacancy = (id: string) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    try {
      await dispatch({ type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY });
      const vacancy = await axios.get(`${url}vacancy/${id}/`, config);
      return dispatch({
        type: VacanciesActionTypes.FETCH_SINGLE_VACANCY,
        payload: vacancy.data,
      });
    } catch (e) {
      return dispatch({
        type: VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY,
      });
    }
  };
};

interface FetchSVInterviewPeriod {
  from: string;
  to: string;
}

export const fetchSvInterview = (
  id: string,
  { today = false, interviewer = '' },
  period?: FetchSVInterviewPeriod,
  nextDay = false,
) => {
  // eslint-disable-next-line consistent-return
  return async (dispatch: Dispatch<VacanciesActions>) => {
    let dayFrom;
    let dayTo;
    if (today) {
      dayFrom = Moment().format(DateTimeFormats.DATE_FORMAT_API);
      dayTo = Moment().format(DateTimeFormats.DATE_FORMAT_API);
    }
    if (period) {
      dayFrom = period.from;
      dayTo = period.to;
    }
    // eslint-disable-next-line no-nested-ternary
    let dayString = today
      ? `&date_from=${dayFrom}&date_to=${dayTo}`
      : period
      ? `&date_from=${dayFrom}&date_to=${dayTo}`
      : '';
    if (nextDay) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const nextDay = Moment()
        .add(1, 'days')
        .format(DateTimeFormats.DATE_FORMAT_API);

      dayString = `&date_from=${nextDay}&date_to=${nextDay}`;
    }
    const interviews = await axios.get(
      `${url}interview/list/?resume=Y&vacancy_id=${id}${dayString}&manager_id=${interviewer}&sortBy=date&sortDir=desc`,
      config,
    );
    if (nextDay) {
      dispatch({
        type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_NEXT_DAY,
        payload: interviews.data,
      });
    } else {
      dispatch({
        type: today
          ? VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY
          : VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS,
        payload: interviews.data,
      });
    }
  };
};

export const addNewInterview = (interview: InterviewState) => {
  return axios.post(`${url}interview/add/`, interview, config);
};
export const addNewCv = (cv: CVI[]) => {
  return axios.post(`${url}resume/add/`, cv, config);
};

export const setPlacesInterview = (places: number, id: string) => {
  return axios.post(
    `${url}interview/${id}/set/place/`,
    { max_place: places },
    config,
  );
};

export const setInterviewCVReport = (
  interview_id: number | string,
  resume_id: number | string,
  report: SeekerReport,
) => {
  return axios.post(
    `${url}interview/${interview_id}/report/${resume_id}/`,
    report,
    config,
  );
};

export const getInterviewCVReport = (
  interview_id: number | string,
  resume_id: number | string,
) => {
  return axios.get(
    `${url}interview/${interview_id}/report/${resume_id}/`,
    config,
  );
};

export const setRegisterInterviewCV = (id: string, resume_id: string) => {
  return axios.post(
    `${url}interview/${id}/registration/add/`,
    { resume_id },
    config,
  );
};
export const sendVacancyTests = (value: VacancyTestTaskI, id: ID) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const res = await axios
      .put(`${url}trialwork/${id}/`, value, config)
      .then(r => undefined);
  };
};
export const fetchTestsVacancy = (vacancyId: ID, testing = false) => {
  const param = testing ? 'testing' : 'avtesting';
  return async (dispatch: Dispatch<VacanciesActions>) => {
    await axios.get(`${url}${param}/${vacancyId}/`, config).then(r => {
      return testing
        ? dispatch({
            type: VacanciesActionTypes.FETCH_TESTING_VACANCY,
            payload: r.data,
          })
        : dispatch({
            type: VacanciesActionTypes.FETCH_AV_TESTING_VACANCY,
            payload: r.data,
          });
    });
  };
};

export const sendVacancyAutoStage = (value: string[], id: ID) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const res = await axios
      .put(`${url}vacancy/${id}/autostage/`, { actions: value }, config)
      .then(r => console.log(r.data));
  };
};

export const setAssignerInterview = (assigner: string, id: string) => {
  return axios.post(
    `${url}interview/${id}/set/manager/`,
    { responsible_user: assigner },
    config,
  );
};

export const setInterviewSeekerStatus = (
  interview_id: number | string,
  resume_id: number | string,
  status: 'Y' | 'N' | '',
) => {
  return axios.patch(
    `${url}interview/${interview_id}/registration/${resume_id}/`,
    { status },
    config,
  );
};

export const addNewVacancy = (vacancy: NewVacancy) => {
  return axios.post(`${url}vacancy/add/`, vacancy, config);
};

export const updateVacancy = (id: ID, vacancy: Vacancy, inList = false) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.put(`${url}vacancy/${id}/`, vacancy, config);
    return dispatch({
      type: inList
        ? VacanciesActionTypes.PUT_SINGLE_VACANCY
        : VacanciesActionTypes.FETCH_SINGLE_VACANCY,
      payload: item.data,
    });
  };
};
export const fetchStageVacancy = (id: ID) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.get(`${url}vacancy/${id}/autostage/`, config);
    return dispatch({
      type: VacanciesActionTypes.FETCH_STAGE_VACANCY,
      payload: item.data,
    });
  };
};
export const getTrialWork = (id: ID) => {
  return axios.get(`${url}trialwork/${id}/`, config);
};

export const fetchTestingDataList = () => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.get(`${url}testing/list/`, config);
    return dispatch({
      type: VacanciesActionTypes.FETCH_TESTING_DATA_LIST,
      payload: item.data,
    });
  };
};

export const fetchAvTestingDataList = () => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.get(`${url}avtesting/list/`, config);
    return dispatch({
      type: VacanciesActionTypes.FETCH_AV_TESTING_DATA_LIST,
      payload: item.data,
    });
  };
};
export const setTestingQuestion = (
  vacancyID: ID,
  questions: number[],
  testing = false,
) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const data = { list: questions };
    let item;
    if (testing) {
      item = await axios.post(`${url}testing/${vacancyID}/set/`, data, config);
    } else {
      item = await axios.post(
        `${url}avtesting/${vacancyID}/set/`,
        data,
        config,
      );
    }

    console.log(item);
  };
};

export const fetchResumeList = (vacancyId: ID, status: string) => {
  return async (dispatch: Dispatch<VacanciesActions>) => {
    const item = await axios.get(`${url}resume/list/`, config);
    console.log(item);
    // return dispatch({
    //   type: VacanciesActionTypes.FETCH_AV_TESTING_DATA_LIST,
    //   payload: item.data,
    // });
  };
};
