import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrowRight from '../../../../assets/image/arrow-right.svg';
import useTypedSelector from '../../../../hooks/useTypedSelector';
import { getFirstLetters } from '../../../../helpers/getFirstLetters';

const StyledProfileDiv = styled.div`
  background-color: #4f4f4f;
  padding-top: 27px;
  padding-bottom: 24px;
  padding-right: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

const StyledColumnDiv = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  &:first-child {
    max-width: calc(100% / 4);
    width: 100%;
    padding-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:last-child {
    padding-left: 11px;
    max-width: calc(100% / 4 * 3);
    width: 100%;
    padding-right: 2px;
  }
`;

const StyledProfileBg = styled.div`
  background: #67b7f0;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  span {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const StyledProfileName = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 3px;
  }
`;

const StyledProfileLink = styled(Link)`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #e0e0e0;
  align-items: center;
  &:hover {
    color: #e0e0e0;
  }
  span {
    margin-left: 8px;
    img {
      width: 3px;
      height: 7px;
    }
  }
`;

const MobileProfile: React.FunctionComponent = () => {
  const { profile } = useTypedSelector(r => r.auth);

  return (
    <StyledProfileDiv>
      <StyledColumnDiv>
        <StyledProfileBg>
          <span>{getFirstLetters(profile.last_name, profile.name)}</span>
        </StyledProfileBg>
      </StyledColumnDiv>
      <StyledColumnDiv>
        <StyledProfileName>
          <div>
            {profile.last_name} {profile.name}
          </div>
          <StyledProfileLink to="/">
            Редактировать профиль
            <span>
              <img src={arrowRight} alt="#" />
            </span>
          </StyledProfileLink>
        </StyledProfileName>
      </StyledColumnDiv>
    </StyledProfileDiv>
  );
};

export default MobileProfile;
