import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import Select from 'react-select';
import {
  StyledTitle,
  StyledBlock,
  StyledLinkWrap,
} from './StyledVacancyComponents';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  fetchAvTestingDataList,
  fetchTestingDataList,
  fetchTestsVacancy,
  sendVacancyTests,
} from '../../../store/actions/vacancies';
import { ID, ISelectType, ISelectVal } from '../../../types/vacancies';
import useTypedSelector from '../../../hooks/useTypedSelector';
import SelectComponent from '../../Common/SelectComponent/SelectComponent';

const StyledTestLabel = styled.label`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const StyledSelectWrap = styled.div`
  max-width: 360px;
  @media (max-width: 991px) {
    margin-bottom: 24px;
  }
`;
export const getOptions = (
  val: ISelectType[],
  typeValue: string,
  name: string,
): any => {
  return val.map((n: any) => {
    return { value: n[typeValue], label: n[name] };
  });
};
const VacancyTestSkills: React.FunctionComponent<{
  vacancyId: ID;
  isVideoTesting: boolean;
  isTesting?: boolean;
  isAvTesting?: boolean;
  handleChangeTesting: (val: ISelectType) => void;
  handleChangeAvTesting: (val: ISelectType) => void;
}> = ({
  vacancyId,
  isVideoTesting,
  isTesting,
  handleChangeTesting,
  handleChangeAvTesting,
}) => {
  const dispatch = useDispatch();
  const { vacancy } = useTypedSelector(r => r.vacancies);
  const getOptionsFunc = (val: any[], typeID: string, name: string) => {
    return val.length !== 0 ? getOptions(val, typeID, name) : [];
  };

  useEffect(() => {
    if (isTesting) {
      dispatch(fetchTestingDataList());
      dispatch(fetchTestsVacancy(vacancyId, true));
    }
  }, [isTesting]);

  useEffect(() => {
    if (isVideoTesting) {
      dispatch(fetchAvTestingDataList());
      dispatch(fetchTestsVacancy(vacancyId, false));
    }
  }, [isVideoTesting]);
  return (
    <div className="sticky-right testing" id="testing">
      <>
        {isTesting && (
          <>
            <StyledTitle>Тестирование навыков</StyledTitle>
            <StyledSelectWrap>
              <SelectComponent
                handleChange={handleChangeTesting}
                options={getOptions(vacancy.testingList, 'id', 'name')}
                initialOption={getOptionsFunc(
                  vacancy.initialTesting,
                  'test_id',
                  'name',
                )}
                multi
              />
            </StyledSelectWrap>
          </>
        )}
        <div style={{ marginTop: '30px' }}>
          {isVideoTesting && (
            <>
              <StyledTitle>Аудио и видео интервью</StyledTitle>
              <StyledSelectWrap>
                <SelectComponent
                  handleChange={handleChangeAvTesting}
                  initialOption={getOptionsFunc(
                    vacancy.initialAvTesting,
                    'avtesting_id',
                    'vacancy_name',
                  )}
                  options={getOptions(vacancy.avTestingList, 'id', 'name')}
                  multi
                />
              </StyledSelectWrap>
            </>
          )}
        </div>
      </>

      <StyledLinkWrap>
        <StyledLink text="Сохранить" path="/" />
      </StyledLinkWrap>
    </div>
  );
};
export default React.memo(VacancyTestSkills);
