import React from 'react';
import styled from 'styled-components';
import StyledLink from '../Template/StyledComponents/StyledLink';
import StyledSwitch from '../Template/StyledComponents/StyledSwitch';
import { Vacancy } from '../../types/vacancies';

const StyledCard = styled.article`
  padding: 19px 16px 26px 16px;
  border: 1px solid #cdcede;
  border-radius: 10px;
  width: 100%;
  max-width: 551px;

  @media (min-width: 576px) {
    padding: 19px 16px 26px 16px;
    display: grid;
    grid-template-columns: 1fr 205px;
    grid-column-gap: 15px;
  }

  @media (min-width: 768px) {
    padding: 31px 28px;
  }

  .title-wrap {
    display: grid;
    grid-template-columns: 1fr 43px;
    grid-column-gap: 16px;
    margin-bottom: 7px;

    @media (min-width: 576px) {
      grid-template-columns: 1fr 105px;
      grid-column: 1 / -1;
      margin-bottom: 16px;
    }
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }

  .switch-wrap {
    @media (min-width: 576px) {
      display: flex;
      align-items: center;
    }
  }

  .switch-label {
    display: none;
    font-size: 11px;
    line-height: 18px;
    text-align: right;
    color: #828282;
    margin-right: 8px;

    @media (min-width: 576px) {
      display: block;
    }
  }

  .btns-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 14px;

    @media (min-width: 576px) {
      grid-template-columns: 1fr;
      grid-auto-rows: min-content;
      grid-row-gap: 13px;
    }
  }

  .list {
    margin-bottom: 19px;
    width: 70%;
  }

  .list-row {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 18px;
  }

  .list-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #828282;
  }

  .list-number {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #4f4f4f;
  }
`;

interface CardItem {
  vacancy: Vacancy;
  handleVacancy: (vacancy: Vacancy) => void;
}

const VacanciesCard: React.FC<CardItem> = ({ vacancy, handleVacancy }) => {
  return (
    <StyledCard>
      <div className="title-wrap">
        <h3 className="title">{vacancy.name}</h3>
        <div className="switch-wrap">
          <div className="switch-label">Активная</div>
          <StyledSwitch
            id={`activeVacancies-${vacancy.id}`}
            handleChange={() => {
              handleVacancy(vacancy);
            }}
            checked={vacancy.status === 'Y'}
          />
        </div>
      </div>
      <dl className="list">
        <div className="list-row">
          <dt className="list-title">Резюме:</dt>
          <dd className="list-number">{vacancy.statistic?.resume}</dd>
        </div>
        <div className="list-row">
          <dt className="list-title">Анкеты</dt>
          <dd className="list-number">{vacancy.statistic?.finished}</dd>
        </div>
      </dl>
      <div className="btns-wrap">
        <StyledLink
          text="Подробнее"
          path={`/vacancies/${vacancy.id}`}
          inlineStyles={{
            height: '38px',
          }}
          $whiteBackground
          $border
        ></StyledLink>
        <StyledLink
          text="Запустить найм"
          path={`/vacancies/${vacancy.id}/actions`}
          inlineStyles={{
            height: '38px',
          }}
        ></StyledLink>
      </div>
    </StyledCard>
  );
};
export default VacanciesCard;
