import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import StyledInput from './StyledInput';

const StyledDropdown = styled.div`
  position: absolute;
  padding: 5px 0;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 280px;
  overflow-y: auto;
  border-bottom-left-radius: 10px;
  border-bottom-rught-radius: 10px;
  background-color: #ffffff;
  filter: drop-shadow(0px 0.99866px 9.9866px rgba(2, 1, 72, 0.16));
  z-index: 2;

  button {
    display: block;
    width: 100%;
    text-align: left;
    background-color: transparent;
    text-shadow: none;
    border: none;
    padding: 5px 15px;

    &:hover,
    &:focus {
      background-color: #f3f3f7;
    }
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.03);
  left: 0;
  top: 0;
  z-index: 1;
`;

interface PropsInterface {
  array: [];
  name: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  initialValue: string;
}

const StyledSelectAutoComplete: React.FC<PropsInterface> = ({
  array,
  name,
  setFieldValue,
  initialValue,
}) => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [term, setTerm] = useState<string>('');
  useEffect(() => {
    if (initialValue) setTerm(initialValue);
  }, [initialValue]);
  const handleInputFocus = () => {
    setDropdown(true);
  };

  const searchItems = (items: [], label: string) => {
    if (label === '') {
      return items;
    }
    return items.filter((item: any) => {
      return item.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
    });
  };
  useEffect(() => {
    if (location.pathname === '/companies/add/') {
      setFieldValue(name, '');
      setTerm('');
    }
  }, [location.pathname]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const handleButtonClick = (city: string, id: string) => {
    setTerm(city);
    setFieldValue(name, city);
    setDropdown(false);
  };

  const visibleItems = searchItems(array, term);

  return (
    <>
      <StyledInput
        additionalClass={dropdown ? 'active' : ''}
        handleFocus={handleInputFocus}
        name={name}
        value={term}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e);
        }}
        autoComplete="off"
        $arrow
      />
      {dropdown && (
        <>
          <StyledDropdown>
            {visibleItems.map((r: any) => {
              return (
                <button
                  key={r.id}
                  data-value={r.name}
                  type="button"
                  onClick={() => handleButtonClick(r.name, r.id)}
                >
                  {r.name}
                </button>
              );
            })}
          </StyledDropdown>
          <StyledOverlay onClick={() => setDropdown(false)} />
        </>
      )}
    </>
  );
};

export default StyledSelectAutoComplete;
