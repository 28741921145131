import { Currency } from './vacancies';

export interface IDirection {
  id: number;
  name: string;
}

export interface IStatus {
  name: string;
  sort: number;
  status_id: string;
}

export interface IVacancyField {
  ID: number;
  NAME: string;
}

export interface IConfiguration {
  [key: string]: string | number | boolean;
}

export interface IVacancy {
  id: number;
  name: string;
  company_id: number;
  status: string;
  currency_code: Currency;
  salary_from: number | undefined;
  salary_to: number | undefined;
  type_location: number;
  type_schedule: number;
  type_frequency: number;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
  url: string;
}

export interface IBitrixVacancy {
  id: number;
  name: string;
}

export interface IConnection {
  hr_vacancy_id: number;
  b24_vacancy_id: number | null;
  b24_vacancy_name: string | null;
}

export enum ConnectorActionTypes {
  SET_WEBHOOK = 'SET_WEBHOOK',
  RESET_CONFIGURATION = 'RESET_CONFIGURATION',
  SET_CONFIGURATION = 'SET_CONFIGURATION',
  GET_CONFIGURATION = 'GET_CONFIGURATION',
  GET_DIRECTIONS = 'GET_DIRECTIONS',
  GET_STATUSES = 'GET_STATUSES',
  GET_VACANCY_FIELDS = 'GET_VACANCY_FIELDS',
  SET_ERROR = 'SET_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  GET_VACANCIES_HR = 'GET_VACANCIES_HR',
  GET_VACANCIES_BITRIX = 'GET_VACANCIES_BITRIX',
  GET_VACANCIES_CONNECTIONS = 'GET_VACANCIES_CONNECTIONS',
  SAVE_VACANCIES_CONNECTIONS = 'SAVE_VACANCIES_CONNECTIONS',
}

interface SetWebhookActions {
  type: ConnectorActionTypes.SET_WEBHOOK;
  payload: string;
}

interface ResetConfigurationActions {
  type: ConnectorActionTypes.RESET_CONFIGURATION;
  payload: null;
}

interface SetConfigurationActions {
  type: ConnectorActionTypes.SET_CONFIGURATION;
  payload: string;
}

interface GetConfigurationActions {
  type: ConnectorActionTypes.GET_CONFIGURATION;
  payload: string;
}

interface GetDirectionsActions {
  type: ConnectorActionTypes.GET_DIRECTIONS;
  payload: string;
}

interface GetStatuses {
  type: ConnectorActionTypes.GET_STATUSES;
  payload: string;
}

interface GetVacancyFields {
  type: ConnectorActionTypes.GET_VACANCY_FIELDS;
  payload: string;
}

interface SetError {
  type: ConnectorActionTypes.SET_ERROR;
  payload: { name: string; value: string };
}

interface SetIsLoading {
  type: ConnectorActionTypes.SET_IS_LOADING;
  payload: { name: string; value: boolean };
}

interface GetVacanciesHr {
  type: ConnectorActionTypes.GET_VACANCIES_HR;
  payload: [];
}

interface GetVacanciesBitrix {
  type: ConnectorActionTypes.GET_VACANCIES_BITRIX;
  payload: [];
}

interface GetVacanciesConnections {
  type: ConnectorActionTypes.GET_VACANCIES_CONNECTIONS;
  payload: [];
}

interface SaveVacanciesConnections {
  type: ConnectorActionTypes.SAVE_VACANCIES_CONNECTIONS;
  payload: [];
}

export type ConnectorActions =
  | ResetConfigurationActions
  | SetWebhookActions
  | SetConfigurationActions
  | GetConfigurationActions
  | GetDirectionsActions
  | GetStatuses
  | GetVacancyFields
  | SetError
  | SetIsLoading
  | GetVacanciesHr
  | GetVacanciesBitrix
  | GetVacanciesConnections
  | SaveVacanciesConnections;
