import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  ScheduleEvent,
  ScheduleEventNumber,
  ScheduleEventWrap,
} from '../StyledVacanciesComponents/StyledVacanciesComponents';

import {
  VacancyInterviewUnixTime,
  VacancySeeker,
} from '../../../types/vacancies';
import useTypedSelector from '../../../hooks/useTypedSelector';
import VacancyInterviewsDayItem, {
  HandleInterViewSeeker,
  HandleModalReport,
} from './VacancyInterviewsDayItem';

const ScheduleItem = styled.div`
  display: flex;
  padding: 20px 40px 35px 25px;
  border-radius: 11px;
  background: #ffffff;

  @media (max-width: 991px) {
    padding: 22px 18px 32px 18px;
    flex-direction: column;
  }
`;

const ScheduleItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 20%;
  padding-top: 10px;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding: 0px;
    width: 100%;
  }

  @media (min-width: 991px) {
    margin-right: 10px;
  }
`;

const ScheduleItemContent = styled.div`
  width: 80%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ScheduleItemInfoTop = styled.div`
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    display: flex;
  }
`;

const ScheduleItemInfoBottom = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const ScheduleItemTime = styled.span`
  display: block;
  margin-bottom: 10px;
  color: #4f4f4f;

  @media (max-width: 991px) {
    margin-right: 19px;
  }
`;

const ScheduleItemStatus = styled.span<{
  done?: boolean;
}>`
  display: inline-block;
  margin-bottom: auto;
  padding: 2px 7px;
  border: 1px solid;
  border-color: ${props => (props.done ? '#27ae60;' : '#ff6363')};
  border-radius: 3px;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${props => (props.done ? '#27ae60;' : '#ff6363')};
`;

const StyledLinkWrap = styled.div`
  margin-top: 15px;
  padding-left: 55px;

  @media (max-width: 991px) {
    padding-left: 0;
  }

  @media (max-width: 576px) {
    margin-top: 0;
  }
`;

const StyledLabel = styled.label`
  margin: 0;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #4f4f4f;
  margin-right: 14px;
  @media (min-width: 991px) {
    margin-bottom: 10px;
  }
  @media (max-width: 1200px) {
    margin-right: 27px;
  }
`;

const ScheduleEventDelete = styled.button`
  width: 100%;
  background-color: #ffffff;
  display: inline-block;
  padding: 7px 5px;
  border: 1px solid #cdcede;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #828282;
  transition: all 0.3s ease;

  @media (min-width: 991px) {
    font-size: 14px;
    line-height: 20px;
    padding: 7px 10px;
  }

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #828282;
    border-color: #cdcede;
  }
`;

interface VacancyInterviewsDayInterface {
  vacancyDate: string;
  handleChangePlaces: (places: number, interviewId: string) => void;
  handleChangeAssigner: (assigner: string, interviewId: string) => void;
  handleModalReport: HandleModalReport;
  handleSetInterviewSeeker: HandleInterViewSeeker;
}

const VacancyInterviewEmptySpaces: React.FC<{
  number: number;
  start: number;
  maxPlaces: number;
}> = ({ number, start, maxPlaces, children }) => {
  let i = 0;
  const spaces = [];
  const freeSpaces = maxPlaces - start;
  while (i < number) {
    // eslint-disable-next-line no-plusplus
    i++;
    spaces.push(
      <ScheduleEvent key={i}>
        <ScheduleEventNumber>№ {start + i}</ScheduleEventNumber>
        <ScheduleEventWrap>
          <span className="text">Место доступно для записи</span>
          <span className="wrapper">{freeSpaces !== 1 && children}</span>
        </ScheduleEventWrap>
      </ScheduleEvent>,
    );
  }
  return <>{spaces}</>;
};

const VacancyInterviewsDay: React.FC<VacancyInterviewsDayInterface> = ({
  vacancyDate,
  handleChangePlaces,
  handleChangeAssigner,
  handleSetInterviewSeeker,
  handleModalReport,
}) => {
  const compareDate = useCallback(date => moment().unix() < date, []);
  const { interviews } = useTypedSelector(r => r.vacancies.vacancy.interviews);

  return (
    <>
      {interviews[vacancyDate].map((item: VacancyInterviewUnixTime) => (
        <ScheduleItem key={item.id}>
          <ScheduleItemInfo>
            <ScheduleItemInfoTop>
              <ScheduleItemTime>
                {moment.unix(item.date).format('HH:mm')}
              </ScheduleItemTime>
              {compareDate(item.date) ? (
                <ScheduleItemStatus>Ожидается</ScheduleItemStatus>
              ) : (
                <ScheduleItemStatus done>Завершено</ScheduleItemStatus>
              )}
            </ScheduleItemInfoTop>
            <ScheduleItemInfoBottom>
              <StyledLabel htmlFor="select-person">Собеседует:</StyledLabel>
              <StyledSelect
                id="select-person"
                name="assigner"
                value={item.responsible_user}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeAssigner(e.target.value, item.id);
                }}
                disabled={!compareDate(item.date)}
                inlineStyles={{
                  height: '32px',
                  padding: '5px 10px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#4F4F4F',
                  borderColor: '#CDCEDE',
                  paddingRight: '29px',
                }}
              >
                <option value={item.responsible_user}>
                  {item.name} {item.last_name}
                </option>
              </StyledSelect>
            </ScheduleItemInfoBottom>
          </ScheduleItemInfo>
          <ScheduleItemContent>
            {item.seekers.map((seeker: VacancySeeker, i: number) => (
              <VacancyInterviewsDayItem
                key={seeker.seeker_id}
                seeker={seeker}
                handleSetInterviewSeeker={handleSetInterviewSeeker}
                handleModalReport={handleModalReport}
                iteration={i}
              />
            ))}
            {compareDate(item.date) && (
              <>
                <VacancyInterviewEmptySpaces
                  number={parseInt(item.max_place, 10) - item.seekers.length}
                  start={item.seekers.length}
                  maxPlaces={parseInt(item.max_place, 10)}
                >
                  <ScheduleEventDelete
                    type="button"
                    onClick={() => {
                      handleChangePlaces(
                        parseInt(item.max_place, 10) - 1,
                        item.id,
                      );
                    }}
                  >
                    Удалить свободное место
                  </ScheduleEventDelete>
                </VacancyInterviewEmptySpaces>
                <StyledLinkWrap>
                  <StyledLink
                    text="Добавить место"
                    button
                    type="button"
                    handleСlick={() => {
                      handleChangePlaces(
                        parseInt(item.max_place, 10) + 1,
                        item.id,
                      );
                    }}
                    inlineStyles={{
                      height: '36px',
                      padding: '10px',
                    }}
                  >
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="7.60469"
                        cy="7"
                        rx="6.95674"
                        ry="7"
                        fill="black"
                      />
                      <path
                        d="M7.60428 5V9M9.59192 7L5.61664 7"
                        stroke="#FEDC2D"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </StyledLink>
                </StyledLinkWrap>
              </>
            )}
          </ScheduleItemContent>
        </ScheduleItem>
      ))}
    </>
  );
};
export default VacancyInterviewsDay;
