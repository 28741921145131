import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useActions } from '../../../../hooks/useActions';
import {
  CompanyStaff,
  StaticDataType,
  StaffRuleList,
} from '../../../../types/companies';
import StyledLabel from '../../../Template/StyledComponents/StyledLabel';
import StyledInput from '../../../Template/StyledComponents/StyledInput';
import StyledLink from '../../../Template/StyledComponents/StyledLink';
import { SelectDiv } from '../../../Template/StyledComponents/StyledSelect';

const StyledModalWrapper = styled.div`
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 15px;

  @media (min-width: 768px) {
    grid-row-gap: 19px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 15px;

  @media (min-width: 768px) {
    grid-column: 1 / -1;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 24px;
  }
`;

const StyledBtnWrap = styled.div`
  grid-column: 1 / -1;
`;

const StyledError = styled.p`
  margin: 0;
  margin-top: 3px;
  color: #eb5757;
  font-size: 12px;
`;

interface Props {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  edit: boolean;
  staffId: CompanyStaff;
  companyId: string;
  staticData: StaticDataType;
}

interface MyFormValues {
  name: string;
  last_name: string;
  email: string;
  type: string | number;
  rule_vacancy: string | number;
  rule_interview: string | number;
  rule_resume: string | number;
}

const ViewCompanyForm: React.FunctionComponent<Props> = ({
  modal,
  setModal,
  edit,
  staffId,
  companyId,
  staticData,
}) => {
  const initialValues: MyFormValues = {
    name: staffId ? staffId.name : '',
    last_name: staffId ? staffId.last_name : '',
    email: staffId ? 'info@mebot24-hr.ru' : '',
    type: staffId ? staffId.type : '',
    rule_vacancy: staffId ? staffId.rule_vacancy : '',
    rule_interview: staffId ? staffId.rule_interview : '',
    rule_resume: staffId ? staffId.rule_resume : '',
  };
  const { createCompanyStaff, updateCompanyStaff } = useActions();
  return (
    <>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Вы не заполнили поле'),
              last_name: Yup.string().required('Вы не заполнили поле'),
              email: Yup.string().required('Вы не заполнили поле'),
              rule_vacancy: Yup.string().required('Вы не заполнили поле'),
              type: Yup.string().required('Вы не заполнили поле'),
              rule_interview: Yup.string().required('Вы не заполнили поле'),
              rule_resume: Yup.string().required('Вы не заполнили поле'),
            })}
            initialValues={initialValues}
            onSubmit={(values: MyFormValues, actions) => {
              const valueTypes = {
                ...values,
                ...{
                  type: +values.type,
                  rule_interview: +values.rule_interview,
                  rule_resume: +values.rule_resume,
                  rule_vacancy: +values.rule_vacancy,
                },
              };
              if (staffId) {
                updateCompanyStaff(companyId, valueTypes, staffId.user_id);
              } else {
                createCompanyStaff(companyId, valueTypes);
              }
            }}
          >
            {({ errors, touched }) => (
              <StyledModalWrapper>
                <StyledTitle>Добавление нового пользователя</StyledTitle>
                <StyledForm action="#">
                  <div>
                    <StyledLabel htmlFor="name" text="Имя" />
                    <StyledInput
                      field
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Александр"
                    />
                    <StyledError>
                      {errors.name && touched.name && errors.name}
                    </StyledError>
                  </div>
                  <div>
                    <StyledLabel htmlFor="last_name" text="Фамилия" />
                    <StyledInput
                      field
                      disabled={staffId}
                      type="text"
                      id="secondName"
                      name="last_name"
                      placeholder="Филиппов"
                    />
                    <StyledError>
                      {errors.last_name &&
                        touched.last_name &&
                        errors.last_name}
                    </StyledError>
                  </div>
                  <div>
                    <StyledLabel htmlFor="email" text="E-mail" />
                    <StyledInput
                      field
                      disabled={staffId}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="info@mebot24-hr.ru"
                    />
                    <StyledError>
                      {errors.email && touched.email && errors.email}
                    </StyledError>
                  </div>
                  <div>
                    <StyledLabel htmlFor="type" text="Должность" />
                    <SelectDiv>
                      <Field
                        disabled={staffId}
                        id="type"
                        as="select"
                        name="type"
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <option hidden></option>
                        {staticData.ruleList.map((n: StaffRuleList) => (
                          <option value={n.id}>{n.name}</option>
                        ))}
                      </Field>
                    </SelectDiv>
                    <StyledError>
                      {errors.type && touched.type && errors.type}
                    </StyledError>
                  </div>
                  <StyledRow>
                    <div>
                      <StyledLabel htmlFor="rule_vacancy" text="Вакансии" />
                      <SelectDiv>
                        <Field
                          as="select"
                          name="rule_vacancy"
                          id="rule_vacancy"
                        >
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                        <StyledError>
                          {errors.rule_vacancy &&
                            touched.rule_vacancy &&
                            errors.rule_vacancy}
                        </StyledError>
                      </SelectDiv>
                    </div>
                    <div className="new-account__row-select">
                      <StyledLabel
                        htmlFor="rule_interview"
                        text="Собеседования"
                      />
                      <SelectDiv>
                        <Field as="select" name="rule_interview">
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                      </SelectDiv>
                      <StyledError>
                        {errors.rule_interview &&
                          touched.rule_interview &&
                          errors.rule_interview}
                      </StyledError>
                    </div>
                    <div>
                      <StyledLabel htmlFor="rule_resume" text="Анкеты" />
                      <SelectDiv>
                        <Field as="select" name="rule_resume">
                          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                          <option hidden></option>
                          {staticData.ruleAction.map((n: StaffRuleList) => (
                            <option value={n.id}>{n.name}</option>
                          ))}
                        </Field>
                      </SelectDiv>
                      <StyledError>
                        {errors.rule_resume &&
                          touched.rule_resume &&
                          errors.rule_resume}
                      </StyledError>
                    </div>
                  </StyledRow>
                  <StyledBtnWrap>
                    <StyledLink button type="submit" text="Сохранить" />
                  </StyledBtnWrap>
                </StyledForm>
              </StyledModalWrapper>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewCompanyForm;
