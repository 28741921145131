import styled from 'styled-components';

export const ScheduleEvent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const ScheduleEventNumber = styled.span`
  flex-shrink: 0;
  width: 55px;
  color: #4f4f4f;
  align-self: start;

  @media (min-width: 768px) {
    padding-top: 7px;
  }
`;

export const ScheduleEventWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px);

  .text {
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 44%;
      margin-bottom: 0;
    }
  }

  .column {
    @media (min-width: 768px) {
      width: 19%;
    }
  }

  .wrapper {
    width: 56%;
    @media (max-width: 768px) {
      width: 100%;
    }

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 0px;
      margin-right: 43px;
      flex-grow: 1;

      &:last-of-type {
        margin-right: 0;
        justify-content: flex-end;
      }
    }
  }

  .flex-wrapper button:nth-of-type(2) {
    margin-left: 10px;
  }

  .flex-wrapper.column-wrapper {
    flex-direction: column;
    align-items: start;

    @media (min-width: 500px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .flex-wrapper.checked-wrapper {
    @media (min-width: 768px) {
      flex-grow: 0;
    }
  }

  .name {
    width: 70%;
    @media (min-width: 768px) {
      margin-right: 35px;
      width: 180px;
    }
  }

  .flex-wrapper.column-wrapper .name {
    margin-bottom: 5px;

    @media (min-width: 500px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

// Страница создания/редактирования вакансии

export const StyledTitle = styled.h2`
  margin: 0;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 31px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 29px;
  }
`;

export const StyledLabel = styled.label`
  margin: 0;
  display: block;
  margin-bottom: 9px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 992px) {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

export const StyledInputWrap = styled.div`
  position: relative;
  display: block;
  margin-bottom: 34px;
  @media (max-width: 991px) {
    margin-bottom: 23px;
  }
`;

export const StyledInputWrapper = styled.div`
  display: grid;
  grid-column-gap: 17px;
  grid-row-gap: 11px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledSmallCol = styled.div`
  padding-right: 0px;
`;

export const StyledRadioInputFieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 991px) {
    margin-top: 15px;
  }
`;

export const StyledRadioInputWrap = styled.div`
  position: relative;
  width: calc(25% - 22.5px);

  &:nth-of-type(1) label {
    border-color: #eb5757;
  }

  &:nth-of-type(2) label {
    border-color: #2bccab;
  }

  &:nth-of-type(3) label {
    border-color: #4a81d3;
  }

  &:nth-of-type(1) input:checked + label,
  &:nth-of-type(1) label:hover {
    color: #fff;
    background: #eb5757;
  }

  &:nth-of-type(2) input:checked + label,
  &:nth-of-type(2) label:hover {
    color: #fff;
    background: #2bccab;
  }

  &:nth-of-type(3) input:checked + label,
  &:nth-of-type(3) label:hover {
    color: #fff;
    background: #4a81d3;
  }

  &:nth-of-type(4) input:checked + label,
  &:nth-of-type(4) label:hover {
    color: #fff;
    background: #bdbdbd;
  }

  @media (max-width: 991px) {
    width: calc(25% - 11px);
  }
`;

export const StyledRadioInputLabel = styled.label`
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 12px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 991px) {
    padding: 4px 6px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const StyledButtonWrap = styled.div`
  position: relative;
  width: 293px;
  margin: 0 auto;

  & > div {
    position: absolute;
    right: 70px;
    top: 10px;
  }
`;

export const StyledContainer = styled.div`
  margin-top: 25px;
`;

export const StyledErrorMessageWrap = styled.div`
  position: absolute;
  bottom: -15px;
  left: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #ff6363;
`;
