import React, { useState } from 'react';
import AuthorizationForm from './Authorization/AuthorizationForm';
import RegistrationForm from './RegisterConfirmation/RegistrationForm';

const Login = () => {
  const [auth, setAuth] = useState(false);
  return auth ? (
    <RegistrationForm setAuth={setAuth} />
  ) : (
    <AuthorizationForm setAuth={setAuth} />
  );
};
export default Login;
