import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useActions } from '../../../../../hooks/useActions';
import useTypedSelector from '../../../../../hooks/useTypedSelector';
import {
  IBitrixVacancy,
  IConnection,
  IVacancy,
} from '../../../../../types/connectorB24';
import DataStatus from '../../../../Common/DataStatus/DataStatus';
import StyledLink from '../../../../Template/StyledComponents/StyledLink';
import StyledSelect from '../../../../Template/StyledComponents/StyledSelect';
import * as Styled from './StyledIntegrationTable';

interface IntegrationTableProps {}

const IntegrationTable: React.FunctionComponent<IntegrationTableProps> = () => {
  const {
    vacanciesHr,
    vacanciesBitrix,
    vacanciesConnections,
    errors,
    loading,
  } = useTypedSelector(r => r.connectorB24);

  const [connections, setConnections] = useState<IConnection[]>([
    vacanciesConnections,
  ]);

  useEffect(() => {
    setConnections(vacanciesConnections);
  }, [vacanciesConnections]);

  const {
    getVacanciesHr,
    getVacanciesBitrix,
    getVacanciesConnections,
    saveVacanciesConnections,
  } = useActions();

  useEffect(() => {
    getVacanciesHr();
    getVacanciesBitrix();
    getVacanciesConnections();
  }, []);

  const getDefaultValue = (vacancyId: number): string => {
    if (!connections.length) {
      return 'default';
    }

    const isConnection = (connection: IConnection) =>
      connection.hr_vacancy_id === vacancyId;

    const connectionAlreadyExistIndex = connections.findIndex(isConnection);

    if (connectionAlreadyExistIndex !== -1) {
      const val = connections[connectionAlreadyExistIndex].b24_vacancy_id;
      return val !== null ? val.toString() : 'default';
    }
    return 'default';
  };

  const handleSelectChange = (evt: React.FormEvent<HTMLSelectElement>) => {
    if (evt.currentTarget.value === 'default') {
      setConnections([
        ...connections,
        {
          hr_vacancy_id: Number(evt.currentTarget.dataset.value),
          b24_vacancy_id: null,
          b24_vacancy_name: null,
        },
      ]);
    } else {
      const value = {
        hr_vacancy_id: Number(evt.currentTarget.dataset.value),
        b24_vacancy_id: Number(evt.currentTarget.value),
        b24_vacancy_name: vacanciesBitrix.filter(
          (vacancyBitrix: IBitrixVacancy) =>
            vacancyBitrix.id === Number(evt.currentTarget.value),
        )[0].name,
      };

      const isConnection = (connection: IConnection) =>
        connection.hr_vacancy_id === Number(evt.currentTarget.dataset.value);

      const connectionAlreadyExistIndex = connections.findIndex(isConnection);

      if (connectionAlreadyExistIndex !== -1) {
        const before = connections.slice(0, connectionAlreadyExistIndex);
        const after = connections.slice(connectionAlreadyExistIndex + 1);
        const newConnections = [...before, ...after, value];

        setConnections(newConnections);
      } else {
        setConnections([...connections, value]);
      }
    }
  };

  const saveConnections = () => {
    saveVacanciesConnections(connections);
  };

  return (
    <Styled.TableWrapper>
      <h3 className="title">Вакансии в HR-системе</h3>
      <DataStatus
        loading={loading.vacanciesHr}
        error={errors.vacanciesHr}
        items={vacanciesHr}
        noItemsText="Конфигуратор не установлен или нет вакансий"
      >
        <div className="integration-table-wrap">
          <table className="integration-table">
            <thead>
              <tr>
                <th>Статус</th>
                <th>Вакансия</th>
                <th>Соответствует вакансии в Б24</th>
              </tr>
            </thead>
            <tbody>
              {vacanciesHr.length !== 0 &&
                vacanciesHr.map((vacancy: IVacancy) => (
                  <tr
                    key={vacancy.id}
                    className={vacancy.status === 'Y' ? 'active' : ''}
                  >
                    <td>
                      {vacancy.status === 'Y' ? 'Актвивна' : 'Не&nbsp;активна'}
                    </td>
                    <td>{vacancy.name}</td>
                    <td>
                      <label className="visually-hidden" htmlFor="vacancies">
                        Выбрать вакансию
                      </label>
                      <StyledSelect
                        name="connection"
                        id="vacancies"
                        inlineStyles={{
                          padding: '6px 30px 6px 6px',
                          height: 33,
                        }}
                        handleChange={handleSelectChange}
                        dataValue={vacancy.id}
                        defaultValue={getDefaultValue(vacancy.id)}
                      >
                        <option value="default" disabled>
                          Выберите вакансию из списка
                        </option>
                        {vacanciesBitrix.length !== 0 &&
                          vacanciesBitrix.map(
                            (vacancyBitrix: IBitrixVacancy) => (
                              <option
                                value={vacancyBitrix.id}
                                key={vacancyBitrix.id}
                              >
                                {vacancyBitrix.name}
                              </option>
                            ),
                          )}
                      </StyledSelect>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="buttons-wrapper">
          <div className="save-btn-wrap">
            <StyledLink
              button
              text="Сохранить изменения"
              handleСlick={saveConnections}
              disabled={loading.connections}
            />
            {loading.connections && (
              <Loader type="Audio" color="#828282" height="16px" width="16px" />
            )}
          </div>
          {errors.connections && <p className="error">{errors.connections}</p>}
        </div>
      </DataStatus>
    </Styled.TableWrapper>
  );
};

export default IntegrationTable;
