import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { useActions } from '../../../hooks/useActions';
import VacancyCVsTable from './VacancyCVsTable';
import VacancyCVsAdd from './VacancyCVsAdd';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import icon from '../../../assets/image/download-icon.svg';
import closeIcon from '../../../assets/image/icon-close.svg';
import { useFetching } from '../../../hooks/useFetching';

const StyledDiv = styled.div`
  margin-top: 24px;
`;

const StyledContentTop = styled.div`
  margin-bottom: 22px;
`;

const StyledTitle = styled.h2`
  margin: 0;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  font-weight: bold;

  @media (max-width: 991px) {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 29px;
  }
`;

const StyledSearchForm = styled.form`
  position: relative;
  margin-bottom: 0;

  input {
    display: block;
    width: 100%;
    padding: 8px 10px 8px 40px;
    border: 1px solid #cdcede;
    border-radius: 25px;
    color: #000000;
    outline: none;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }
  }

  button {
    position: absolute;
    left: 5px;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  button svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @media (max-width: 991px) {
    margin-bottom: 14px;

    input {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

const StyledBtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px) {
    justify-content: flex-end;
  }

  @media (max-width: 374px) {
    justify-content: space-between;
  }
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 20px;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s ease;
  background: transparent;
  color: #4f4f4f;
  border: 1px solid #cdcede;
  white-space: nowrap;

  svg {
    margin-right: 4px;
  }

  &.last {
    margin-right: 0;
  }

  @media (max-width: 374px) {
    margin-right: 0;
  }
`;

const StyledModalWrap = styled.div`
  position: relative;
`;

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.03);
  left: 0;
  top: 0;
  z-index: 1;
`;

const StyledImportModal = styled.form`
  position: absolute;
  z-index: 12;
  content: '';
  top: 50px;
  right: 0px;
  min-height: 234px;
  width: 264px;
  padding: 19px 24px 31px 24px;
  background-color: #fff;
  box-shadow: 0px 1px 10px rgb(2 1 72 / 16%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    text-align: left;
    color: #4f4f4f;
    margin-bottom: 4px;
  }

  .text {
    margin: 0;
    margin-bottom: 7px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #4f4f4f;
  }

  .download-link {
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2f80ed;
    margin-bottom: 15px;
    display: flex;
    width: 100%;
  }

  .input-wrap {
    position: relative;
    height: 59px;
    border: 1px solid #cdcede;
    border-radius: 5px;
    margin-bottom: 16px;
  }

  input {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  label {
    position: relative;
    margin: 0;
    display: block;
    height: 100%;
    padding: 19px;
    padding-right: 10px;
    padding-left: 62px;
    font-size: 13px;
    text-align: left;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    .error-text,
    .uploaded,
    .isloading-text {
      display: none;
    }

    &.active .upload,
    &.error .upload,
    &.isloading .upload {
      display: none;
    }

    &.active .uploaded,
    &.error .error-text,
    &.isloading .isloading-text {
      display: inline;
    }

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      display: block;
      width: 39px;
      height: 39px;
      border-radius: 50%;
      background-image: url(${icon});
      background-repeat: no-repeat;
    }
  }
`;

const StyledDeleteBtn = styled.button`
  position: absolute;
  dosplay: block;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-size: contain;
  transition: filter 0.3s;

  &:hover,
  &:focus {
    filter: contrast(200%);
  }
`;

interface Params {
  id: string;
}

const VacancyCVs: React.FunctionComponent<Params> = ({ id }) => {
  const FILE_TYPE = 'csv';
  const { fetchListCVS } = useActions();
  const [count, setCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const limit = 10;

  const [fetchListCvsNew, isCvsLoading, cvsError] = useFetching(() =>
    fetchListCVS(count, limit, { vacancy_id: id }),
  );

  useEffect(() => {
    fetchListCvsNew();
  }, [count, id]);

  const handleOpenModal = () => {
    setModal(true);
  };

  const reFetchCVS = () => {
    fetchListCVS(count, limit, { vacancy_id: id });
  };

  const handleImportClick = () => {
    setOpen(!open);
  };

  const dropHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (e.dataTransfer.items[0].kind === 'file') {
      const file = e.dataTransfer.items[0].getAsFile();
      if (file !== null && file.name.endsWith(FILE_TYPE)) {
        console.log(file.name);
        setIsLoading(false);
        setActive(true);
      } else {
        setIsLoading(false);
        setError(true);
      }
    }
  };

  const dragOverHandler = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.endsWith(FILE_TYPE)) {
      setActive(true);
      console.log(e.target.value);
    } else {
      setError(true);
    }
  };

  let labelClassName;
  if (active) {
    labelClassName = 'active';
  } else if (error) {
    labelClassName = 'error';
  } else if (isLoading) {
    labelClassName = 'isloading';
  } else {
    labelClassName = '';
  }

  return (
    <>
      <Modal
        size="xl"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        className="modal fade addResume"
      >
        <Modal.Body className="big">
          <VacancyCVsAdd
            vacancyId={id}
            setModal={setModal}
            reFetchCVS={reFetchCVS}
          />
          <StyledDeleteBtn
            type="button"
            onClick={() => {
              setModal(false);
            }}
            aria-label="Удалить."
          ></StyledDeleteBtn>
        </Modal.Body>
      </Modal>
      <StyledDiv>
        <StyledContentTop className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <StyledTitle>Резюме</StyledTitle>
            </div>
            <div className="col-lg-5">
              <StyledSearchForm action="#">
                <input
                  type="text"
                  name="search"
                  placeholder="Иванов Иван Петрович"
                />
                <button type="submit">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4586 10.0193L7.84054 7.29639C8.51367 6.49619 8.88249 5.48938 8.88249 4.44125C8.88249 1.99238 6.89011 0 4.44125 0C1.99238 0 0 1.99238 0 4.44125C0 6.89011 1.99238 8.88249 4.44125 8.88249C5.36058 8.88249 6.23667 8.6052 6.98569 8.07882L9.6236 10.8224C9.73386 10.9369 9.88216 11 10.0411 11C10.1915 11 10.3342 10.9427 10.4425 10.8384C10.6727 10.6169 10.68 10.2496 10.4586 10.0193ZM4.44125 1.15859C6.25134 1.15859 7.72391 2.63115 7.72391 4.44125C7.72391 6.25134 6.25134 7.72391 4.44125 7.72391C2.63115 7.72391 1.15859 6.25134 1.15859 4.44125C1.15859 2.63115 2.63115 1.15859 4.44125 1.15859Z"
                      fill="#828282"
                    />
                  </svg>
                </button>
              </StyledSearchForm>
            </div>
            <div className="col-lg-5 text-lg-right">
              <StyledBtnsWrapper>
                <StyledButton type="button" onClick={handleOpenModal}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.80117 3.83526C9.66855 3.70267 9.50748 3.6364 9.31815 3.6364H6.36357V0.681751C6.36357 0.49242 6.29725 0.331398 6.16471 0.198809C6.03215 0.0663194 5.87125 0 5.68164 0H4.31813C4.12878 0 3.96778 0.0662448 3.83519 0.198834C3.7026 0.331423 3.63633 0.492445 3.63633 0.681776V3.63642H0.681778C0.492446 3.63642 0.331424 3.70269 0.198834 3.83528C0.0662449 3.96787 0 4.12874 0 4.3182V5.68188C0 5.87138 0.0662201 6.03228 0.198809 6.16474C0.331399 6.29738 0.492422 6.3636 0.681753 6.3636H3.63631V9.3183C3.63631 9.50763 3.70258 9.66878 3.83517 9.80132C3.96776 9.93378 4.12875 10 4.31811 10H5.68172C5.8712 10 6.03212 9.93378 6.16473 9.80132C6.29737 9.6687 6.36359 9.50763 6.36359 9.3183V6.3636H9.31812C9.50763 6.3636 9.6686 6.29738 9.80114 6.16474C9.9337 6.03228 10 5.87138 10 5.68188V4.3182C9.99995 4.12874 9.9338 3.96777 9.80117 3.83526Z"
                      fill="#CDCEDE"
                    />
                  </svg>
                  Добавить кандидата
                </StyledButton>
                <StyledModalWrap>
                  <StyledButton
                    className="last"
                    type="button"
                    onClick={handleImportClick}
                  >
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.98167 6.0979C9.94449 6.01223 9.85774 5.95633 9.76146 5.95633H7.14987V0.229097C7.14987 0.102639 7.04309 0 6.91153 0H3.09827C2.96671 0 2.85994 0.102639 2.85994 0.229097V5.95635H0.238333C0.142056 5.95635 0.0552975 6.01225 0.0181183 6.09747C-0.0185917 6.18315 0.00142778 6.28166 0.0695971 6.34719L4.82424 10.9326C4.86904 10.9757 4.92959 11 4.99298 11C5.05637 11 5.11692 10.9757 5.16172 10.9331L9.93019 6.34764C9.99836 6.28209 10.0189 6.1836 9.98167 6.0979Z"
                        fill="#CDCEDE"
                      />
                    </svg>
                    Импорт списка
                  </StyledButton>
                  {open && (
                    <>
                      <StyledImportModal encType="multipart/form-data">
                        <h3>Импорт списка</h3>
                        <p className="text">Загружать файл в формате (csv)</p>
                        <a className="download-link" href="/">
                          Скачать пример файла
                        </a>
                        <div className="input-wrap">
                          <input
                            id="id-file"
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              changeHandler(e);
                            }}
                          />
                          <label
                            htmlFor="id-file"
                            onDrop={(e: React.DragEvent) => {
                              dropHandler(e);
                            }}
                            onDragOver={(e: React.DragEvent) => {
                              dragOverHandler(e);
                            }}
                            className={labelClassName}
                          >
                            <span className="upload">Перенести файл</span>
                            <span className="uploaded">
                              Файл загружен{' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                              </svg>
                            </span>
                            <span className="isloading-text">Загрузка...</span>
                            <span className="error-text">
                              Ошибка при загрузке
                            </span>
                          </label>
                        </div>
                        <StyledLink text="Загрузить" path="/" />
                      </StyledImportModal>
                      <StyledOverlay
                        onClick={() => {
                          setOpen(false);
                        }}
                      />
                    </>
                  )}
                </StyledModalWrap>
              </StyledBtnsWrapper>
            </div>
          </div>
        </StyledContentTop>
        <VacancyCVsTable
          page={count}
          setPage={setCount}
          isCvsLoading={isCvsLoading}
          cvsError={cvsError}
        />
      </StyledDiv>
    </>
  );
};
export default VacancyCVs;
