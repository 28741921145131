import React, { useEffect, useState } from 'react';
import VacanciesList from './VacanciesList';
import { useActions } from '../../hooks/useActions';
import { useFetching } from '../../hooks/useFetching';

const Vacancies: React.FunctionComponent = () => {
  const { fetchListVacancies } = useActions();
  const [page, setPage] = useState(0);
  const [archPage, setArchPage] = useState(0);

  const [
    fetchListVacanciesActual,
    isActualVacanciesLoading,
    vacanciesActualError,
  ] = useFetching(() => {
    fetchListVacancies(page, 4, false);
  });

  const [
    fetchListVacanciesArch,
    isArchVacanciesLoading,
    vacanciesArchError,
  ] = useFetching(() => {
    fetchListVacancies(archPage, 4, true);
  });

  useEffect(() => {
    fetchListVacanciesActual();
  }, [page]);

  useEffect(() => {
    fetchListVacanciesArch();
  }, [archPage]);

  const refetchAll = () => {
    const rfa = async () => {
      await fetchListVacanciesActual();
      await fetchListVacanciesArch();
    };

    rfa();
  };

  return (
    <VacanciesList
      refetchAll={refetchAll}
      page={page}
      setPage={setPage}
      archPage={archPage}
      setArchPage={setArchPage}
      loading={{
        actual: isActualVacanciesLoading,
        archive: isArchVacanciesLoading,
      }}
      error={{
        actual: vacanciesActualError,
        archive: vacanciesArchError,
      }}
    />
  );
};

export default Vacancies;
