import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledSearch from '../../Template/StyledComponents/StyledSearch';
import ViewCompanyCard from './ViewCompanyCard/ViewCompanyCard';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { CompanyStaff } from '../../../types/companies';
import { useActions } from '../../../hooks/useActions';
import ViewCompanyForm from './ViewCompanyForm/ViewCompanyForm';
import FormHeader from '../FormHeader/FormHeader';
import Pagination from '../../Common/pagination';

const StyledCompanyHeader = styled.section`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 24px;
  padding-top: 24px;
  padding-bottom: 13px;

  button {
    display: none;
  }

  @media (min-width: 768px) {
    padding-top: 18px;
    padding-bottom: 25px;
    grid-template-columns: 34% 60%;
    grid-column-gap: 20px;
    align-items: center;

    button {
      display: block;
    }

    .wrapper {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 22px;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;

    .wrapper {
      grid-template-columns: 221px 1fr;
    }
  }
`;

const StyledCompanyMount = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 21px;
  order: 1;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 21px;
    order: 0;
  }
`;

const StyledTable = styled.div`
  @media (min-width: 992px) {
    border-radius: 11px;
    border: 1px solid #cdcede;
    padding-bottom: 4px;
    margin-bottom: 48px;
  }

  .table {
    width: 100%;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;

    @media (max-width: 991px) {
      display: grid;
      grid-auto-rows: min-content;
      grid-row-gap: 15px;
    }
  }

  .table-row {
    display: grid;
    grid-template-areas:
      'name vacancy btn'
      '. ankets btn'
      'type interview btn';
    grid-template-columns: 40% 50% 5%;
    grid-column-gap: 10px;

    @media (min-width: 992px) {
      grid-template-areas: none;
      grid-column-gap: 0;
      grid-template-columns: 30% 15% 15% 15% 15% 10%;
    }

    @media (max-width: 576px) {
      padding: 10px;
    }
  }

  .table-row-head {
    color: #828282;
    background-color: #ededf3;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .cell {
    margin: 0;
    padding: 10px 20px;
    font-weight: 400;
    border: none;

    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .cell p {
    margin: 0;
  }

  .cell-name {
    @media (max-width: 991px) {
      grid-area: name;
    }
  }

  .cell-type {
    @media (max-width: 991px) {
      grid-area: type;
    }
  }

  .cell-vacancy {
    @media (max-width: 991px) {
      grid-area: vacancy;
    }
  }

  .cell-ankets {
    @media (max-width: 991px) {
      grid-area: ankets;
    }
  }

  .cell-interview {
    @media (max-width: 991px) {
      grid-area: interview;
    }
  }

  .cell-last {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 991px) {
      display: grid;
      grid-auto-rows: min-content;
      grid-row-gap: 25px;
      grid-area: btn;
    }
  }
`;

interface Params {
  id: string;
}

const ViewCompany: React.FunctionComponent = () => {
  const { staffId, staff, staffChange } = useTypedSelector(r => r.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const params: Params = useParams();
  const {
    fetchCompanyStaff,
    resetStaff,
    fetchStaffId,
    deleteCompanyStaff,
  } = useActions();
  const [modal, setModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  useEffect(() => {
    fetchCompanyStaff(params.id);
    setModal(false);
  }, [staffChange]);

  const handleEditStaff = (value: string) => {
    fetchStaffId(value);
    setModal(true);
    setEdit(true);
  };
  const handleDeleteStaff = (value: string) => {
    deleteCompanyStaff(value, params.id);
  };
  const addStaffFunc = () => {
    setModal(true);
    resetStaff();
  };

  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany />
      <div className="container">
        <StyledCompanyHeader>
          <StyledCompanyMount>
            Всего: <span>{staff.length}</span>&nbsp; сотрудников
          </StyledCompanyMount>
          <div className="wrapper">
            <StyledLink
              handleСlick={() => addStaffFunc()}
              type="button"
              text="Добавить сотрудника"
              button
              inlineStyles={{
                padding: '9px 10px',
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="6.95674" cy="7" rx="6.95674" ry="7" fill="black" />
                <path
                  d="M6.95639 5V9M8.94403 7L4.96875 7"
                  stroke="#FEDC2D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </StyledLink>
            <StyledSearch placeholder="Имя кандитата ..." />
          </div>
        </StyledCompanyHeader>
        <StyledTable>
          <div className="table">
            <div className="table-row table-row-head">
              <p className="cell">ФИО</p>
              <p className="cell">Роль</p>
              <p className="cell">Вакансии</p>
              <p className="cell">Собеседования</p>
              <p className="cell">Анкеты</p>
            </div>
            {dictionaries.ruleAction &&
              staff.map((n: CompanyStaff) => (
                <ViewCompanyCard
                  staticData={dictionaries}
                  handleDeleteStaff={handleDeleteStaff}
                  handleEditStaff={handleEditStaff}
                  staff={n}
                  key={n.user_id}
                />
              ))}
          </div>
        </StyledTable>
      </div>
      {/* {staff.length > 10 && (  
        <Pagination/> компонент, передать props row
      )} */}
      <ViewCompanyForm
        staticData={dictionaries}
        companyId={params.id}
        staffId={staffId}
        edit={edit}
        setModal={setModal}
        modal={modal}
      />
    </>
  );
};

export default ViewCompany;
