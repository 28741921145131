import styled from 'styled-components';

export const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: #000000;

  @media (max-width: 991px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

export const StyledCloseBtn = styled.button`
  position: absolute;
  top: 30px;
  right: 31px;
  width: 30px;
  height: 30px;
  padding: 0px;
  content: '';
  background-color: transparent;

  &:hover svg,
  &:focus svg {
    opacity: 0.6;
  }

  @media (max-width: 991px) {
    top: 10px;
    right: 11px;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #828282;
`;

export const StyledSubmitBtnWrap = styled.div`
  max-width: 492px;
  margin: 0 auto;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    button {
      height: 50px;
    }
  }
`;

export const StyledLoginText = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: normal;
  color: #828282;
  text-align: center;

  a {
    font-weight: 500;
    text-decoration: underline;
    color: #828282;
    margin-left: 2px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const StyledItemWrap = styled.div`
  margin-bottom: 24px;

  @media (min-width: 991px) {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    & input {
      height: 42px;
    }

    & label {
      margin-bottom: 7px;
      font-size: 13px;
    }
  }
`;

export const StyledFormSection = styled.section`
  max-width: 750px;
  width: 100%;
  margin: 0 auto 120px;
  padding: 60px 120px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
  background: #ffffff;
  position: relative;
  z-index: 2;

  @media (max-width: 991px) {
    padding: 38px 24px 32px 24px;
  }
`;

export const StyledAuthFormSection = styled.section`
  max-width: 360px;
  width: 100%;
  margin: 0 auto 120px;
  padding: 50px 16px 57px 16px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
  background: #ffffff;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    padding-bottom: 57px;
  }
`;

export const StyledAuthForm = styled.form`
  margin-top: 33px;
  max-width: 264px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const StyledAuthFormDiv = styled.div`
  margin-top: 33px;
  max-width: 264px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
