import produce from 'immer';
import { ConnectorActionTypes } from '../../types/connectorB24';

const connectorB24Reducer = produce(
  (draft, action) => {
    switch (action.type) {
      case ConnectorActionTypes.SET_WEBHOOK: {
        draft.webhook = action.payload;
        return draft;
      }
      case ConnectorActionTypes.RESET_CONFIGURATION: {
        draft.configuration = action.payload;
        draft.directions = [];
        draft.statuses = [];
        draft.vacancyFields = [];
        return draft;
      }
      case ConnectorActionTypes.SET_CONFIGURATION: {
        draft.configuration = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_CONFIGURATION: {
        draft.configuration = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_DIRECTIONS: {
        draft.directions = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_STATUSES: {
        draft.statuses = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_VACANCY_FIELDS: {
        draft.vacancyFields = action.payload;
        return draft;
      }
      case ConnectorActionTypes.SET_ERROR: {
        draft.errors[action.payload.name] = action.payload.value;
        return draft;
      }
      case ConnectorActionTypes.SET_IS_LOADING: {
        draft.loading[action.payload.name] = action.payload.value;
        return draft;
      }
      case ConnectorActionTypes.GET_VACANCIES_HR: {
        draft.vacanciesHr = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_VACANCIES_BITRIX: {
        draft.vacanciesBitrix = action.payload;
        return draft;
      }
      case ConnectorActionTypes.GET_VACANCIES_CONNECTIONS: {
        draft.vacanciesConnections = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    webhook: '',
    configuration: null,
    directions: [],
    statuses: [],
    vacancyFields: [],
    vacanciesHr: [],
    vacanciesBitrix: [],
    vacanciesConnections: [],
    errors: {
      webhook: '',
      configuration: '',
      directions: '',
      statuses: '',
      vacancyFields: '',
      vacanciesHr: '',
      vacanciesBitrix: '',
      connections: '',
    },
    loading: {
      webhook: false,
      configuration: false,
      directions: false,
      statuses: false,
      vacancyFields: false,
      vacanciesHr: false,
      vacanciesBitrix: false,
      connections: false,
    },
  },
);
export default connectorB24Reducer;
