import styled from 'styled-components';

export const SelectWrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-auto-rows: min-content;
  gap: 10px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    gap: 20px;
    grid-template-columns: 400px 1fr;
    align-items: end;
  }

  @media (min-width: 992px) {
    gap: 24px;
    grid-template-columns: 550px 1fr;
  }

  .label {
    display: block;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  .text {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #828282;

    @media (min-width: 768px) {
      max-width: 400px;
      padding: 10px 0;
    }
  }

  .error {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    color: #ff6363;
  }
`;
