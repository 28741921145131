import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'react-h5-audio-player/src/styles.scss';
import AudioPlayer from 'react-h5-audio-player';
import {
  ICVRes,
  SeekerSummaryReport,
  SeekerSummaryReportAvQuestions,
  SeekerSummaryReportAvTesting,
  SeekerSummaryReportTesting,
} from '../../../types/vacancies';
import VacancyTest from '../VacancyTest';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledProgress from '../../Template/StyledComponents/StyledProgress';
import {
  StyledCard,
  StyledButton,
  StyledNameWrap,
  StyledAttr,
  StyledName,
  StyledStats,
  StyledContent,
  StyledContentCard,
  StyledTests,
  StyledTestsTitle,
  StyledTestsWrapper,
  StyledTestsSlider,
  StyledText,
  StyledTextLink,
  StyledInterviewTitleWrap,
  StyledInterviewStats,
  StyledProgressItem,
  StyledProgresswrapper,
  StyledWrapper,
  StyledVideo,
  StyledTaskWrapper,
  StyledBlock,
  StyledGridWrap,
  StyledAudio,
  StyledLoaderWrap,
  StyledError,
} from './VacancyQuestionnaireItemComponents';
import { getFirstLetters } from '../../../helpers/getFirstLetters';
import { useActions } from '../../../hooks/useActions';
import { useFetching } from '../../../hooks/useFetching';
import Loader from '../../Template/Loader/Loader';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { getAge } from '../../../helpers/getAge';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';

interface QuestionnaireItem {
  item: ICVRes;
  key: number | string;
  opened: number;
  handleOpen: (id: number) => void;
}

const VacancyQuestionnaireItem: React.FC<QuestionnaireItem> = ({
  item,
  key,
  opened,
  handleOpen,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const { interviewReport } = useTypedSelector(r => r.mainData.dictionaries);
  const { fetchSummaryReport } = useActions();
  const [
    fetchSummaryReportNew,
    isReportLoading,
    reportError,
  ] = useFetching(() => fetchSummaryReport(item.id));
  const report = useTypedSelector(r => r.vacancies.report);

  const handleItemClick = () => {
    handleOpen(Number(item.id));
    fetchSummaryReportNew();
  };

  // const status = true;
  // const checkedTests = true;

  const age = getAge(Number(item.year_of_birthday.slice(-4)));

  const personAge = `${age} ${wordDeclination(age, wordsDict.YEARS_FORMS)}`;

  const summaryData = [
    {
      name: interviewReport[0].title,
      val: report.interview.mark_1,
      color: '#FEDC2D',
    },
    {
      name: interviewReport[1].title,
      val: report.interview.mark_2,
      color: '#27AE60',
    },
    {
      name: interviewReport[2].title,
      val: report.interview.mark_3,
      color: '#FF6363',
    },
    {
      name: interviewReport[3].title,
      val: report.interview.mark_4,
      color: '#FEDC2D',
    },
  ];

  return (
    <>
      <StyledCard key={key}>
        <StyledButton type="button" onClick={handleItemClick}>
          <StyledNameWrap>
            <StyledAttr>
              {getFirstLetters(item.first_name, item.last_name)}
            </StyledAttr>
            <StyledName>{`${item.first_name} ${item.last_name}`}</StyledName>
          </StyledNameWrap>
          {/* <StyledStats>
            <div className="wrap">
              <dt>Статус:</dt>
              <dd className={status ? 'status accepted' : 'status rejected'}>
                {status ? 'принят' : 'отклонено'}
              </dd>
            </div>
            <div className="wrap">
              <dt>Пройденные тесты:</dt>
              <dd className={checkedTests ? 'checked' : ''}>
                {checkedTests ? (
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.31372"
                      cy="4.01275"
                      r="3.92602"
                      fill="white"
                    />
                    <path
                      d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
                      stroke="#27AE60"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
                    <path
                      d="M3.93707 1.77771L4.02632 5.37717H4.96336L5.05261 1.77771H3.93707ZM4.49484 7.23639C4.83322 7.23639 5.10095 6.97982 5.10095 6.6526C5.10095 6.32537 4.83322 6.0688 4.49484 6.0688C4.15646 6.0688 3.88873 6.32537 3.88873 6.6526C3.88873 6.97982 4.15646 7.23639 4.49484 7.23639Z"
                      fill="#FF6363"
                    />
                  </svg>
                )}
                {checkedTests ? 'Проверено' : 'Нет проверки'}
              </dd>
            </div>
          </StyledStats> */}

          <svg
            className="arrow"
            style={{
              transform: opened ? 'rotate(180deg)' : '',
            }}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="13"
              cy="13"
              r="12.48"
              transform="rotate(-90 13 13)"
              stroke="#BDBDBD"
              strokeWidth="1.04"
            />
            <path
              d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
              stroke="#828282"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledButton>
        <StyledContent
          className={`${opened === Number(item.id) ? 'show' : ''}`}
        >
          {!isReportLoading && !reportError && (
            <>
              <StyledContentCard>
                <div className="name-wrapper">
                  <div className="name-attr">
                    {getFirstLetters(item.first_name, item.last_name)}
                  </div>
                  <div className="name">{`${item.first_name} ${item.last_name}`}</div>
                </div>
                <div className="text-block">
                  <p className="text">
                    Возраст:&nbsp;
                    <span>{personAge}</span>
                  </p>
                  <p className="text">
                    E-mail:&nbsp;
                    <a className="link" href="mailto:info@rambler.ru">
                      {item.email}
                    </a>
                  </p>
                  <p className="text">
                    Тел:&nbsp;
                    <a className="link" href="tel:+79998889988">
                      {item.phone}
                    </a>
                  </p>
                  <StyledLink
                    path="/"
                    text="Резюме"
                    inlineStyles={{ padding: '10px 10px' }}
                  ></StyledLink>
                </div>
              </StyledContentCard>
              <StyledTests>
                {report.testing && (
                  <>
                    <StyledTestsTitle>Пройденные тесты</StyledTestsTitle>
                    <StyledTestsWrapper>
                      <StyledTestsSlider>
                        {report.testing.map(
                          (test: SeekerSummaryReportTesting) => {
                            return (
                              <VacancyTest
                                key={test.id}
                                checked={test.test_approved === 'Y'}
                                points={test.result}
                                maxPoints={test.max_result}
                                name={test.course_name}
                              />
                            );
                          },
                        )}
                      </StyledTestsSlider>
                    </StyledTestsWrapper>{' '}
                  </>
                )}
                {report.trialwork &&
                  Object.keys(report.trialwork).length !== 0 && (
                    <StyledBlock>
                      <StyledTestsTitle>Пройденное задание</StyledTestsTitle>
                      <StyledTaskWrapper>
                        <StyledText>
                          <b>Название задачи:</b> {report.trialwork.name}
                        </StyledText>
                        <StyledText>
                          <b>Оценка сложности:</b> {report.trialwork.complexity}
                        </StyledText>
                        <StyledText>
                          <b>Затраченное время:</b>{' '}
                          {report.trialwork.time_performance} ч.
                        </StyledText>
                        <StyledText className="d-flex flex-wrap">
                          <b className="pr-1">Набор ссылок: </b>
                          {JSON.parse(report.trialwork.results).map(
                            (result: string, i: number) => {
                              return (
                                <a
                                  className="show-link"
                                  href={`${result}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  key={result}
                                >
                                  Ссылка &#8470; {i + 1}
                                </a>
                              );
                            },
                          )}
                        </StyledText>
                      </StyledTaskWrapper>
                    </StyledBlock>
                  )}
                {report.avtesting &&
                  report.avtesting.map(
                    (avtest: SeekerSummaryReportAvTesting) => {
                      return (
                        <StyledBlock>
                          <StyledTestsTitle>
                            Видео/Аудио тесты: <span>{avtest.title}</span>
                          </StyledTestsTitle>
                          <StyledGridWrap>
                            {avtest.questions.map(
                              (
                                question: SeekerSummaryReportAvQuestions,
                                i: number,
                              ) => {
                                let output;
                                if (question.answer.endsWith('mp3')) {
                                  output = (
                                    <>
                                      <StyledText>
                                        <b>Вопрос {i + 1}:</b> {question.name}
                                      </StyledText>
                                      <StyledAudio>
                                        <AudioPlayer
                                          src={`${
                                            process.env.REACT_APP_API_URL
                                          }${question.answer.slice(
                                            1,
                                            question.answer.length,
                                          )}`}
                                          layout="horizontal"
                                          showJumpControls={false}
                                          customVolumeControls={[]}
                                          customAdditionalControls={[]}
                                          autoPlayAfterSrcChange={false}
                                          style={{
                                            width: '350px',
                                            borderRadius: '5px',
                                          }}
                                        ></AudioPlayer>
                                      </StyledAudio>
                                    </>
                                  );
                                } else if (question.answer.endsWith('mp4')) {
                                  output = (
                                    <>
                                      <StyledText>
                                        <b>Вопрос {i + 1}:</b> {question.name}
                                      </StyledText>
                                      <StyledTextLink
                                        className="no-margin"
                                        onClick={() => {
                                          setModal(true);
                                        }}
                                      >
                                        Смотреть
                                      </StyledTextLink>
                                      <Modal
                                        show={modal}
                                        onHide={() => setModal(false)}
                                      >
                                        <Modal.Body>
                                          <h4 className="text-center mb-3 pl-3 pr-3">
                                            Сколько необходимо времени для
                                            реализации годового плана?
                                          </h4>
                                          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                          <StyledVideo
                                            src={`${
                                              process.env.REACT_APP_API_URL
                                            }${question.answer.slice(
                                              1,
                                              question.answer.length,
                                            )}`}
                                            controls
                                            width="320"
                                            height="250"
                                          ></StyledVideo>
                                        </Modal.Body>
                                      </Modal>
                                    </>
                                  );
                                }

                                return output;
                              },
                            )}
                          </StyledGridWrap>
                        </StyledBlock>
                      );
                    },
                  )}

                {report.interview && (
                  <>
                    {report.interview.text && (
                      <StyledBlock>
                        <StyledTestsTitle>
                          Комментарий руководителя:
                        </StyledTestsTitle>
                        <StyledText>{report.interview.text}</StyledText>
                      </StyledBlock>
                    )}
                    {Object.keys(report.interview).length !== 0 && (
                      <StyledBlock>
                        <StyledInterviewTitleWrap>
                          <StyledTestsTitle>
                            Пройденное собеседование
                          </StyledTestsTitle>
                          <StyledTextLink className="edit">
                            Редактировать
                          </StyledTextLink>
                        </StyledInterviewTitleWrap>
                        <StyledWrapper>
                          <StyledInterviewStats>
                            <div className="wrap">
                              <dt>Дата:</dt>
                              <dd>{report.interview.date.slice(0, 10)}</dd>
                            </div>
                            <div className="wrap">
                              <dt>Время:</dt>
                              <dd>{report.interview.date.slice(11, -3)}</dd>
                            </div>
                            {/* <div className="wrap">
                          <dt>Статус:</dt>
                          <dd
                            className={
                              status ? 'status accepted' : 'status rejected'
                            }
                          >
                            {status ? 'принят' : 'отклонено'}
                          </dd>
                        </div> */}
                          </StyledInterviewStats>
                          <StyledProgresswrapper>
                            {summaryData.map(data => {
                              return (
                                <StyledProgressItem>
                                  <StyledProgress
                                    color={data.color}
                                    value={data.val}
                                  />
                                  <span>{data.name}</span>
                                </StyledProgressItem>
                              );
                            })}
                          </StyledProgresswrapper>
                        </StyledWrapper>
                      </StyledBlock>
                    )}
                  </>
                )}
              </StyledTests>{' '}
            </>
          )}
          {isReportLoading && (
            <StyledLoaderWrap>
              <Loader />
            </StyledLoaderWrap>
          )}

          {reportError && (
            <StyledError>
              <p>Что-то пошло не так =(</p>
              <button type="button" onClick={handleItemClick}>
                Попробовать еще раз
              </button>
            </StyledError>
          )}
        </StyledContent>
      </StyledCard>
    </>
  );
};
export default VacancyQuestionnaireItem;
