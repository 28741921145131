import styled from 'styled-components';

export const Section = styled.section`
  .wrapper {
    padding-top: 20px;

    @media (min-width: 768px) {
      padding-top: 38px;
    }
  }
`;

export const BtnWrap = styled.div`
  width: 180px;
  @media (min-width: 576px) {
    margin-left: auto;
  }
`;
