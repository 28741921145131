import { Dispatch } from 'redux';
import axios from 'axios';
import { config, url } from './actionsAssets';
import {
  ConnectorActions,
  ConnectorActionTypes,
  IConfiguration,
  IConnection,
} from '../../types/connectorB24';

export const setCompanyWebhook = (
  options: IConfiguration,
  setSaved: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'webhook', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'webhook', value: true },
      });
      const response = await axios.post(
        `${url}connector/bitrix/config/`,
        options,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.SET_CONFIGURATION,
        payload: response.data,
      });
      setSaved(true);
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'webhook',
          value: 'Произошла ошибка при сохранении конфигурации вебхука',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'webhook', value: false },
      });
    }
  };
};

export const getCompanyConfiguration = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      const response = await axios.get(
        `${url}connector/bitrix/config/`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_CONFIGURATION,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetCompanyConfiguration = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    dispatch({
      type: ConnectorActionTypes.RESET_CONFIGURATION,
      payload: null,
    });
  };
};

export const setCompanyConfiguration = (options: IConfiguration) => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'configuration', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'configuration', value: true },
      });
      const response = await axios.post(
        `${url}connector/bitrix/config/`,
        options,
        config,
      );
      // dispatch({
      //   type: ConnectorActionTypes.SET_CONFIGURATION,
      //   payload: response.data,
      // });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'configuration',
          value: 'Произошла ошибка при сохранении данных',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'configuration', value: false },
      });
    }
  };
};

export const getDirections = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'directions', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'directions', value: true },
      });
      const response = await axios.get(
        `${url}connector/bitrix/category_list/`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_DIRECTIONS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'directions',
          value: 'Произошла ошибка при загрузке направлений',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'directions', value: false },
      });
    }
  };
};

export const getStatuses = (directionId: string) => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'statuses', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'statuses', value: true },
      });
      const response = await axios.get(
        `${url}connector/bitrix/stage_list/?funnel_id=${directionId}`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_STATUSES,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'statuses',
          value: 'Произошла ошибка при загрузке статусов',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'statuses', value: false },
      });
    }
  };
};

export const getVacancyFields = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'vacancyFields', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacancyFields', value: true },
      });
      const response = await axios.get(
        `${url}connector/bitrix/prop_list/`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_VACANCY_FIELDS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'vacancyFields',
          value: 'Произошла ошибка при загрузке полей вакансии',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacancyFields', value: false },
      });
    }
  };
};

export const getVacanciesHr = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'vacanciesHr', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacanciesHr', value: true },
      });
      const response = await axios.get(`${url}vacancy/list/`, config);
      dispatch({
        type: ConnectorActionTypes.GET_VACANCIES_HR,
        payload: response.data.result,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'vacanciesHr',
          value: 'Произошла ошибка при загрузке списка вакансии',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacanciesHr', value: false },
      });
    }
  };
};

export const getVacanciesBitrix = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'vacanciesBitrix', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacanciesBitrix', value: true },
      });
      const response = await axios.get(
        `${url}connector/bitrix/vacancy_enum/`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_VACANCIES_BITRIX,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'vacanciesBitrix',
          value: 'Произошла ошибка при загрузке списка вакансий',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'vacanciesBitrix', value: false },
      });
    }
  };
};

export const getVacanciesConnections = () => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      const response = await axios.get(
        `${url}connector/bitrix/vacancy_list/`,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.GET_VACANCIES_CONNECTIONS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveVacanciesConnections = (options: IConnection[]) => {
  return async (dispatch: Dispatch<ConnectorActions>) => {
    try {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: { name: 'connections', value: '' },
      });
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'connections', value: true },
      });
      const response = await axios.post(
        `${url}connector/bitrix/vacancy_list/`,
        options,
        config,
      );
      dispatch({
        type: ConnectorActionTypes.SAVE_VACANCIES_CONNECTIONS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConnectorActionTypes.SET_ERROR,
        payload: {
          name: 'connections',
          value: 'Произошла ошибка при загрузке списка вакансий',
        },
      });
    } finally {
      dispatch({
        type: ConnectorActionTypes.SET_IS_LOADING,
        payload: { name: 'connections', value: false },
      });
    }
  };
};
