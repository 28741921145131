import React from 'react';
import styled from 'styled-components';
import StyledLink from '../Template/StyledComponents/StyledLink';
import StyledSelect from '../Template/StyledComponents/StyledSelect';

const StyledTestItem = styled.div`
  box-shadow: 0px 2px 5px rgb(2 0 107 / 10%);
  border-radius: 11px;
  width: 147px;
  flex-shrink: 0;
  text-align: center;
  margin-right: 16px;
  min-height: 113px;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    width: 168px;
  }
`;

const StyledTestCheck = styled.p<{
  checkedTest: boolean;
}>`
  margin: 0;
  min-height: 34px;
  background-color: ${props => (props.checkedTest ? '#27ae60' : '#FF6363;')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px 11px 0px 0px;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #ffffff;
  padding: 8px 8px;
  width: 100%;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }

  .checked-svg {
    display: ${props => (props.checkedTest ? 'block' : 'none')};
  }

  .unchecked-svg {
    display: ${props => (props.checkedTest ? 'none' : 'block')};
  }

  .checked-text {
    display: ${props => (props.checkedTest ? 'inline' : 'none')};
  }

  .unchecked-text {
    display: ${props => (props.checkedTest ? 'none' : 'inline')};
  }
`;

const StyledTestWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 12px;

  @media (min-width: 1200px) {
    padding: 20px 17px;
  }
`;

const StyledPoints = styled.p`
  margin: 0;
  margin-bottom: 4px;
  color: #000000;
  font-size: 13px;
  line-height: 20px;

  span {
    font-size: 25px;
    line-height: 29px;
    font-weight: bold;
  }
`;

const StyledTestName = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const StyledLinkWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

interface PropsInterface {
  checked?: boolean;
  points?: number;
  maxPoints?: number;
  name?: string;
  reportTest?: boolean;
}

const StyledTest: React.FC<PropsInterface> = ({
  checked,
  points,
  maxPoints,
  name,
  reportTest,
}) => {
  return (
    <StyledTestItem>
      <StyledTestCheck checkedTest={!!checked}>
        <svg
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="checked-svg"
        >
          <circle cx="4.31372" cy="4.01275" r="3.92602" fill="white" />
          <path
            d="M3.00488 4.11387L4.05948 5.32142L5.62223 2.70407"
            stroke="#27AE60"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="unchecked-svg"
        >
          <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
          <path
            d="M3.93707 1.77771L4.02632 5.37717H4.96336L5.05261 1.77771H3.93707ZM4.49484 7.23639C4.83322 7.23639 5.10095 6.97982 5.10095 6.6526C5.10095 6.32537 4.83322 6.0688 4.49484 6.0688C4.15646 6.0688 3.88873 6.32537 3.88873 6.6526C3.88873 6.97982 4.15646 7.23639 4.49484 7.23639Z"
            fill="#FF6363"
          />
        </svg>
        <span className="checked-text">Проверено</span>
        <span className="unchecked-text">Нет проверки</span>
      </StyledTestCheck>
      <StyledTestWrap>
        <StyledPoints>
          <span>{points}</span>/{maxPoints}
        </StyledPoints>
        <StyledTestName>{name}</StyledTestName>
        {!reportTest && (
          <StyledLinkWrapper>
            {checked ? (
              <StyledSelect
                name="actions"
                inlineStyles={{
                  padding: '6px 30px 10px 8px',
                  height: 35,
                }}
              >
                <option value="actions" disabled selected>
                  Действия
                </option>
                <option value="invite">Пригласить на должность</option>
                <option value="test">Тестировать</option>
                <option value="interview">Собеседовать</option>
              </StyledSelect>
            ) : (
              <StyledLink
                button
                type="button"
                text="Проверить"
                inlineStyles={{
                  padding: '8px 10px',
                }}
              />
            )}
          </StyledLinkWrapper>
        )}
      </StyledTestWrap>
    </StyledTestItem>
  );
};

export default StyledTest;
