import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useActions } from './hooks/useActions';
import Header from './components/Template/Header/Header';
import VacancyDisplay from './components/Template/VacancyDisplay/VacancyDisplay';
import { appRoute, publicRoute } from './routes/AppRoute';
import useTypedSelector from './hooks/useTypedSelector';

const App: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { checkAuthorization } = useActions();
  const { auth } = useTypedSelector(r => r.auth);

  if (auth.isAuth === null) {
    checkAuthorization();
  }

  useEffect(() => {
    if (pathname === '/login' && auth.isAuth === true) {
      history.push('/');
    } else if (auth.isAuth === false) {
      history.push('/login');
    }
  }, [auth.isAuth]);

  const urlVacancy = /vacancies\/\d/;

  useEffect(() => {
    if (!pathname.match(urlVacancy)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <>
      <main className="main" id="main">
        {auth.isAuth ? (
          <>
            <Header />
            <VacancyDisplay />
            <Switch>
              {appRoute.map((item: any) => {
                return (
                  <Route
                    key={item.path}
                    path={item.path}
                    exact={item.exact}
                    component={item.component}
                  />
                );
              })}
              <Redirect to="/" />
            </Switch>
          </>
        ) : (
          <Switch>
            {publicRoute.map((item: any) => {
              return (
                <>
                  <Route
                    key={item.path}
                    path={item.path}
                    exact={item.exact}
                    component={item.component}
                  />
                </>
              );
            })}
            <Redirect to="/login" />
          </Switch>
        )}
      </main>
    </>
  );
};

export default App;
