import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useActions } from '../../../hooks/useActions';
import VacanciesItemHeader from '../VacanciesItemHeader/VacanciesItemHeader';
import VacancyActions from '../VacancyActions/VacancyActions';
import EditVacancy from '../EditVacancy/EditVacancy';
import VacancyInterviews from '../VacancyInterviews/VacancyInterviews';
import VacancyQuestionnaire from '../VacancyQuestionnaire/VacancyQuestionnaire';
import VacancyCVs from '../VacancyCVs/VacancyCVs';
import ErrorComponent from '../../Template/Errors/ErrorComponent';
import useTypedSelector from '../../../hooks/useTypedSelector';

interface Params {
  id: string;
}

const VacanciesItem: React.FunctionComponent = () => {
  const params: Params = useParams();
  const { fetchSingleVacancy, clearSingleVacancy } = useActions();
  const { loaded } = useTypedSelector(r => r.vacancies.vacancy);
  useEffect(() => {
    fetchSingleVacancy(params.id);
  }, [params.id]);
  useEffect(() => {
    return () => {
      clearSingleVacancy();
    };
  }, []);

  return (
    <>
      <VacanciesItemHeader id={params.id} />
      {loaded && (
        <Switch>
          <Route path="/vacancies/:id/actions" exact>
            <VacancyActions id={params.id} />
          </Route>
          <Route path="/vacancies/:id/edit" exact>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <EditVacancy />
            </ErrorBoundary>
          </Route>
          <Route path="/vacancies/:id/interviews" exact>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <VacancyInterviews id={params.id} />
            </ErrorBoundary>
          </Route>
          <Route path="/vacancies/:vacancy_id/questionnaire" exact>
            <VacancyQuestionnaire />
          </Route>
          <Route path="/vacancies/:vacancy_id/questionnaire/:id" exact>
            <VacancyQuestionnaire />
          </Route>
          <Route path="/vacancies/:id/cvs" exact>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <VacancyCVs id={params.id} />
            </ErrorBoundary>
          </Route>
        </Switch>
      )}
    </>
  );
};
export default VacanciesItem;
