import styled from 'styled-components';

export const TableWrapper = styled.div`
  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  .integration-table-wrap {
    position: relative;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
  }

  .integration-table {
    min-width: 780px;
    width: calc(100% - 2px);
    margin: 1px;
    border: 1px solid #cdcede;
    border-radius: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;
    border-collapse: collapse;
    border-style: hidden;
    box-shadow: 0 0 0 1px #cdcede;

    thead {
      background-color: #ededf3;
      color: #828282;
    }

    th {
      padding: 10px 25px 7px 25px;
    }

    td {
      padding: 10px 25px 10px 25px;
    }

    .active {
      font-weight: 500;
      position: relative;

      td:first-of-type::before {
        content: '';
        position: absolute;
        display: block;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: #fedc2d;
        border-radius: 50%;
      }
    }

    @media (min-width: 768px) {
      th {
        padding: 10px 44px 7px 44px;
      }

      td {
        padding: 12px 44px 12px 44px;
      }

      .active {
        td:first-of-type::before {
          left: 15px;
        }
      }

      .buttons-wrapper {
        flex-direction: row;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .save-btn-wrap {
    position: relative;
    width: 100%;
    max-width: 290px;

    & > div {
      position: absolute;
      right: 40px;
      top: 10px;
    }
  }

  .error {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #ff6363;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
`;
