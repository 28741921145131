import React from 'react';
import FormHeader from '../FormHeader/FormHeader';
import CompanyForm from '../CompanyForm/CompanyForm';

const AddNewCompany: React.FunctionComponent = () => {
  return (
    <>
      <FormHeader text="Добавить компанию" editCompany={false} />
      <CompanyForm action="add" />
    </>
  );
};

export default AddNewCompany;
