import React from 'react';
import styled from 'styled-components';

const StyledProgressComponent = styled.div`
  position: relative;
  width: 66px;
  height: 66px;

  @media (max-width: 767px) {
    transform: scale(0.7);
  }

  .value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 13px;
    color: #000000;

    b {
      font-weight: bold;
      font-size: 14px;
      line-height: 15px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 15px;

      b {
        font-size: 17px;
        line-height: 17px;
      }
    }
  }

  svg {
    transform: rotate(-90deg);
  }

  .full-circle {
    position: absolute;
    top: 0;
    right: 0;
  }

  .progress-circle {
    position: relative;
    z-index: 1;
    // 2 * π * R  = C
    stroke-dasharray: 188.5;
  }
`;

interface PropsInterface {
  additionalClass?: string;
  color?: string | number;
  value: number;
}

const StyledProgress: React.FC<PropsInterface> = ({
  additionalClass,
  value,
  color,
}) => {
  return (
    <StyledProgressComponent className={additionalClass}>
      <svg
        fill="none"
        width="67"
        height="67"
        className="progress-circle"
        strokeDashoffset={`${188.5 * (1 - value / 10)}`}
      >
        <circle
          cx="33"
          cy="33"
          r="30"
          strokeWidth="7"
          stroke={color ? `${color}` : '#000000'}
        ></circle>
      </svg>
      <svg fill="none" width="67" height="67" className="full-circle">
        <circle
          cx="33"
          cy="33"
          r="30"
          strokeWidth="7"
          stroke="#ffffff"
        ></circle>
      </svg>
      <span className="value">
        <b>{value}</b>
        /10
      </span>
    </StyledProgressComponent>
  );
};

export default StyledProgress;
