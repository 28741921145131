import styled from 'styled-components';

export const Integration = styled.section`
  margin-top: 40px;
`;

export const IntegrationTitle = styled.h2`
  font-size: 25px;
  line-height: 29px;
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

export const IntegrationWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 25px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;
