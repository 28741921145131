import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { createArray } from '../../../helpers/createArray';

const StyledRatingComponent = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;

  &:disabled {
    pointer-events: none;
  }

  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .legend {
    font-size: 13px;
    line-height: 20px;
    color: #4f4f4f;
    margin: 0;
    margin-bottom: 10px;

    span {
      font-weight: bold;
      font-size: 17px;
      line-height: 15px;
      color: #000000;
      padding-left: 3px;
    }
  }

  label {
    margin: 0;
    display: block;
    width: 23px;
    height: 7px;
    border-radius: 10px;
    background: #cdcede;
    margin-right: 3px;
    cursor: pointer;
  }

  input:checked ~ label,
  input:checked ~ label ~ label {
    background-color: #fedc2d;
  }

  input:not(:checked) ~ label:hover,
  input:not(:checked) ~ label:hover ~ label {
    background-color: #feca2d;
  }
`;

interface PropsInterface {
  max: number;
  name?: string;
  id?: string | number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  legend?: string | number;
  valueInfo?: string | number;
  disabled?: boolean;
}

interface HTMLFieldSetElementWithVal extends HTMLFieldSetElement {
  value: number;
}

const StyledRating: React.FC<PropsInterface> = ({
  max = 10,
  name,
  id,
  handleChange,
  legend,
  valueInfo,
  disabled,
}) => {
  const [val, setVal] = useState(0);
  const rating = createArray(max);

  const getValue = (evt: React.ChangeEvent<HTMLFieldSetElementWithVal>) => {
    setVal(evt.target.value);
  };

  const isReport = valueInfo && valueInfo !== 0;

  return (
    <StyledRatingComponent
      onChange={
        (evt: React.ChangeEvent<HTMLFieldSetElementWithVal>) => getValue(evt)
        // eslint-disable-next-line
      }
      disabled={disabled}
    >
      <legend className="legend">
        {legend}:<span>{isReport ? valueInfo : val}</span>/{max}
      </legend>
      <div className="wrapper">
        {rating.map(el => {
          const combId = `${id}-${el}`;

          return (
            <Fragment key={combId}>
              <input
                className="visually-hidden"
                type="radio"
                name={name}
                value={isReport ? valueInfo : el}
                id={combId}
                onChange={handleChange}
                defaultChecked={isReport && valueInfo ? el >= valueInfo : false}
                // eslint-disable-next-line
                readOnly={isReport ? true : false}
              />
              <label htmlFor={combId} aria-label={`${el}`}></label>
            </Fragment>
          );
        })}
      </div>
    </StyledRatingComponent>
  );
};

export default StyledRating;
