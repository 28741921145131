import React, { useState } from 'react';
import styled from 'styled-components';
import StyledInput, {
  StyledInputComponent,
} from '../../Template/StyledComponents/StyledInput';

const StyledDiv = styled.div`
  position: relative;
`;

const StyledButtonShow = styled.button`
  position: absolute;
  right: 17px;
  top: calc(50% - 1px);
  display: block;
  width: 19px;
  height: 12px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  cursor: pointer;

  .open {
    display: none;
  }
  .close {
    display: block;
  }

  &:hover svg,
  &:focus svg {
    filter: invert(100%);
  }

  &.show {
    .open {
      display: block;
    }
    .close {
      display: none;
    }
  }
`;

const openIcon = (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="open"
  >
    <path
      d="M9.14859 0.377193C12.5252 0.377193 15.5387 2.0757 17.4761 4.73272C17.7147 5.05989 17.7147 5.50152 17.4761 5.82869C15.5387 8.4857 12.5254 10.1842 9.14878 10.1842C5.77217 10.1842 2.75852 8.48569 0.82103 5.82868C0.582465 5.50152 0.582465 5.05989 0.82103 4.73272C2.75851 2.0757 5.77198 0.377193 9.14859 0.377193Z"
      stroke="#BDBDBD"
      strokeWidth="0.754386"
    />
    <circle cx="9.0527" cy="5.28067" r="3.01754" fill="#C4C4C4" />
  </svg>
);

const closeIcon = (
  <svg
    width="21"
    height="10"
    viewBox="0 0 21 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="close"
  >
    <path
      d="M17.5965 3.07243L19.8597 5.55934M13.3059 5.97382L14.3505 9.29825M7.56128 5.97382L6.51665 9.29825M3.26316 3.07243L1 5.55934M19.1053 1C16.3392 4.73037 9.67544 9.70419 2.50877 1"
      stroke="#BDBDBD"
      strokeWidth="1.05505"
      strokeLinecap="round"
    />
  </svg>
);

interface PropsInterface {
  id?: string;
}

const PasswordInput: React.FunctionComponent<PropsInterface> = ({ id }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  return (
    <StyledDiv>
      <StyledInputComponent
        field
        id={id}
        type={show ? 'text' : 'password'}
        name="password"
        placeholder="**********"
        inlineStyles={{
          padding: '16px 20px 12px 20px',
        }}
      />
      <StyledButtonShow
        className={show ? 'show' : ''}
        type="button"
        onClick={handleShow}
      >
        {openIcon}
        {closeIcon}
      </StyledButtonShow>
    </StyledDiv>
  );
};

export default PasswordInput;
