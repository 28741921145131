import * as React from 'react';
import { useHistory } from 'react-router';
import { IntegrationService } from '../../../../types/companies';
import StyledLink from '../../../Template/StyledComponents/StyledLink';
import * as Styled from './StyledIntegrationCard';

interface IntegrationCardProps {
  service: IntegrationService;
}

const IntegrationCard: React.FunctionComponent<IntegrationCardProps> = ({
  service,
}) => {
  const history = useHistory();

  return (
    <Styled.IntegrationCard
      $color={service.color}
      $background={service.background}
    >
      <img
        className="image"
        src={service.logo}
        alt="Превью сервиса"
        width="61"
        height="61"
      />
      <div className="card-wrapper">
        <h3 className="title">{service.name}</h3>
        <p className="text">{service.text}</p>
        <div className="btn-wrapper">
          <StyledLink
            path={`${history.location.pathname}${service.path}`}
            text="Настроить"
            inlineStyles={{ padding: 10 }}
          />
        </div>
      </div>
    </Styled.IntegrationCard>
  );
};

export default IntegrationCard;
