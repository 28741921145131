import * as React from 'react';
import StyledTitle from '../../../Template/StyledComponents/StyledTitle';
import FormHeader from '../../FormHeader/FormHeader';

interface IntegrationHhProps {}

const IntegrationHh: React.FunctionComponent<IntegrationHhProps> = () => {
  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany></FormHeader>
      <section>
        <StyledTitle color="#F06767" text="Интеграция с HH.RU" />
      </section>
    </>
  );
};

export default IntegrationHh;
