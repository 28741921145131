import styled from 'styled-components';

export const IntegrationCard = styled.div<{
  $color: string;
  $background: string;
}>`
  width: 100%;
  background-color: ${props => (props.$color ? props.$color : '#D0E5FA')};
  background-image: ${props =>
    props.$background ? `url(${props.$background})` : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 30px;
  padding-bottom: 35px;
  padding-right: 100px;
  border-radius: 5px;

  .title {
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #606770;
    margin: 0;
    margin-bottom: 25px;
  }

  .btn-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 152px);
    gap: 14px;
  }

  .image {
    width: 61px;
    height: 61px;
    margin-right: 25px;
    align-self: start;
  }

  .card-wrapper {
    padding-top: 20px;
  }
`;
