import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { Link, NavLink, useHistory } from 'react-router-dom';
import mainLogo from '../../../assets/image/main-logo.svg';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useActions } from '../../../hooks/useActions';
import CompaniesSelector from './CompaniesSelector/CompaniesSelector';
import MobileMenu from './MobileMenu/MobileMenu';
import { resetAuthorization } from '../../../store/actions/auth';
import useTypedSelector from '../../../hooks/useTypedSelector';

export const StyledHeaderTop = styled.div`
  box-shadow: 0 4px 6px rgb(52 59 118 / 6%);
  background: #333333;
  min-height: 55px;

  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledHeaderNav = styled.div`
  box-shadow: 0 4px 7px rgb(52 59 118 / 9%);
  background: #ffffff;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const StyledLogo = styled(Link)`
  display: inline-flex;
  align-items: center;
  max-width: 120px;
  height: 55px;
`;

export const StyledHelp = styled.div`
  display: flex;
  align-items: center;

  span,
  a {
    font-size: 13px;
    line-height: 20px;
    color: #ffffff;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }

  span {
    position: relative;
    display: block;
    font-size: 13px;
    line-height: 20px;
    color: #ffffff;
    padding-right: 30px;
    margin-right: 5px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 10px;
      width: 24px;
      height: 1px;
      background-color: #ffffff;
    }
  }
`;

const StyledProfile = styled.div`
  margin-left: auto;
  position: relative;

  &::before {
    position: absolute;
    bottom: -10px;
    content: '';
    width: 100%;
    height: 22px;
    background-color: transparent;
  }

  & > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    text-transform: uppercase;
    color: #ffffff;

    &::after {
      content: attr(data-letters);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }
  }

  &:hover,
  &:focus-within {
    .profile-block {
      display: block !important;
    }
  }
`;

const StyledProfileBlock = styled.div`
  display: none;
  position: absolute;
  content: '';
  width: 260px;
  box-shadow: 0px 1px 10px rgb(2 1 72 / 16%);
  border-radius: 3px;
  background-color: #fff;
  z-index: 103;
  padding-top: 16px;
  padding-bottom: 26px;
  top: 64px;
  left: -86px;

  .title {
    padding: 0px 14px 14px 14px;
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    color: #333333;
  }

  .btns {
    padding: 10px 16px 0px 16px;
    display: flex;
    justify-content: space-between;
  }

  .btn-link {
    border: 1px solid #cdcede;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #4f4f4f;

    &:first-of-type {
      max-width: 170px;
      width: 100%;
      margin-right: 5px;
    }

    &:last-of-type {
      max-width: 58px;
      width: 100%;
    }

    &:hover,
    &:focus {
      border: 1px solid #b0b1c2;
    }
  }
`;

const StyledList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .item {
    margin-right: 60px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .item-link {
    display: block;
    padding: 17px 0 13px;
    border-bottom: 4px solid transparent;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;
    transition: color 0.3s ease;

    &.active-link {
      border-color: #ffe600;
    }

    &:hover,
    &:focus {
      color: #000000;

      .active-link {
        color: #4f4f4f;
      }
    }
  }
`;

const StyledOtherLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 132px;
  margin-left: auto;
`;

const Header: React.FunctionComponent = () => {
  const { fetchCompanies, fetchDicts } = useActions();
  const { fetchProfile } = useActions();
  const { auth, profile } = useTypedSelector(r => r.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {});
  useEffect(() => {
    fetchProfile();
    fetchCompanies();
    fetchDicts();
  }, []);

  const handleClickLogOut = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    dispatch(resetAuthorization());
    history.push('/login');
  };
  return (
    <header>
      <StyledHeaderTop>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-6 col-9 d-flex align-items-center d-lg-block">
              <StyledLogo to="/">
                <img src={mainLogo} alt="#" />
              </StyledLogo>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-5 d-none d-lg-block">
              <StyledHelp>
                <span>У вас есть вопросы?</span>
                <a href="mailto:info@mebot24-hr.ru">info@mebot24-hr.ru</a>
              </StyledHelp>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-3">
              <CompaniesSelector mobile={false} />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-3">
              {profile.last_name && (
                <StyledProfile>
                  <Link
                    to="/"
                    data-letters={
                      profile.last_name.slice(0, 1) + profile.name.slice(0, 1)
                    }
                  >
                    {profile.last_name} {profile.name.slice(0, 1)}.
                  </Link>
                  <StyledProfileBlock className="profile-block">
                    <div className="title">
                      {profile.last_name} {profile.name}
                    </div>
                    <div className="btns">
                      <Link className="btn-link" to="/profile/">
                        Редактировать профиль
                      </Link>
                      {!auth.token ? (
                        <Link className="btn-link" to="/logIn">
                          Войти
                        </Link>
                      ) : (
                        <Link
                          className="btn-link"
                          to="/"
                          onClick={e => handleClickLogOut(e)}
                        >
                          Выйти
                        </Link>
                      )}
                    </div>
                  </StyledProfileBlock>
                </StyledProfile>
              )}
            </div>
          </div>
        </div>
      </StyledHeaderTop>
      <StyledHeaderNav>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <nav>
                <StyledList>
                  <li className="item">
                    <NavLink
                      className="item-link"
                      to="/"
                      activeClassName="active-link"
                      exact
                    >
                      Главная
                    </NavLink>
                  </li>
                  <li className="item">
                    <NavLink
                      className="item-link"
                      to="/vacancies/"
                      activeClassName="active-link"
                    >
                      Вакансии
                    </NavLink>
                  </li>
                  <li className="item">
                    <NavLink
                      className="item-link"
                      to="/companies/"
                      activeClassName="active-link"
                    >
                      Компании
                    </NavLink>
                  </li>
                  <li className="item">
                    <NavLink
                      className="item-link"
                      to="/about/"
                      activeClassName="active-link"
                    >
                      О системе
                    </NavLink>
                  </li>
                </StyledList>
              </nav>
            </div>
            <div className="col-lg-4">
              <StyledOtherLink to="/">
                Другие продукты
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
                    stroke="#333333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </StyledOtherLink>
            </div>
          </div>
        </div>
      </StyledHeaderNav>
      <MobileMenu />
    </header>
  );
};
export default Header;
