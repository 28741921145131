import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLinkRoundComponent = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 17px 10px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
  height: 37px;
  transition: all 0.3s ease;
  span {
    margin-right: 6px;
  }
  svg {
    fill: #ffffff;
  }
  &:hover,
  &:focus {
    background-color: #fff;
    color: #333333;
    svg {
      fill: #cdcede;

      &.plus-icon {
        stroke: #ffffff;
      }
    }
  }
  &:nth-child(2) {
    padding-right: 20px;
  }
  &.active {
    background-color: #ffffff;
    color: #333333;
  }
  &.active svg {
    fill: #cdcede;

    &.plus-icon {
      stroke: #ffffff;
    }
  }

  @media (max-width: 991px) {
    height: 30px;
    padding: 6px 14px 6px 17px;
    font-size: 13px;
    line-height: 18px;
  }
`;

interface PropsInterface {
  text: string;
  path?: string;
  additionalClass?: string;
}

const StyledLinkRound: React.FC<PropsInterface> = ({
  text,
  path,
  additionalClass,
  children,
}) => {
  return (
    <StyledLinkRoundComponent className={additionalClass} to={path}>
      <span>{children}</span>
      {text}
    </StyledLinkRoundComponent>
  );
};

export default StyledLinkRound;
