import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import {
  Currency,
  NewVacancy,
  NewVacancyError,
} from '../../../types/vacancies';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { Company } from '../../../types/mainPage';
import { addNewVacancy } from '../../../store/actions/vacancies';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledInputRadio from '../../Template/StyledComponents/StyledInputRadio';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  StyledTitle,
  StyledLabel,
  StyledInputWrap,
  StyledInputWrapper,
  StyledSmallCol,
  StyledRadioInputFieldset,
  StyledRadioInputWrap,
  StyledRadioInputLabel,
  StyledButtonWrap,
  StyledContainer,
  StyledErrorMessageWrap,
} from '../StyledVacanciesComponents/StyledVacanciesComponents';

const AddNewVacancy: React.FunctionComponent = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState<NewVacancyError>({
    status: false,
    name: false,
    url: false,
    currency_code: false,
    salary_from: false,
    salary_to: false,
    type_location: false,
    type_schedule: false,
    type_frequency: false,
    text_conditions: false,
    text_responsibilities: false,
    text_requirements: false,
  });
  const { companies } = useTypedSelector(r => r.mainData.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const history = useHistory();
  const [vacancy, setVacancy] = useState<NewVacancy>({
    status: 'Y',
    name: 'Новая вакансия',
    url: '',
    currency_code: Currency.RUB,
    salary_from: undefined,
    salary_to: undefined,
    type_location: 1,
    type_schedule: 1,
    type_frequency: 1,
    text_conditions: '',
    text_responsibilities: '',
    text_requirements: '',
  });

  const handleChangeVacancyState = (value: string | number, type: string) => {
    setVacancy(prev => {
      return { ...prev, [type]: value };
    });

    setEmptyFieldError(prev => {
      return { ...prev, [type]: false };
    });
  };

  const handleSaveNewVacancy = () => {
    let checkPassed = true;

    Object.keys(vacancy).forEach((key: string) => {
      const keyVacancy = key as keyof typeof vacancy;

      if (!vacancy[keyVacancy]) {
        checkPassed = false;
        setEmptyFieldError(prev => {
          return { ...prev, [keyVacancy]: true };
        });
      }
    });

    if (checkPassed) {
      setButtonLoader(true);
      addNewVacancy(vacancy)
        .then(r => {
          const { id } = r.data;
          setButtonLoader(false);
          history.push(`/vacancies/${id}`);
        })
        .catch(e => {
          console.log(e);
          setButtonLoader(false);
        });
    }
  };

  return (
    <StyledContainer className="container">
      <div className="row align-items-center">
        <div className="col-lg-3">
          <StyledTitle>Создание вакансии</StyledTitle>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-lg-6">
            <StyledInputWrap>
              <StyledLabel htmlFor="vacancy-name">{vacancy.name}</StyledLabel>
              <StyledInput
                input
                id="vacancy-name"
                type="text"
                name="name"
                placeholder="Название вакансии"
                value={vacancy.name}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeVacancyState(e.target.value, e.target.name);
                }}
              />
              <StyledErrorMessageWrap>
                {emptyFieldError.name && 'Вы не заполнили поле'}
              </StyledErrorMessageWrap>
            </StyledInputWrap>
            <StyledInputWrap>
              <StyledLabel htmlFor="company-name">Компания</StyledLabel>
              <StyledSelect name="company" id="company-name">
                {companies.map((r: Company) => (
                  <option value={r.id} key={r.id}>
                    {r.name}
                  </option>
                ))}
              </StyledSelect>
            </StyledInputWrap>
            <StyledInputWrap as="fieldset">
              <StyledLabel as="legend">Заработная плата</StyledLabel>
              <div className="row">
                <StyledSmallCol className="col-xl-5 col-sm-4 col-4">
                  <div className="position-relative">
                    <label className="visually-hidden" htmlFor="id-from">
                      От
                    </label>
                    <StyledInput
                      input
                      type="number"
                      name="salary_from"
                      placeholder="от"
                      value={vacancy.salary_from}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleChangeVacancyState(
                          parseInt(e.target.value, 10),
                          e.target.name,
                        );
                      }}
                    />
                    <StyledErrorMessageWrap>
                      {emptyFieldError.salary_from && 'Вы не заполнили поле'}
                    </StyledErrorMessageWrap>
                  </div>
                </StyledSmallCol>
                <StyledSmallCol className="col-xl-5 col-sm-4 col-4">
                  <div className="position-relative">
                    <label className="visually-hidden" htmlFor="id-to">
                      До
                    </label>
                    <StyledInput
                      input
                      type="number"
                      name="salary_to"
                      placeholder="до"
                      value={vacancy.salary_to}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleChangeVacancyState(
                          parseInt(e.target.value, 10),
                          e.target.name,
                        );
                      }}
                    />
                    <StyledErrorMessageWrap>
                      {emptyFieldError.salary_to && 'Вы не заполнили поле'}
                    </StyledErrorMessageWrap>
                  </div>
                </StyledSmallCol>
                <div className="col-xl-2 col-sm-4 col-4">
                  <StyledSelect
                    handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleChangeVacancyState(e.target.value, e.target.name);
                    }}
                    name="currency_code"
                    inlineStyles={{ padding: '14px 24px 14px 10px' }}
                  >
                    <option value={Currency.RUB}>руб</option>
                    <option value={Currency.EUR}>eur</option>
                    <option value={Currency.USD}>usd</option>
                  </StyledSelect>
                </div>
              </div>
            </StyledInputWrap>
            <StyledInputWrap>
              <div className="position-relative">
                <StyledLabel htmlFor="vacancy-link">
                  Ссылка на источник размещения
                </StyledLabel>
                <StyledInput
                  id="vacancy-link"
                  textarea
                  name="url"
                  placeholder="Ссылка на источник размещения"
                  value={vacancy.url}
                  inlineStyles={{ height: 92 }}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleChangeVacancyState(e.target.value, e.target.name);
                  }}
                />
                <StyledErrorMessageWrap>
                  {emptyFieldError.url && 'Вы не заполнили поле'}
                </StyledErrorMessageWrap>
              </div>
              <StyledRadioInputFieldset>
                <legend className="visually-hidden">Источник размещения</legend>
                <StyledRadioInputWrap>
                  <input
                    type="radio"
                    id="linkHH"
                    name="vacancyInfo6"
                    defaultChecked
                    className="visually-hidden"
                  />
                  <StyledRadioInputLabel htmlFor="linkHH">
                    hh
                  </StyledRadioInputLabel>
                </StyledRadioInputWrap>
                <StyledRadioInputWrap>
                  <input
                    type="radio"
                    id="linkSuperjob"
                    name="vacancyInfo6"
                    className="visually-hidden"
                  />
                  <StyledRadioInputLabel htmlFor="linkSuperjob">
                    superjob
                  </StyledRadioInputLabel>
                </StyledRadioInputWrap>
                <StyledRadioInputWrap>
                  <input
                    type="radio"
                    id="linkRabota"
                    name="vacancyInfo6"
                    className="visually-hidden"
                  />
                  <StyledRadioInputLabel htmlFor="linkRabota">
                    rabota
                  </StyledRadioInputLabel>
                </StyledRadioInputWrap>
                <StyledRadioInputWrap>
                  <input
                    type="radio"
                    id="linkOther"
                    name="vacancyInfo6"
                    className="visually-hidden"
                  />
                  <StyledRadioInputLabel htmlFor="linkOther">
                    Другое
                  </StyledRadioInputLabel>
                </StyledRadioInputWrap>
              </StyledRadioInputFieldset>
            </StyledInputWrap>
            <StyledInputWrap as="fieldset">
              <StyledLabel as="legend">График</StyledLabel>
              <StyledInputWrapper>
                {dictionaries.vacancySchedule?.map(
                  (item: { id: number; title: string | null }) => {
                    return (
                      <StyledInputRadio
                        key={item.id}
                        id={item.id}
                        idPrefix="schedule"
                        name="type_schedule"
                        flag={vacancy.type_schedule}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleChangeVacancyState(
                            parseInt(e.target.value, 10),
                            e.target.name,
                          );
                        }}
                        text={item.title}
                      />
                    );
                  },
                )}
              </StyledInputWrapper>
            </StyledInputWrap>
            <StyledInputWrap as="fieldset">
              <StyledLabel as="legend">Тип вакансии</StyledLabel>
              <StyledInputWrapper>
                {dictionaries.vacancyLocation?.map(
                  (item: { id: number; title: string | null }) => (
                    <StyledInputRadio
                      key={item.id}
                      id={item.id}
                      idPrefix="vacancytype"
                      name="type_location"
                      flag={vacancy.type_location}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleChangeVacancyState(
                          Number(e.target.value),
                          e.target.name,
                        );
                      }}
                      text={item.title}
                    />
                  ),
                )}
              </StyledInputWrapper>
            </StyledInputWrap>
            <StyledInputWrap as="fieldset">
              <StyledLabel as="legend">Тип найма</StyledLabel>
              <StyledInputWrapper>
                {dictionaries.vacancyFrequency?.map(
                  (item: { id: number; title: string | null }) => (
                    <StyledInputRadio
                      key={item.id}
                      id={item.id}
                      idPrefix="worktype"
                      name="type_frequency"
                      flag={vacancy.type_frequency}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleChangeVacancyState(
                          Number(e.target.value),
                          e.target.name,
                        );
                      }}
                      text={item.title}
                    />
                  ),
                )}
              </StyledInputWrapper>
            </StyledInputWrap>
          </div>
          <div className="col-lg-6">
            <StyledInputWrap>
              <StyledLabel htmlFor="vacancy-requirements">
                Требования
              </StyledLabel>
              <StyledInput
                input
                id="vacancy-requirements"
                textarea
                name="text_requirements"
                value={vacancy.text_requirements}
                inlineStyles={{ height: 179 }}
                placeholder="Правила организации вашего коллектива, необходимые знания..."
                handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChangeVacancyState(e.target.value, e.target.name);
                }}
              />
              <StyledErrorMessageWrap>
                {emptyFieldError.text_requirements && 'Вы не заполнили поле'}
              </StyledErrorMessageWrap>
            </StyledInputWrap>
            <StyledInputWrap>
              <StyledLabel htmlFor="vacancy-conditions">
                Условия работы
              </StyledLabel>
              <StyledInput
                input
                id="vacancy-conditions"
                textarea
                name="text_conditions"
                value={vacancy.text_conditions}
                inlineStyles={{ height: 142 }}
                placeholder="Опишите режим работы, местонахождение компании..."
                handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChangeVacancyState(e.target.value, e.target.name);
                }}
              />
              <StyledErrorMessageWrap>
                {emptyFieldError.text_conditions && 'Вы не заполнили поле'}
              </StyledErrorMessageWrap>
            </StyledInputWrap>
            <StyledInputWrap>
              <StyledLabel htmlFor="vacancy-duties">
                Перечислите обязанности
              </StyledLabel>
              <StyledInput
                input
                id="vacancy-duties"
                textarea
                name="text_responsibilities"
                inlineStyles={{ height: 252 }}
                handleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChangeVacancyState(e.target.value, e.target.name);
                }}
                placeholder="Какими навыками и знаниями должен обладать кандидат..."
                value={vacancy.text_responsibilities}
              />
              <StyledErrorMessageWrap>
                {emptyFieldError.text_responsibilities &&
                  'Вы не заполнили поле'}
              </StyledErrorMessageWrap>
            </StyledInputWrap>
          </div>
          <div className="col-lg-12">
            <StyledButtonWrap style={{ opacity: buttonLoader ? 0.5 : 1 }}>
              <StyledLink
                type="button"
                button
                handleСlick={handleSaveNewVacancy}
                disabled={buttonLoader}
                text="Сохранить"
              ></StyledLink>
              {buttonLoader && (
                <Loader type="Audio" color="#000" height="16px" width="16px" />
              )}
            </StyledButtonWrap>
          </div>
        </div>
      </form>
    </StyledContainer>
  );
};
export default AddNewVacancy;
