import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledLink from '../Template/StyledComponents/StyledLink';
import VacanciesCard from './VacanciesCard';
import useTypedSelector from '../../hooks/useTypedSelector';
import { Vacancy } from '../../types/vacancies';
import { useActions } from '../../hooks/useActions';
import VacanciesCardArchive from './VacanciesCardArchive';
import VacanciesPagination from './VacanciesPagination';
import DataStatus from '../Common/DataStatus/DataStatus';

const StyledWrapper = styled.div`
  padding-top: 20px;

  @media (min-width: 768px) {
    padding-top: 46px;
  }
`;

const StyledBtnsWrap = styled.div`
  margin-bottom: 26px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const StyledTitleBtn = styled.h2`
  margin: 0;

  button {
    width: 255px;
    margin-bottom: 7px;
    display: grid;
    grid-template-columns: 1fr 25px;
    grid-column-gap: 20px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font: inherit;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    padding: 0;
    text-align: left;
    color: #000000;

    svg {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }

    circle {
      transition: stroke 0.5s;
    }

    &:hover circle,
    &:focus circle {
      stroke: #fedc2d;
    }

    &.archive {
      margin-bottom: 24px;
    }

    @media (min-width: 768px) {
      &.archive {
        margin-bottom: 17px;
      }
    }
  }

  &.active button svg {
    transform: rotate(180deg);
  }
`;

const StyledLinkWrap = styled.div`
  max-width: 210px;
`;

const StyledCardsWrap = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 14px;
  justify-items: center;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 40px;
  }

  @media (min-width: 992px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
`;

const StyledCardsWrapArchive = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 14px;
  justify-items: center;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 40px;
  }

  @media (min-width: 992px) {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
`;

interface PaginationProps {
  page: number;
  setPage: (value: number) => void;
  archPage: number;
  setArchPage: (value: number) => void;
  refetchAll: () => void;
  loading: {
    actual: boolean;
    archive: boolean;
  };
  error: {
    actual: boolean;
    archive: boolean;
  };
}

const VacanciesList: React.FC<PaginationProps> = ({
  page,
  setPage,
  archPage,
  setArchPage,
  refetchAll,
  loading,
  error,
}) => {
  const [showActual, setShowActual] = useState(true);
  const [showArchive, setShowArchive] = useState(true);
  const { vacanciesList } = useTypedSelector(r => r.vacancies);
  const { updateVacancy } = useActions();
  const limit = 4;

  const forcePage = () => {
    if (page < vacanciesList.count / limit - 1) {
      setPage(page + 1);
    }
  };
  const forceArchPage = () => {
    if (archPage < vacanciesList.countArchived / limit - 1) {
      setArchPage(archPage + 1);
    }
  };
  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
  };
  const handleChangeArchPage = (pageNumber: number) => {
    setArchPage(pageNumber);
  };

  const handleVacancy = (vacancy: Vacancy) => {
    const newItem = { ...vacancy };
    if (vacancy.status === 'Y') {
      newItem.status = 'N';
    } else {
      newItem.status = 'Y';
    }

    const fetchAfterUpdate = async () => {
      await updateVacancy(newItem.id, newItem, true);
    };

    fetchAfterUpdate().then(() => {
      refetchAll();
    });
  };

  const handleBtnClick = (cb: any, name: any) => {
    cb(!name);
  };

  const pageNumber = Math.ceil(vacanciesList.count / limit);
  const pageArchNumber = Math.ceil(vacanciesList.countArchived / limit);

  return (
    <div className="container">
      <StyledWrapper>
        <StyledBtnsWrap>
          <StyledTitleBtn className={showActual ? 'active' : ''}>
            <button
              type="button"
              onClick={() => handleBtnClick(setShowActual, showActual)}
            >
              Актуальные вакансии
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="13"
                  cy="13"
                  r="12.48"
                  transform="rotate(-90 13 13)"
                  stroke="#BDBDBD"
                  strokeWidth="1.04"
                />
                <path
                  d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </StyledTitleBtn>
          <StyledLinkWrap>
            <StyledLink
              text="Добавить вакансию"
              path="/vacancies/add"
              inlineStyles={{
                height: '38px',
              }}
            >
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="7.60469" cy="7" rx="6.95674" ry="7" fill="black" />
                <path
                  d="M7.60428 5V9M9.59192 7L5.61664 7"
                  stroke="#FEDC2D"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </StyledLink>
          </StyledLinkWrap>
        </StyledBtnsWrap>
        {showActual && (
          <DataStatus
            loading={!vacanciesList.loaded}
            error={error.actual}
            items={vacanciesList.vacancies}
            noItemsText="Вакансий нет"
          >
            <StyledCardsWrap>
              {vacanciesList.vacancies.map((v: Vacancy) => (
                <VacanciesCard
                  vacancy={v}
                  key={v.id}
                  handleVacancy={handleVacancy}
                />
              ))}
            </StyledCardsWrap>
            {vacanciesList.count && pageNumber > 1 && (
              <VacanciesPagination
                forcePage={forcePage}
                handleChangePage={handleChangePage}
                vacanciesCount={vacanciesList.count}
                limit={limit}
                page={page}
              />
            )}
            {vacanciesList.count === 0 && 'Активных вакансий нет'}
          </DataStatus>
        )}

        <StyledTitleBtn className={showArchive ? 'active' : ''}>
          <button
            className="archive"
            type="button"
            onClick={() => handleBtnClick(setShowArchive, showArchive)}
          >
            Архивные вакансии
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="13"
                cy="13"
                r="12.48"
                transform="rotate(-90 13 13)"
                stroke="#BDBDBD"
                strokeWidth="1.04"
              />
              <path
                d="M9 14C9.32 13.68 11.8 11.2 13 10L17 14"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </StyledTitleBtn>

        {showArchive && (
          <DataStatus
            loading={!vacanciesList.loaded}
            error={error.archive}
            items={vacanciesList.vacanciesArchived}
            noItemsText="Вакансий нет"
          >
            <StyledCardsWrapArchive>
              {vacanciesList.vacanciesArchived.map((item: Vacancy) => (
                <VacanciesCardArchive
                  vacancy={item}
                  key={item.id}
                  handleVacancy={handleVacancy}
                />
              ))}
            </StyledCardsWrapArchive>
            {vacanciesList.countArchived && pageArchNumber > 1 && (
              <VacanciesPagination
                forcePage={forceArchPage}
                handleChangePage={handleChangeArchPage}
                vacanciesCount={vacanciesList.countArchived}
                limit={limit}
                page={archPage}
              />
            )}
            {vacanciesList.countArchived === 0 && 'Архивных вакансий нет'}
          </DataStatus>
        )}
      </StyledWrapper>
    </div>
  );
};

export default VacanciesList;
