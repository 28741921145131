import React from 'react';
import styled from 'styled-components';
import { CompaniesDataType, StaticDataType } from '../../types/companies';
import StyledLink from '../Template/StyledComponents/StyledLink';
import { useActions } from '../../hooks/useActions';
import buidl1 from '../../assets/image/build.png';
import buidl2 from '../../assets/image/build-2.png';
import buidl3 from '../../assets/image/build-3.png';
import buidl4 from '../../assets/image/build-4.png';
import buidl5 from '../../assets/image/build-5.png';

const StyledCompanyCardWrap = styled.div`
  margin-bottom: 20px;
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
`;

const StyledCompanyCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 28px;
  border-radius: 5px;
  position: relative;
  background: #67b7f0;
  min-height: 232px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 472px) {
    min-height: 166px;
  }

  @media (max-width: 380px) {
    padding-right: 0px;
  }
`;

const StyledRow = styled.div`
  max-width: 378px;
  width: 100%;
  z-index: 2;

  @media (max-width: 380px) {
    max-width: 100%;
  }
`;

const StyledCompanyLetter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: 25px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  color: #ffffff;
  background: #56ccf2;
  box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.16);
  margin-right: 0px;

  &:before {
    content: attr(data-count);
    position: absolute;
    right: 45px;
    bottom: 40px;
    z-index: 10;
    display: block;
    min-width: 21px;
    min-height: 21px;
    border-radius: 50%;
    padding: 3px 7px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background: #ff7557;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 472px) {
      width: 39px;
      height: 39px;
      font-size: 15px;
      line-height: 18px;
      &:before {
        right: 26px;
        bottom: 25px;
        min-width: 17px;
        min-height: 17px;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const StyledCompanyTitle = styled.h3`
  width: 100%;
  margin: 0 0 6px;
  font-weight: bold;
  font-size: 17px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 472px) {
    font-size: 15px;
    margin-bottom: 0px;
  }
`;

const StyledCompanyCount1 = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #ffffff;
  @media (max-width: 472px) {
    font-size: 13px;
    line-height: 23px;
  }
`;

const StyledCompanyCount2 = styled.div`
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 21px;
  font-size: 15px;
  color: #ffffff;
  @media (max-width: 472px) {
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    opacity: 0.7;
    margin-bottom: 17px;
  }
`;

const StyledCompanyButtons = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 472px) {
    max-width: 196px;
    width: 100%;
  }
`;

const StyledWhiteLinkWrap = styled.div`
  max-width: 88px;
  width: 100%;

  @media (max-width: 472px) {
    max-width: 64px;
  }
`;

const StyledLinkWrap = styled.div`
  max-width: 152px;

  @media (max-width: 472px) {
    max-width: 122px;
  }
`;

const StyledCompanyCardBg = styled.div`
  position: absolute;
  content: '';
  bottom: 0px;
  right: 0px;
  z-index: 1;

  @media (max-width: 1200px) {
    right: -80px;
  }

  @media (max-width: 991px) {
    right: 0px;
  }

  @media (max-width: 768px) {
    right: -56px;
  }

  @media (max-width: 576px) {
    right: -80px;
  }

  @media (max-width: 472px) {
    right: -42px;
  }

  @media (max-width: 380px) {
    right: -12px;
    height: 100%;
  }
`;

const StyledCompanyCol10 = styled.div`
  @media (max-width: 380px) {
    padding-right: 0px;
  }
`;

const StyledCompanyCol2 = styled.div`
  @media (max-width: 380px) {
    padding-left: 5px;
  }
`;
export const setImage = (value: string) => {
  switch (true) {
    case value === '1': {
      return buidl1;
    }
    case value === '2': {
      return buidl2;
    }
    case value === '3': {
      return buidl3;
    }
    case value === '4': {
      return buidl4;
    }
    case value === '5': {
      return buidl5;
    }
    default: {
      return buidl1;
    }
  }
};

interface CompaniesProps {
  companies: CompaniesDataType;
  staticData: StaticDataType;
}

const CompaniesCard: React.FunctionComponent<CompaniesProps> = ({
  companies,
  staticData,
}) => {
  const { setCompanyActive } = useActions();
  const color = staticData.companyColors
    ?.map(n => {
      return String(n.id) === String(companies.color) ? n.color : '';
    })
    .join('');
  return (
    <>
      <StyledCompanyCardWrap className="col-lg-6 col-md-12">
        <StyledCompanyCard
          style={{
            backgroundColor: color,
          }}
        >
          <StyledRow className="row">
            <StyledCompanyCol2 className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-2">
              <StyledCompanyLetter data-count="12">
                {companies.name[0] + companies.name[1]}
              </StyledCompanyLetter>
            </StyledCompanyCol2>
            <StyledCompanyCol10 className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-10">
              <StyledCompanyTitle>
                {companies.name} № {companies.id}
              </StyledCompanyTitle>
              <div>
                <StyledCompanyCount1></StyledCompanyCount1>
                <StyledCompanyCount2></StyledCompanyCount2>
                <StyledCompanyButtons>
                  <StyledWhiteLinkWrap>
                    <StyledLink
                      button
                      type="button"
                      handleСlick={() => setCompanyActive(companies.id)}
                      text="Войти"
                      $whiteBackground
                      inlineStyles={{
                        height: '36px',
                      }}
                    ></StyledLink>
                  </StyledWhiteLinkWrap>
                  <StyledLinkWrap>
                    <StyledLink
                      text="Редактировать"
                      path={`/companies/${companies.id}/edit/`}
                      inlineStyles={{
                        height: '36px',
                      }}
                    />
                  </StyledLinkWrap>
                </StyledCompanyButtons>
              </div>
            </StyledCompanyCol10>
          </StyledRow>
          <StyledCompanyCardBg>
            <img src={setImage(String(companies.image))} alt="#" />
          </StyledCompanyCardBg>
        </StyledCompanyCard>
      </StyledCompanyCardWrap>
    </>
  );
};
export default CompaniesCard;
