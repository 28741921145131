import Basket from '../components/Basket/Basket';
import Catalog from '../components/Catalog/Catalog';
import AddNewCompany from '../components/Companies/AddNewCompany/AddNewCompany';
import Companies from '../components/Companies/Companies';
import EditCompany from '../components/Companies/EditCompany/EditCompany';
import Integration from '../components/Companies/Integration/Integration';
import IntegrationHh from '../components/Companies/Integration/IntegrationHh/IntegrationHh';
import IntegrationBitrix from '../components/Companies/Integration/IntergrationBitrix/IntegrationBitrix';
import ViewCompany from '../components/Companies/ViewCompany/ViewCompany';
import Login from '../components/Login/Login';
import MainPage from '../components/MainPage/MainPage';
import Profile from '../components/Profile/Profile';
import AddNewVacancy from '../components/Vacancies/AddNewVacancy/AddNewVacancy';
import Vacancies from '../components/Vacancies/Vacancies';
import VacanciesItem from '../components/Vacancies/VacanciesItem/VacanciesItem';

export const appRoute = [
  { path: '/companies/', component: Companies, exact: true },
  { path: '/companies/add/', component: AddNewCompany, exact: false },
  { path: '/companies/:id/edit/', component: EditCompany, exact: false },
  { path: '/companies/:id/connector/', component: Integration, exact: true },
  {
    path: '/companies/:id/connector/bitrix',
    component: IntegrationBitrix,
    exact: true,
  },
  {
    path: '/companies/:id/connector/hh',
    component: IntegrationHh,
    exact: true,
  },
  { path: '/companies/:id/', component: ViewCompany, exact: false },
  { path: '/vacancies/add/', component: AddNewVacancy, exact: true },
  { path: '/vacancies/:id/', component: VacanciesItem, exact: false },
  { path: '/vacancies/', component: Vacancies, exact: true },
  { path: '/catalog/', component: Catalog, exact: false },
  { path: '/profile/', component: Profile, exact: false },
  { path: '/basket/', component: Basket, exact: false },
  { path: '/', component: MainPage, exact: true },
];

export const publicRoute = [
  { path: '/logIn/', component: Login, exact: false },
];
