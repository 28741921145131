import React from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import arrow from '../../../assets/image/arrow.svg';

export const StyledInputComponent = styled(Field)<{
  $arrow: boolean | undefined;
  $errorFlag: boolean | undefined;
}>`
  border: ${props =>
    props.$errorFlag ? '1px solid #ff6363' : '1px solid #e0e0e0'};
  border-radius: 5px;
  width: 100%;
  height: 49px;
  padding: 12px 20px 16px 20px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.374px;
  color: #333333;
  display: flex;
  align-items: center;
  box-shadow: none;
  outline: none;
  resize: none;

  &:hover {
    border-color: ${props => (props.$errorFlag ? '#ff6363' : '#bdbdbd')};
  }

  &:focus,
  &:not(:placeholder-shown) {
    border-color: #bdbdbd;
  }

  &::placeholder {
    color: #bdbdbd;
  }

  &:disabled {
    pointer-events: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.active {
    position: relative;
    z-index: 2;
  }

  ${props =>
    props.$arrow &&
    css`
      background-image: url(${arrow});
      background-size: 12px;
      background-position: calc(100% - 15px) center;
      background-repeat: no-repeat;
      padding-right: 30px;
    `}
`;

export const StyledInputDiv = styled.div`
  input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    height: 49px;
    padding: 12px 20px 16px 20px;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.374px;
    color: #333333;
    display: flex;
    align-items: center;
    box-shadow: none;
    outline: none;
    resize: none;

    &:hover,
    &:focus {
      border-color: #bdbdbd;
    }

    &::placeholder {
      color: #bdbdbd;
    }

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

interface CustomStyles {
  minHeight?: string | number;
  height?: string | number;
  padding?: string | number;
  fontSize?: string | number;
  textAlign?: 'center';
  border?: string;
}

interface PropsInterface {
  additionalClass?: string;
  inlineStyles?: CustomStyles;
  placeholder?: string;
  id?: string;
  type?: string;
  name?: string;
  textarea?: boolean;
  input?: boolean;
  readonlyFlag?: boolean;
  field?: boolean;
  textareaField?: boolean;
  max?: number;
  handleChange?: any;
  handleFocus?: any;
  defaultValue?: string;
  value?: string | number;
  autoComplete?: string;
  $arrow?: boolean;
  disabled?: any;
  readOnly?: boolean;
  errorFlag?: boolean;
}

const StyledInput: React.FC<PropsInterface> = ({
  textareaField,
  additionalClass,
  inlineStyles,
  placeholder,
  id,
  type,
  name,
  textarea,
  input,
  field,
  max,
  handleChange,
  handleFocus,
  defaultValue,
  value,
  readonlyFlag,
  autoComplete,
  $arrow,
  disabled,
  readOnly,
  errorFlag,
}) => {
  let output;
  if (textarea) {
    output = (
      <StyledInputComponent
        value={value}
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        onChange={handleChange}
        name={name}
        as="textarea"
        autoComplete={autoComplete}
        $arrow={$arrow}
        readOnly={readOnly}
        disabled={disabled}
        $errorFlag={errorFlag}
      />
    );
  } else if (field) {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        type={type}
        name={name}
        component={textareaField && 'textarea'}
        autoComplete={autoComplete}
        $arrow={$arrow}
        disabled={disabled}
        $errorFlag={errorFlag}
      />
    );
  } else if (input) {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        type={type}
        name={name}
        max={max}
        onFocus={handleFocus}
        defaultValue={defaultValue}
        readOnly={readonlyFlag}
        value={value}
        onChange={handleChange}
        autoComplete={autoComplete}
        $arrow={$arrow}
        as="input"
        disabled={disabled}
        $errorFlag={errorFlag}
      />
    );
  } else {
    output = (
      <StyledInputComponent
        className={additionalClass}
        style={inlineStyles}
        placeholder={placeholder}
        id={id}
        type={type}
        name={name}
        max={max}
        onFocus={handleFocus}
        defaultValue={defaultValue}
        readOnly={readonlyFlag}
        value={value}
        onChange={handleChange}
        autoComplete={autoComplete}
        $arrow={$arrow}
      />
    );
  }
  return output;
};

export default StyledInput;
