import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CompaniesCard from './CompaniesCard';
import useTypedSelector from '../../hooks/useTypedSelector';
import StyledLink from '../Template/StyledComponents/StyledLink';

import { CompaniesDataType } from '../../types/companies';
import { useActions } from '../../hooks/useActions';
import Pagination from '../Common/pagination';
import { useFetching } from '../../hooks/useFetching';
import DataStatus from '../Common/DataStatus/DataStatus';

const StyledCompaniesList = styled.div`
  margin-top: 40px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const StyledCompaniesTitle = styled.h2`
  font-size: 25px;
  line-height: 29px;
  font-weight: bold;

  @media (max-width: 991px) {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 29px;
    align-items: flex-end;
    display: flex;
  }
`;

const StyledCompaniesAddBtnWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;

const StyledCompaniesAddBtn = styled.h2`
  max-width: 194px;
  width: 100%;
`;

const Companies: React.FunctionComponent = () => {
  const { fetchCompaniesPage, resetCompanySuccess } = useActions();
  const { companiesPage } = useTypedSelector(r => r.companies);
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const [count, setCount] = useState(0);
  const limit = 4;

  const [
    fetchCompaniesPageNew,
    isCompaniesLoading,
    companiesError,
  ] = useFetching(() => fetchCompaniesPage(limit, count));

  useEffect(() => {
    fetchCompaniesPageNew();
  }, [count]);

  const handleChangeCount = (value: number) => {
    setCount(prev => {
      switch (value) {
        case 1:
          if (
            prev + 1 ===
            Math.ceil(Number(companiesPage.totalCount) / limit)
          ) {
            return prev;
          }
          return prev + 1;

        case -1:
          if (prev === 0) {
            return prev;
          }
          return prev - 1;
        default:
          return prev;
      }
    });
  };

  useEffect(() => {
    resetCompanySuccess();
  }, []);

  return (
    <>
      <div className="all-companies">
        <StyledCompaniesList>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <StyledCompaniesTitle>Мои компании</StyledCompaniesTitle>
              </div>
              <div className="col-lg-4">
                <StyledCompaniesAddBtnWrap>
                  <StyledCompaniesAddBtn>
                    <StyledLink
                      text="Добавить компанию"
                      path="/companies/add/"
                      inlineStyles={{
                        height: '38px',
                      }}
                    />
                  </StyledCompaniesAddBtn>
                </StyledCompaniesAddBtnWrap>
              </div>
            </div>
            <div className="row">
              <DataStatus
                loading={isCompaniesLoading}
                error={companiesError}
                items={companiesPage.companiesPage}
                noItemsText="Компаний нет"
              >
                {companiesPage.companiesPage.map((n: CompaniesDataType) => (
                  <CompaniesCard
                    key={n.id}
                    companies={n}
                    staticData={dictionaries}
                  />
                ))}
              </DataStatus>
            </div>

            {Math.ceil(Number(companiesPage.totalCount) / limit) > 1 && (
              <Pagination
                companiesPage={Math.ceil(
                  Number(companiesPage.totalCount) / limit,
                )}
                count={count}
                handleChangeCount={handleChangeCount}
              />
            )}
          </div>
        </StyledCompaniesList>
      </div>
    </>
  );
};
export default Companies;
