import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: 55% 45%;
  }
`;

const StyledDropZone = styled.div`
  position: relative;
  background-color: #f3f3f7;
  border-radius: 11px;
  padding: 16px;
  min-height: 126px;
  display: flex;
  flex-direction: column;
  height: 265px;
  overflow: auto;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (min-width: 992px) {
    min-height: 184px;
  }
`;

const StyledDropZoneText = styled.p`
  position: absolute;
  margin: 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #cdcede;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const StyledDropElement = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  border: 1px solid #cdcede;
  border-radius: 6px;
  padding: 13px 18px;
  margin-bottom: 16px;
  cursor: pointer;
  align-items: center;
  transition: all 0.5s;

  &:hover,
  &:focus {
    background: #faf4c0;
    border-color: #faf4c0;
  }

  @media (max-width: 576px) {
    padding: 10px 6px;
    font-size: 13px;
    line-height: 21px;
  }

  @media (max-width: 991px) {
    margin-right: 10px;
  }

  @media (min-width: 992px) {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
export const getItems = (val: any) =>
  val.map((k: any, i: number) => ({
    id: `item-${i}-${new Date().getTime()}`,
    content: `item ${i}`,
    title: k.text,
    value: k.value,
  }));

export const dragVal = [
  {
    text: 'Тестирование',
    value: 'TESTING',
  },
  {
    text: 'Тестовое задание',
    value: 'TRIALWORK',
  },
  {
    text: 'Интервью',
    value: 'INTERVIEW',
  },
  {
    text: 'Видео и аудио тестирование',
    value: 'AVTESTING',
  },
];

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  result[droppableSource.droppableId] = sourceClone;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  // width: 450,
});

const QuoteApp: React.FC<any> = ({ setValue, todoValues }) => {
  function onDragEnd(result: any) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(todoValues[sInd], source.index, destination.index);
      const newState = [...todoValues];
      newState[sInd] = items;
      setValue(newState);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const result = move(
        todoValues[sInd],
        todoValues[dInd],
        source,
        destination,
      );
      const newState = [...todoValues];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newState[sInd] = result[sInd];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newState[dInd] = result[dInd];
      if (newState.filter(group => group.length).length === 1 && dInd === 0) {
        setValue([...newState.filter(group => group.length), []]);
      } else if (
        newState.filter(group => group.length).length === 1 &&
        dInd === 1
      ) {
        setValue([[], ...newState.filter(group => group.length)]);
      } else {
        setValue(newState.filter(group => group.length));
      }
    }
  }
  const getItemsAll = (el: any) => {
    return el.map((item: any, index: any) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
        {(provided, snapshot) => (
          <StyledDropElement
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {item.title}
            </div>
          </StyledDropElement>
        )}
      </Draggable>
    ));
  };
  return (
    <>
      <StyledWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          {todoValues.map((el: any, ind: number) => {
            if (ind === 0) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <StyledDropZone
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {todoValues[0].length === 0 && (
                        <StyledDropZoneText>
                          Перетащите&nbsp;поочередно этапы отбора
                        </StyledDropZoneText>
                      )}

                      {getItemsAll(el)}
                      {provided.placeholder}
                    </StyledDropZone>
                  )}
                </Droppable>
              );
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={ind}>
                <Droppable droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {getItemsAll(el)}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </DragDropContext>
      </StyledWrapper>
    </>
  );
};

export default QuoteApp;
