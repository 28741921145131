const getQueryArray = (
  obj: Record<string, any> = {},
  path: string[] = [],
  result: string[] = [],
): string[] => {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    path.push(k);

    if (v instanceof Object) {
      getQueryArray(v, path, acc);
    } else if (v != null) {
      acc.push(`${path.map((n, i) => (i ? `[${n}]` : n)).join('')}=${v}`);
    }

    path.pop();

    return acc;
  }, result);
};

const getQueryString = (obj: Record<string, any>): string =>
  getQueryArray(obj).join('&');

export { getQueryString };
