import axios from 'axios';
import { Dispatch } from 'redux';
import { AuthActions, AuthActionTypes } from '../../types/auth';
import { config, url } from './actionsAssets';
import { IAuthorization } from '../../types/mainPage';

export const fetchProfile = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      const profile = await axios.post(`${url}user/profile/`, '', config);
      dispatch({
        type: AuthActionTypes.FETCH_USER_PROFILE,
        payload: profile.data,
      });
    } catch (e) {
      //console.log(e);
    }
  };
};

export const checkAuthorization = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      const res = await axios.post(`${url}authorize/refresh_jwt/`, {}, config);
      dispatch({
        type: AuthActionTypes.SET_AUTHORIZATION_TOKEN,
        payload: res.data.jwt,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.SET_AUTHORIZATION_TOKEN,
        payload: '',
      });
    }
  };
};

export const setAuthorization = (data: IAuthorization) => {
  return (dispatch: Dispatch<AuthActions>) => {
    axios
      .post(`${url}authorize/get_jwt/`, data, config)
      .then(res => {
        dispatch({
          type: AuthActionTypes.SET_AUTHORIZATION_TOKEN,
          payload: res.data.jwt,
        });
      })
      .catch(error => {
        switch (error.response.status) {
          case 403:
            dispatch({
              type: AuthActionTypes.ERROR_AUTHORIZATION,
              payload: {
                type: 'LOGIN',
                message: error.response.data.error,
              },
            });
            break;
          default:
            break;
        }
        dispatch({
          type: AuthActionTypes.SET_IS_AUTHORIZATION,
          payload: false,
        });
      });
  };
};
export const resetAuthorization = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    await axios.post(`${url}authorize/logout/`, '', config);
    dispatch({
      type: AuthActionTypes.SET_AUTHORIZATION_TOKEN,
      payload: '',
    });
  };
};

export const testDebug = () => {
  console.log('test');
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      const res = await axios.post(`${url}authorize/refresh_jwt/`, {}, config);
      console.log(res.data);
    } catch (e) {
      //
    }
  };
};
