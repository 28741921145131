import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { wordDeclination, wordsDict } from '../../../helpers/wordDeclination';
import { Vacancy } from '../../../types/vacancies';

const StyledLi = styled.li<{
  fixedNavItem: boolean | undefined;
}>`
  position: relative;
  margin-top: ${props => (props.fixedNavItem ? '10px' : '0')};
`;

const StyledButton = styled.button<{
  fixedNavItem: boolean | undefined;
}>`
  font: inherit;
  width: 100%;
  background-color: transparent;
  text-shadow: none;
  display: flex;
  flex-wrap: wrap;
  padding: ${props =>
    props.fixedNavItem ? '22px 20px 42px 15px' : '20px 30px 40px 0'};
  position: relative;
  border: ${props => (props.fixedNavItem ? '1px solid #cdcede' : 'none')};
  border-radius: ${props => (props.fixedNavItem ? '8px' : '0')};
  transition: all 0.3s ease;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:after {
    display: ${props => (props.fixedNavItem ? 'none' : 'block')};
    position: absolute;
    content: '';
    width: 238px;
    height: 1px;
    background-color: rgba(205, 206, 222, 0.7);
    bottom: 0px;
    left: 5px;
  }

  &:hover {
    border-color: ${props => (props.fixedNavItem ? '#fedc2d' : 'inherit')};
    box-shadow: ${props =>
      props.fixedNavItem ? '0 0 0 2px #fedc2d' : 'inherit'};
  }

  &:hover .vacancy-item__letter {
    color: ${props => (props.fixedNavItem ? '#000000' : 'inherit')};
    background-color: ${props => (props.fixedNavItem ? '#fedc2d' : 'inherit')};
  }

  &:hover .vacancy-item__title {
    color: ${props => (props.fixedNavItem ? '#000000' : 'inherit')};
  }
`;

const StyledLetter = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${props => (props.fixedNavItem ? '27px' : '40px')};
  height: ${props => (props.fixedNavItem ? '27px' : '40px')};
  margin-right: 15px;
  border-radius: 50%;
  font-weight: 700;
  font-size: ${props => (props.fixedNavItem ? '14px' : '20px')};
  line-height: ${props => (props.fixedNavItem ? '20px' : '29px')};
  color: #888e96;
  background: #f2f2f2;
  transition: all 0.3s ease;
`;

const StyledInfo = styled.div<{
  fixedNavItem: boolean | undefined;
}>`
  display: block;
  width: ${props =>
    props.fixedNavItem ? 'calc(100% - 42px)' : 'calc(100% - 55px)'};
`;

const StyledTitle = styled.span<{
  fixedNavItem: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  min-height: ${props => (props.fixedNavItem ? '0' : '42px')};
  width: 100%;
  margin-bottom: ${props => (props.fixedNavItem ? '15px' : '10px')};
  font-weight: 500;
  color: ${props => (props.fixedNavItem ? '#828282' : 'inherit')};
  transition: all 0.3s ease;
`;

const StyledLinks = styled.div<{
  fixedNavItem: boolean | undefined;
}>`
  position: absolute;
  width: ${props =>
    props.fixedNavItem ? 'calc(100% - 35px)' : 'calc(100% - 85px)'};
  right: ${props => (props.fixedNavItem ? '15px' : '40px')};
  bottom: 20px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-right: ${props => (props.fixedNavItem ? '15px' : '0')};

  button {
    padding: 0;
    font: inherit;
    background-color: transparent;
    border: none;
    text-shadow: none;
    font-size: 13px;
    line-height: 17px;
    text-decoration: underline;
    color: #828282;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      color: #333333;
      text-decoration: none;
    }
  }

  button {
    margin-left: ${props => (props.fixedNavItem ? '0' : '4px')};
  }

  &.archive {
    color: #828282;

    &:hover {
      color: #333333;
    }
  }
`;

interface PropsInterface {
  vacancyTitle: string;
  candidates: number;
  questionnaires: number;
  modifiedItem?: boolean;
  handleClick?: () => void;
  vacancy: Vacancy;
  setOpenedNav?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VacancyDisplayItem: React.FC<PropsInterface> = ({
  vacancyTitle,
  candidates,
  questionnaires,
  modifiedItem,
  handleClick,
  vacancy,
  setOpenedNav,
}) => {
  const history = useHistory();

  const handleCandidatesClick = () => {
    if (setOpenedNav) setOpenedNav(false);
    history.push(`/vacancies/${vacancy.id}/cvs`);
  };
  const handleQuestionnairesClick = () => {
    if (setOpenedNav) setOpenedNav(false);
    history.push(`/vacancies/${vacancy.id}/questionnaire`);
  };

  return (
    <StyledLi fixedNavItem={modifiedItem}>
      <StyledButton
        fixedNavItem={modifiedItem}
        onClick={handleClick}
        type="button"
      >
        <StyledLetter
          fixedNavItem={modifiedItem}
          className="vacancy-item__letter"
        >
          {vacancyTitle.slice(0, 1)}
        </StyledLetter>
        <StyledInfo fixedNavItem={modifiedItem}>
          <StyledTitle
            fixedNavItem={modifiedItem}
            className="vacancy-item__title"
          >
            {vacancyTitle}
          </StyledTitle>
        </StyledInfo>
      </StyledButton>
      <StyledLinks fixedNavItem={modifiedItem}>
        <button type="button" onClick={handleCandidatesClick}>
          {candidates} {wordDeclination(candidates, wordsDict.CANDIDATES_FORMS)}
        </button>
        <button type="button" onClick={handleQuestionnairesClick}>
          {questionnaires}{' '}
          {wordDeclination(questionnaires, wordsDict.QUESTIONNARIES_FORMS)}
        </button>
      </StyledLinks>
    </StyledLi>
  );
};

export default VacancyDisplayItem;
