import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import StyledLabel from './StyledLabel';
import {
  checkFileSize,
  getBase64,
  getPictureSize,
  checkDataType,
} from '../../../helpers/picture';

const StyledImageUploadComponent = styled.div`
  .preview {
    display: none;
    position: relative;
    width: 220px;
    height: 60px;
    box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.16);

    &.active {
      display: block;
    }
  }

  .preview img {
    width: 220px;
    height: 60px;
    display: block;
    border-radius: 3px;
  }

  .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    text-shadow: none;
    z-index: 1;
    transition: background-color 0.3s;
  }

  .delete-btn svg {
    cursor: pointer;
  }

  .btn-tooltip {
    display: block;
    opacity: 0;
    width: 94px;
    border-radius: 3px;
    position: absolute;
    left: 190px;
    bottom: calc(100% + 5px);
    background-color: rgba(51, 51, 51, 0.4);
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
    z-index 1;
    text-align: center;
    padding: 3px;
    transition: opacity 0.3s;
  }

  .delete-btn:hover,
  .delete-btn:focus {
    background-color: rgba(51, 51, 51, 0.4);
  }

  .delete-btn:hover + .btn-tooltip {
    opacity: 1;
  }

  .wrapper {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 10px;
    column-gap: 20px;
    margin-bottom: 10px;

    @media (min-width: 576px) {
      grid-template-columns: 220px 1fr;
    }
  }

  .wrapper-column {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 10px;
    column-gap: 20px;
    align-items: center;

    @media (min-width: 500px) {
      grid-template-columns: 220px 220px;
    }

    @media (min-width: 576px) {
      grid-template-columns: 1fr;
      row-gap: 24px;
    }
  }

  .info {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #828282;

    @media (min-width: 576px) {
      padding-top: 5px;
    }
  }

  .info-text {
    margin: 0;
  }

  .input-wrapper {
    padding: 3px 0;
    position: relative;
    width: 220px;
    height: 49px;
    padding: 15px 10px;
    background-color: #fedc2d;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;

    @media (min-width: 500px) {
      order: -1;
    }

    @media (min-width: 576px) {
      order: 0;
    }
  }

  .input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .text {
    position: relative;
    z-index: 0;
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  .input-wrapper:hover,
  .input-wrapper:focus-within {
    background-color: #ffe600;
  }

  .error {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    color: #ff6363;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
`;

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-x-lg"
    viewBox="0 0 16 16"
  >
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
  </svg>
);

interface PropsInterface {
  id: string;
  name: string;
  text: string;
  formats: string[];
  size: {
    width: number;
    height: number;
  };
  initialValue: string | false;
  setFieldValue: (
    field: string,
    value: boolean | { name: string; body: string },
    shouldValidate?: boolean | undefined,
  ) => void;
}

const StyledImageUpload: React.FC<PropsInterface> = ({
  id,
  name,
  text,
  formats,
  size,
  initialValue,
  setFieldValue,
}) => {
  const [formatError, setFormatError] = useState('');
  const [sizeError, setSizeError] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  const previewImg = useRef<HTMLImageElement | null>(null);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // сбрасываем ошибки и превью
    setFormatError('');
    setSizeError('');
    setShowPreview(false);
    if (previewImg && previewImg.current) previewImg.current.src = '';
    // проверка на существование файла
    if (!event.currentTarget.files || !event.currentTarget.files[0]) return;
    // проверка на тип файла
    const fileName = event.currentTarget.files[0].name;
    if (!checkDataType(fileName, formats)) {
      setFormatError('Выберите подходящий формат файла');
      setFieldValue('logo', false, false);
      return;
    }
    // формируем base64
    const base64String = await getBase64(event.currentTarget.files[0]);
    // определяем размер
    const pictureSize =
      typeof base64String === 'string'
        ? await getPictureSize(base64String)
        : {
            width: 0,
            height: 0,
          };
    // проверка на размер
    if (checkFileSize(pictureSize, size)) {
      setSizeError(
        `Размер файла должен быть равен ${size.width}px по ширине и ${size.height}px по высоте`,
      );
      setFieldValue('logo', false, false);
      return;
    }
    // выводим превью
    if (previewImg && previewImg.current) {
      setShowPreview(true);
      previewImg.current.src =
        typeof base64String === 'string' ? base64String : '';
    }
    // записываем значение
    if (typeof base64String === 'string') {
      setFieldValue('logo', { name: fileName, body: base64String }, false);
    }
  };

  const deleteBtnHandler = () => {
    setFieldValue('logo', false, false);
    setShowPreview(false);

    if (inputFile && inputFile.current) {
      inputFile.current.value = '';
    }
  };

  return (
    <StyledImageUploadComponent>
      <StyledLabel
        text={text}
        htmlFor={id}
        inlineStyles={{
          marginBottom: '5px',
        }}
      />
      <div className="wrapper">
        <div className="wrapper-column">
          <div
            className={
              showPreview || !!initialValue ? 'preview active' : 'preview'
            }
          >
            <img
              src={
                initialValue !== false
                  ? `${process.env.REACT_APP_API_URL}${initialValue}`
                  : ''
              }
              alt="Превью"
              width="220"
              height="60"
              ref={previewImg}
            />
            <button
              className="delete-btn"
              type="button"
              aria-label={`Удалить ${text}`}
              onClick={deleteBtnHandler}
            >
              {closeIcon}
            </button>
            <span className="btn-tooltip">Удалить {text.toLowerCase()}</span>
          </div>
          <div className="input-wrapper">
            <input
              className="input"
              id={id}
              name={name}
              type="file"
              accept="image/*"
              onChange={changeHandler}
              ref={inputFile}
            />
            <p className="text">
              {showPreview || !!initialValue
                ? `Заменить ${text.toLowerCase()}`
                : `Загрузить ${text.toLowerCase()}`}
            </p>
          </div>
        </div>

        <div className="info">
          <p className="info-text">
            Размеры файла: {size.width}px*{size.height}{' '}
          </p>
          <p className="info-text">
            Поддерживаемые форматы: {formats.join(', ')}.
          </p>
        </div>
      </div>

      {formatError !== '' && <p className="error">{formatError}</p>}

      {sizeError !== '' && <p className="error">{sizeError}</p>}
    </StyledImageUploadComponent>
  );
};

export default StyledImageUpload;
