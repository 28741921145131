import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Russian } from 'flatpickr/dist/l10n/ru';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Loader from 'react-loader-spinner';
import produce from 'immer';
import InputMask from 'react-input-mask';
import { CVI } from '../../../types/vacancies';
import { addNewCv } from '../../../store/actions/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import StyledLabel from '../../Template/StyledComponents/StyledLabel';
import StyledInput, {
  StyledInputDiv,
} from '../../Template/StyledComponents/StyledInput';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import closeIcon from '../../../assets/image/icon-close.svg';

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin: 0;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const StyledAddBtn = styled.button`
  display: block;
  width: 100%;
  background-color: #f3f3f7;
  border-radius: 5px;
  border: none;
  font: inherit;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 16px;
  text-align: center;
  transition: color 0.5s;

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.5);
  }

  svg {
    margin-right: 10px;
  }

  &.delete-btn {
    padding: 10px;
    max-width: 120px;
    margin-left: auto;
  }
`;

const StyledBtnWrap = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  justify-items: center;

  @media (min-width: 768px) {
    grid-row-gap: 30px;
  }
`;

const StyledRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 15px;
  margin-bottom: 20px;

  .delete-btn {
    grid-column: 1 / -1;
  }

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
  }
`;

enum Actions {
  DELETE = 'DELETE',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const VacancyCVsRow: React.FC<{
  cv: CVI;
  handleChangeState: (
    action: Actions,
    index: number,
    value?: string,
    name?: string,
  ) => void;
  index: number;
}> = ({ cv, handleChangeState, index }) => {
  const handleChangeStateRow = (e: {
    target: { name: string; value: string };
  }) => {
    handleChangeState(Actions.EDIT, index, e.target.value, e.target.name);
  };

  return (
    <>
      <div>
        <StyledLabel text="Имя" htmlFor="first_name" />
        <StyledInput
          id="first_name"
          type="text"
          name="first_name"
          handleChange={handleChangeStateRow}
          placeholder="Иван"
          input
        />
      </div>
      <div>
        <StyledLabel text="Фамилия" htmlFor="last_name" />
        <StyledInput
          id="last_name"
          type="text"
          name="last_name"
          handleChange={handleChangeStateRow}
          placeholder="Иванов"
          input
        />
      </div>
      <div>
        <StyledLabel text="E-mail" htmlFor="mail" />
        <StyledInput
          id="mail"
          type="text"
          name="email"
          placeholder="info@mebot24-hr.ru"
          handleChange={handleChangeStateRow}
          input
        />
      </div>
      <div>
        <StyledLabel text="Телефон" htmlFor="tel" />
        <StyledInputDiv>
          <InputMask
            id="tel"
            onChange={handleChangeStateRow}
            mask="+7\ (999) 999-99-99"
            placeholder="+7 (___) ___-__-__"
            name="phone"
          />
        </StyledInputDiv>
      </div>
      <div>
        <StyledLabel text="Дата рождения" htmlFor="eventDate" />
        <StyledInputDiv>
          <Flatpickr
            id="eventDate"
            name="year_of_birthday"
            onChange={(date, currentDateString) => {
              handleChangeStateRow({
                target: {
                  name: 'year_of_birthday',
                  value: moment(currentDateString, 'DD.MM.YYYY').format(
                    DateTimeFormats.DATE_FORMAT_API,
                  ),
                },
              });
            }}
            options={{
              locale: Russian,
              dateFormat: 'd.m.Y',
              mode: 'single',
              inline: false,
              defaultDate: cv.year_of_birthday,
              disableMobile: true,
            }}
          />
        </StyledInputDiv>
      </div>
      <div>
        <StyledLabel text="Ссылка на резюме" htmlFor="link" />
        <StyledInput
          id="link"
          type="text"
          name="url"
          handleChange={handleChangeStateRow}
          placeholder="https://rabota.ua"
          input
        />
      </div>
      <StyledAddBtn
        className="delete-btn"
        type="button"
        onClick={() => {
          handleChangeState(Actions.DELETE, index);
        }}
      >
        Удалить
      </StyledAddBtn>
    </>
  );
};

const VacancyCVsAdd: React.FC<{
  vacancyId: string;
  reFetchCVS: () => void;
  setModal: (e: boolean) => void;
}> = ({ vacancyId, setModal, reFetchCVS }) => {
  const [cv, setCv] = useState<CVI[]>([
    {
      vid: Math.random(),
      id: '',
      vacancy_id: vacancyId,
      first_name: '',
      last_name: '',
      url: '',
      year_of_birthday: moment()
        .subtract(18, 'years')
        .format(DateTimeFormats.DATE_FORMAT_API),
      phone: '',
    },
  ]);
  const [cvLoading, setCvLoading] = useState(false);

  const handleChangeState = (
    action: Actions,
    index?: number,
    value?: string,
    name?: string,
  ) => {
    setCv(
      produce(draft => {
        switch (action) {
          case Actions.EDIT: {
            if (index !== undefined && name) {
              draft[index][name] = value;
            }

            return draft;
          }
          case Actions.DELETE: {
            if (index !== undefined) {
              draft.splice(index, 1);
            }
            return draft;
          }
          case Actions.ADD: {
            draft.push({
              vid: Math.random(),
              id: '',
              vacancy_id: vacancyId,
              first_name: '',
              last_name: '',
              url: '',
              year_of_birthday: moment()
                .subtract(18, 'years')
                .format(DateTimeFormats.DATE_FORMAT_API),
              phone: '',
            });
            return draft;
          }

          default: {
            return draft;
          }
        }
      }),
    );
  };

  const handleAddNewCV = () => {
    setCvLoading(true);
    addNewCv(cv)
      .then(() => {
        setCvLoading(false);
        setModal(false);
        reFetchCVS();
      })
      .catch(error => {
        setCvLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(cv);
  }, [cv]);

  return (
    <>
      <StyledTitle>Добавить резюме</StyledTitle>
      <StyledRow>
        {cv.map((item, i) => (
          <VacancyCVsRow
            key={item.vid}
            cv={item}
            index={i}
            handleChangeState={handleChangeState}
          />
        ))}
      </StyledRow>
      <StyledBtnWrap>
        <StyledAddBtn
          className="addResume-newRow"
          type="button"
          onClick={() => {
            handleChangeState(Actions.ADD);
          }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="6.95674" cy="7" rx="6.95674" ry="7" fill="#CDCEDE" />
            <path
              d="M6.95639 5V9M8.94403 7L4.96875 7"
              stroke="#F3F3F7"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          Добавить еще одно
        </StyledAddBtn>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <StyledLink
          button
          type="button"
          handleСlick={handleAddNewCV}
          disabled={cvLoading}
          text="Добавить резюме"
          inlineStyles={{
            maxWidth: 362,
          }}
        >
          {cvLoading && (
            <Loader type="Audio" color="#000" height="16px" width="16px" />
          )}
        </StyledLink>
      </StyledBtnWrap>
    </>
  );
};

export default VacancyCVsAdd;
