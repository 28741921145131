import produce from 'immer';
import { MainPageActionTypes } from '../../types/mainPage';

const mainDataReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case MainPageActionTypes.FETCH_COMPANIES_HEAD: {
        draft.companies.companies = action.payload.result;
        draft.companies.loaded = true;
        return draft;
      }
      case MainPageActionTypes.FETCH_ACTIVE_COMPANY: {
        draft.companies.active = action.payload;
        return draft;
      }
      case MainPageActionTypes.SET_ACTIVE_COMPANY: {
        draft.companies.active = action.payload;
        return draft;
      }
      case MainPageActionTypes.FETCH_VACANCIES_LIST_MAIN: {
        draft.vacancies.loaded = true;
        draft.vacancies.totalResult = action.payload.totalResultCount;
        draft.vacancies.vacancies = action.payload.result;
        return draft;
      }
      case MainPageActionTypes.FETCH_DICTS: {
        draft.dictionaries = action.payload;
        return draft;
      }
      case MainPageActionTypes.FETCH_VACANCIES_LIST_NAV: {
        draft.vacanciesNav.totalResult = action.payload.totalResultCount;
        draft.vacanciesNav.vacancies = action.payload.result;
        return draft;
      }
      case MainPageActionTypes.CLEAR_VACANCIES_LIST_MAIN: {
        draft.vacancies.loaded = false;
        draft.vacancies.vacancies = [];
        return draft;
      }
      case MainPageActionTypes.FETCH_ERRORS_VERIFICATION: {
        //draft.auth.error = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    companies: {
      companies: [],
      loaded: false,
      active: 0,
    },
    vacancies: {
      vacancies: [],
      loaded: false,
      totalResult: '',
    },
    dictionaries: {},
    vacanciesNav: {
      vacancies: [],
      loaded: false,
      totalResult: '',
    },
  },
);
export default mainDataReducer;
