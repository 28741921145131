import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { CompanyStaff, StaticDataType } from '../../../../types/companies';
import { getRuleActions } from '../../../../helpers/viewComapny';

const StyledWrap = styled.div`
  @media (max-width: 991px) {
    border-radius: 11px;
    border: 1px solid #cdcede;

    .table-row-mob {
      padding: 10px;
      display: grid;
      grid-template-columns: 45% 50%;
      background: #ededf3;
      color: #828282;
    }

    .table-row-mob p {
      margin: 0;
    }
  }

  @media (min-width: 992px) {
    .table-row-mob {
      display: none;
    }
  }
`;

const StyledRow = styled.div`
  transition: color 0.3s;

  path {
    transition: fill 0.3s;
  }

  .cell-label-mob {
    font-size: 11px;
    line-height: 18px;
    color: #828282;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .icon-button {
    background-color: transparent;
    border: none;
    padding: 0 10px;
  }

  &:hover,
  &:focus {
    color: #000000;

    path {
      fill: #828282;
    }
  }
`;

export interface StaffProps {
  staff: CompanyStaff;
  handleEditStaff: (value: string) => void;
  handleDeleteStaff: (value: string) => void;
  staticData: StaticDataType;
}

const ViewCompanyCard: React.FC<StaffProps> = ({
  staff,
  handleEditStaff,
  handleDeleteStaff,
  staticData,
}) => {
  const staffVacancy = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_vacancy),
    [staticData.ruleAction, staff.rule_interview],
  );
  const staffInterview = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_vacancy),
    [staticData.ruleAction, staff.rule_vacancy],
  );
  const staffType = useMemo(
    () => getRuleActions(staticData.ruleList, staff.type),
    [staticData.ruleList, staff.type],
  );
  const staffResume = useMemo(
    () => getRuleActions(staticData.ruleAction, staff.rule_resume),
    [staticData.ruleAction, staff.rule_resume],
  );

  return (
    <StyledWrap>
      <div className="table-row-mob">
        <p>ФИО, роль</p>
        <p>Доступ</p>
      </div>
      <StyledRow className="table-row">
        <p className="cell cell-name">
          {staff.name} {staff.last_name}
        </p>
        <p className="cell cell-type">{staffType}</p>
        <div className="cell cell-vacancy">
          <span className="cell-label-mob">Вакансии</span>
          <p>{staffVacancy}</p>
        </div>
        <div className="cell cell-ankets">
          <span className="cell-label-mob">Анкеты</span>
          <p>{staffInterview}</p>
        </div>
        <div className="cell cell-interview">
          <span className="cell-label-mob">Собеседования</span>
          <p>{staffResume}</p>
        </div>
        <p className="cell cell-last">
          <button
            className="icon-button"
            type="button"
            onClick={() => handleEditStaff(staff.user_id)}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8147 3.17086L15.6797 7.05478L5.89624 16.8861L2.0334 13.0022L11.8147 3.17086ZM18.6125 2.23414L16.8889 0.502046C16.2227 -0.167349 15.1411 -0.167349 14.4727 0.502046L12.8216 2.16122L16.6866 6.04518L18.6125 4.10984C19.1292 3.59062 19.1292 2.75333 18.6125 2.23414ZM0.0107555 18.4614C-0.0595831 18.7795 0.226226 19.0645 0.542821 18.9872L4.84975 17.9378L0.98691 14.0539L0.0107555 18.4614Z"
                fill="#CDCEDE"
              />
            </svg>
          </button>
          <button
            className="icon-button"
            type="button"
            onClick={() => handleDeleteStaff(staff.user_id)}
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.999993 14C0.999993 15.1025 1.89695 16 2.99998 16H11C12.103 16 13 15.1025 13 14V4.00001H0.999993V14Z"
                fill="#CDCEDE"
              />
              <path
                d="M9 0.999994V0H5V0.999994H0V2.99998H14V0.999994H9Z"
                fill="#CDCEDE"
              />
            </svg>
          </button>
        </p>
      </StyledRow>
    </StyledWrap>
  );
};
export default ViewCompanyCard;
