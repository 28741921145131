import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  display: flex;
  margin-top: 26px;
  align-items: center;
  & > span {
    margin-right: 18px;
    img {
      width: 14px;
      height: 14px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledSpan = styled.span`
  margin-left: 16px;
  width: 21px;
  height: 21px;
  background: #cdcede;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: #ff7557;
  }
`;

interface PropsInterface {
  icon: string;
  text: string;
  activeNumber?: number;
  href: string;
  active: boolean;
  handleClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const MobileLink: React.FunctionComponent<PropsInterface> = ({
  icon,
  text,
  activeNumber,
  active = false,
  href,
  handleClick,
}) => {
  return (
    <StyledLink to={href} onClick={handleClick}>
      <span>
        <img src={icon} alt="#" />
      </span>
      <div>{text}</div>
      {activeNumber && (
        <StyledSpan className={active ? 'active' : ''}>
          {activeNumber}
        </StyledSpan>
      )}
    </StyledLink>
  );
};

export default MobileLink;
