import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledLabel from '../../Template/StyledComponents/StyledLabel';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';

import {
  StyledAddForm,
  StyledWrap,
  StyledItemWrap,
  StyledBlocks,
  StyledColorBlock,
  StyledCompanyBlocks,
  StyledCompanyBlock,
  StyledButtonWrap,
  StyledErrorMessageWrap,
  StyledUploadWrap,
} from '../StyledFormComponents/StyledFormComponents';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledSelectAutoComplete from '../../Template/StyledComponents/StyledSelectAutoComplete';
import StyledDropdown from '../../Template/StyledComponents/StyledDropdown';
import { CompanyColorsType, MyFormValues } from '../../../types/companies';
import StyledImageUpload from '../../Template/StyledComponents/StyledImageUpload';
import { FILE_TYPES } from '../../../helpers/picture';
import { colorsData, companiesBg } from './data';

interface PropsType {
  action: 'add' | 'edit';
}

interface Params {
  id: string;
}

const CompanyForm: React.FunctionComponent<PropsType> = ({ action }) => {
  const {
    setCompanyEdit,
    setCompanyActive,
    fetchCompanyCities,
    fetchCompanies,
    fetchCompanyEdit,
    resetCompanySuccess,
    addCompany,
  } = useActions();
  const { dictionaries } = useTypedSelector(r => r.mainData);
  const { setSuccess, companyCity, editCompanyData } = useTypedSelector(
    r => r.companies,
  );
  const history = useHistory();
  const params: Params = useParams();
  const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

  useEffect(() => {
    fetchCompanyCities();
  }, []);

  useEffect(() => {
    if (action === 'edit') setCompanyActive(params.id);
  }, []);

  useEffect(() => {
    if (action === 'edit') {
      fetchCompanyEdit(params.id);
    } else {
      resetCompanySuccess();
    }
  }, [params.id]);

  useEffect(() => {
    if (setSuccess) history.push('/companies/');
  }, [setSuccess]);

  const [colors, setColors] = useState<string>('1');

  const [images, setImages] = useState<string>();

  const bg = dictionaries.companyColors
    ?.map((n: CompanyColorsType) => {
      return String(n.id) === String(colors) ? n.color : '';
    })
    .join('');

  useEffect(() => {
    setColors(String(editCompanyData.color));
    setImages(String(editCompanyData.image));
  }, [editCompanyData]);

  const initialValues: MyFormValues = { ...editCompanyData };

  const checkVideoLogoData = (formValues: MyFormValues) => {
    if (
      typeof formValues.logo === 'string' &&
      typeof formValues.video !== 'string'
    ) {
      const { logo, ...valuesWithoutLogo } = formValues;
      return Object.assign(valuesWithoutLogo);
    }
    if (
      typeof formValues.video === 'string' &&
      typeof formValues.logo !== 'string'
    ) {
      const { video, ...valuesWithoutVideo } = formValues;
      return Object.assign(valuesWithoutVideo);
    }
    if (
      typeof formValues.video === 'string' &&
      typeof formValues.logo === 'string'
    ) {
      const { logo, video, ...valuesWithoutVideoLogo } = formValues;
      return Object.assign(valuesWithoutVideoLogo);
    }
    return formValues;
  };

  return (
    <StyledAddForm className="add-form">
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Вы не заполнили поле'),
          city: Yup.string().required('Вы не заполнили поле'),
          time_zone: Yup.string().required('Вы не заполнили поле'),
          address: Yup.string().required('Вы не заполнили поле'),
          email: Yup.string()
            .email('Введите корректный email')
            .required('Вы не заполнили поле'),
          phone: Yup.string()
            .nullable()
            .required('Вы не заполнили поле')
            .matches(phoneRegExp, 'Введите валидный номер телефона'),
          description: Yup.string().required('Вы не заполнили поле'),
          image: Yup.string().required('Выберите изображение компании'),
        })}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          const newVal = Object.assign(checkVideoLogoData(values), {
            image: images,
            color: colors,
          });

          if (action === 'add') {
            addCompany(newVal);
          } else if (action === 'edit') {
            setCompanyEdit(newVal);
            fetchCompanies();
          }
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          errors,
          resetForm,
          touched,
        }) => (
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <StyledItemWrap>
                    <StyledLabel
                      text="Название компании"
                      htmlFor="company-name"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      type="text"
                      name="name"
                      id="company-name"
                      placeholder="ООО «Рога и копыта»"
                    />
                    <StyledErrorMessageWrap>
                      <ErrorMessage name="name" />
                    </StyledErrorMessageWrap>
                  </StyledItemWrap>

                  <StyledWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Город"
                        htmlFor="city"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledSelectAutoComplete
                        initialValue={values.city}
                        name="city"
                        setFieldValue={setFieldValue}
                        array={companyCity}
                      />
                      <StyledErrorMessageWrap>
                        <ErrorMessage name="city" />
                      </StyledErrorMessageWrap>
                    </StyledItemWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Часовой пояс"
                        htmlFor="time_zone"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledDropdown
                        initialValue={values.time_zone}
                        name="time_zone"
                        setFieldValue={setFieldValue}
                        array={dictionaries.companyTimezon}
                      />
                      <StyledErrorMessageWrap>
                        <ErrorMessage name="time_zone" />
                      </StyledErrorMessageWrap>
                    </StyledItemWrap>
                  </StyledWrap>

                  <StyledItemWrap>
                    <StyledLabel
                      text="Адрес"
                      htmlFor="address"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      name="address"
                      type="text"
                      id="address"
                      placeholder="г. Москва, ул. Барклая, д 6, стр 5, офис 328"
                    />
                    <StyledErrorMessageWrap>
                      {errors.address && touched.address && errors.address}
                    </StyledErrorMessageWrap>
                  </StyledItemWrap>

                  <StyledWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="E-mail"
                        htmlFor="mail"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledInput
                        field
                        name="email"
                        type="email"
                        id="mail"
                        placeholder="info@mebot24-hr.ru"
                      />
                      <StyledErrorMessageWrap>
                        {errors.email && touched.email && errors.email}
                      </StyledErrorMessageWrap>
                    </StyledItemWrap>
                    <StyledItemWrap>
                      <StyledLabel
                        text="Телефон"
                        htmlFor="phone"
                        inlineStyles={{
                          marginBottom: '16px',
                        }}
                      />
                      <StyledInput
                        field
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="+7 (988) 456-45-56"
                      />
                      <StyledErrorMessageWrap>
                        {errors.phone && touched.phone && errors.phone}
                      </StyledErrorMessageWrap>
                    </StyledItemWrap>
                  </StyledWrap>
                  <StyledItemWrap>
                    <StyledLabel
                      text="Описание компании"
                      htmlFor="description"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                    />
                    <StyledInput
                      field
                      inlineStyles={{
                        minHeight: '218px',
                      }}
                      textareaField
                      name="description"
                      placeholder="Мы дизайн-студия. Создаем сайты и приложения."
                    />
                    <StyledErrorMessageWrap>
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </StyledErrorMessageWrap>
                  </StyledItemWrap>
                </div>
                <div className="col-lg-6">
                  <StyledUploadWrap>
                    <StyledImageUpload
                      id="logo"
                      name="logo"
                      text="Логотип"
                      size={{ width: 220, height: 60 }}
                      formats={FILE_TYPES}
                      initialValue={
                        typeof values.logo === 'string' ? values.logo : false
                      }
                      setFieldValue={setFieldValue}
                    />
                  </StyledUploadWrap>
                  <StyledLabel
                    text="Выберите цвет вашей компании"
                    inlineStyles={{
                      marginBottom: '16px',
                    }}
                    paragraph
                  />
                  <StyledBlocks>
                    {colorsData.map(item => {
                      return (
                        <StyledColorBlock
                          onClick={() => setColors(item.index)}
                          style={{ backgroundColor: bg as string }}
                          className={colors === item.index ? ' active' : ''}
                          type="button"
                          aria-label={item.color}
                          key={item.color}
                        >
                          <span className={item.color}></span>
                        </StyledColorBlock>
                      );
                    })}
                  </StyledBlocks>
                  <StyledItemWrap>
                    <StyledLabel
                      text="Выберите фоновое изображение вашей компании"
                      inlineStyles={{
                        marginBottom: '16px',
                      }}
                      paragraph
                    />
                    <StyledCompanyBlocks>
                      {companiesBg.map(company => {
                        return (
                          <StyledCompanyBlock
                            type="button"
                            onClick={() => setImages(company.index)}
                            className={
                              images === company.index ? ' active' : ''
                            }
                            key={company.image}
                          >
                            <div
                              className="wrapper"
                              style={{ backgroundColor: bg as string }}
                            >
                              <img src={company.image} alt="#" />
                            </div>
                          </StyledCompanyBlock>
                        );
                      })}
                    </StyledCompanyBlocks>
                    <StyledErrorMessageWrap>
                      {errors.image && touched.image && errors.image}
                    </StyledErrorMessageWrap>
                  </StyledItemWrap>
                </div>
              </div>
              <StyledButtonWrap style={{ opacity: isSubmitting ? 0.5 : 1 }}>
                <StyledLink
                  text="Сохранить изменения"
                  inlineStyles={{
                    height: '50px',
                  }}
                  type="submit"
                  button
                  disabled={isSubmitting}
                ></StyledLink>
                {isSubmitting && (
                  <Loader
                    type="Audio"
                    color="#000"
                    height="16px"
                    width="16px"
                  />
                )}
              </StyledButtonWrap>
            </div>
          </Form>
        )}
      </Formik>
    </StyledAddForm>
  );
};

export default CompanyForm;
