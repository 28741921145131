import React, { SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import useTypedSelector from '../../../hooks/useTypedSelector';
import VacancyCVsTableRow from './VacancyCVsTableRow';
import { CVS } from '../../../types/vacancies';
import VacancyCVsInvite from './VacancyCVsInvite';
import Loader from '../../Template/Loader/Loader';
import DataStatus from '../../Common/DataStatus/DataStatus';
import Pagination from '../../Common/pagination';

const StyledTotalCount = styled.div`
  margin-bottom: 16px;

  span {
    margin-right: 4px;
  }

  @media (max-width: 991px) {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
  }
`;

const StyledTable = styled.div`
  position: relative;
  overflow-x: auto;
  margin-bottom: 30px;
`;

const StyledTableWrapper = styled.div`
  min-width: 720px;
  border-radius: 12px 12px 12px 12px;
`;

const StyledTableHead = styled.div`
  width: 100%;
  padding: 0 270px 0 40px;
  border-radius: 12px 12px 0px 0px;
  background: #f2f2f2;

  span {
    display: block;
    padding: 8px 0;
    color: #828282;
  }

  @media (max-width: 1200px) {
    padding-right: 200px;
  }

  @media (max-width: 991px) {
    padding: 0 15px;

    span {
      font-size: 11px;
      line-height: 20px;
    }
  }
`;

const StyledTableHeadWrap = styled.div`
  display: grid;
  grid-template-columns: 40% 15% 18% 15% 12%;
  align-items: center;

  @media (max-width: 991px) {
    grid-template-columns: 25% 18% 22% 20% 15%;
  }
`;

const StyledTableList = styled.ul`
  margin: 0;
  padding: 10px 40px 25px;
  border-radius: 0px 0px 12px 12px;
  list-style-type: none;
  border: 1px solid #cdcede;
  border-top: 0px;

  @media (max-width: 991px) {
    box-shadow: 0px 4px 20px rgb(56 56 81 / 17%);
    border: none;
    padding: 0 0 20px;
  }
`;

interface PropsInterface {
  page: number;
  setPage: (value: number | ((prevVar: number) => number)) => void;
  isCvsLoading: boolean;
  cvsError: boolean;
}

const VacancyCVsTable: React.FC<PropsInterface> = ({
  page = 0,
  setPage,
  isCvsLoading,
  cvsError,
}) => {
  const { cvs } = useTypedSelector(r => r.vacancies.vacancy);
  const [modal, setModal] = useState(false);
  const [inv, setInv] = useState({
    company_id: '',
    first_name: '',
    last_name: '',
    year_of_birthday: '',
    date_create: '',
    file_id: '',
    id: '',
    last_update: '',
    seeker_id: '',
    url: '',
    vacancy_id: '',
  });
  const limit = 10;

  const handleModal = (item: CVS) => {
    setInv(item);
    setModal(true);
  };

  const handleChangeCount = (value: number) => {
    setPage((prev: any) => {
      switch (value) {
        case 1:
          if (prev + 1 === Math.ceil(Number(cvs.count) / limit)) {
            return prev;
          }
          return prev + 1;
        case -1:
          if (prev === 0) {
            return prev;
          }
          return prev - 1;
        default:
          return prev;
      }
    });
  };

  return (
    <DataStatus loading={isCvsLoading} error={cvsError} items={cvs.cvs}>
      <Modal
        size="xl"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        className="modal fade addResume"
      >
        <Modal.Body className="big">
          {inv && <VacancyCVsInvite item={inv} handleModal={setModal} />}
        </Modal.Body>
      </Modal>
      <div className="container">
        <StyledTotalCount>
          Всего: <span>{cvs.count} </span>резюме
        </StyledTotalCount>
        <StyledTable>
          <StyledTableWrapper>
            <StyledTableHead>
              <StyledTableHeadWrap>
                <span>ФИО</span>
                <span>Возраст</span>
                <span>Добавлено</span>
                <span>Изменено</span>
                <span>Ссылка</span>
              </StyledTableHeadWrap>
            </StyledTableHead>
            <StyledTableList>
              {cvs.cvs.map((item: CVS) => (
                <VacancyCVsTableRow
                  item={item}
                  key={item.id}
                  handleModal={handleModal}
                />
              ))}
            </StyledTableList>
          </StyledTableWrapper>
        </StyledTable>
        {Math.ceil(Number(cvs.count) / limit) > 1 && (
          <Pagination
            companiesPage={Math.ceil(Number(cvs.count) / limit)}
            count={page}
            handleChangeCount={handleChangeCount}
          />
        )}
      </div>
    </DataStatus>
  );
};
export default VacancyCVsTable;
