import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';
import StyledInput from './StyledInput';
import { CompanyTomeZone } from '../../../types/companies';

const StyledDropdownComponent = styled.div`
  position: absolute;
  padding: 5px 0;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 280px;
  overflow-y: auto;
  border-bottom-left-radius: 10px;
  border-bottom-rught-radius: 10px;
  background-color: #ffffff;
  filter: drop-shadow(0px 0.99866px 9.9866px rgba(2, 1, 72, 0.16));
  z-index: 2;

  button {
    display: block;
    width: 100%;
    text-align: left;
    background-color: transparent;
    text-shadow: none;
    border: none;
    padding: 5px 15px;

    &:hover,
    &:focus {
      background-color: #f3f3f7;
    }
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.03);
  left: 0;
  top: 0;
  z-index: 1;
`;

interface PropsInterface {
  array: [];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  name: string;
  initialValue: number | string;
}

const StyledDropdown: React.FC<PropsInterface> = ({
  array,
  setFieldValue,
  name,
  initialValue,
}) => {
  const location = useLocation();
  const nameV =
    array && array.filter((r: CompanyTomeZone) => r.VALUE === initialValue);
  const nameVAct: CompanyTomeZone = nameV && nameV[0];
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | number>('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (initialValue && nameVAct) {
      setInputValue(`GMT+${initialValue} ${array && nameVAct.TITLE}`);
    }
  }, [nameVAct]);

  const handleInputFocus = () => {
    setDropdown(true);
  };
  useEffect(() => {
    if (location.pathname === '/companies/add/') {
      setInputValue('');
    }
  }, [location.pathname]);
  const handleButtonClick = (item: string, nameValue: string) => {
    setFieldValue(name, String(item));
    setInputValue(`GMT+${item} ${nameValue}`);
    setDropdown(false);
  };

  return (
    <>
      <StyledInput
        name={name}
        additionalClass={dropdown ? 'active' : ''}
        handleFocus={handleInputFocus}
        readonlyFlag
        value={inputValue}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputChange(e);
        }}
        autoComplete="off"
        $arrow
      />
      {dropdown && (
        <>
          <StyledDropdownComponent>
            {array.map((r: CompanyTomeZone) => {
              return (
                <button
                  key={r.VALUE}
                  data-value={r.VALUE}
                  type="button"
                  onClick={() => handleButtonClick(r.VALUE, r.TITLE)}
                >
                  GMT+{r.VALUE} {r.TITLE}
                </button>
              );
            })}
          </StyledDropdownComponent>
          <StyledOverlay onClick={() => setDropdown(false)} />
        </>
      )}
    </>
  );
};

export default StyledDropdown;
