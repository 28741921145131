import produce from 'immer';
import { CompaniesActionTypes, CompanyStaff } from '../../types/companies';

const companiesReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case CompaniesActionTypes.FETCH_COMPANIES: {
        draft.companiesPage.companiesPage = action.payload.result;
        draft.companiesPage.totalCount = action.payload.totalResultCount;
        return draft;
      }
      case CompaniesActionTypes.FETCH_COMPANIES_STAFF: {
        draft.staff = action.payload;
        return draft;
      }
      case CompaniesActionTypes.SET_COMPANY_STAFF_CHANGE: {
        draft.staffChange =
          (action.payload === 'OK' || action.payload.company_id) && new Date();
        return draft;
      }
      case CompaniesActionTypes.RESET_COMPANY_STAFF: {
        draft.staffId = null;
        return draft;
      }
      case CompaniesActionTypes.FETCH_COMPANY_STAFF_ID: {
        const id = action.payload;

        const staffEdit = draft.staff.filter(
          (n: CompanyStaff) => n.user_id === id,
        );
        const currentStaff = staffEdit[0];
        draft.staffId = currentStaff;
        return draft;
      }
      case CompaniesActionTypes.FETCH_COMPANY_EDIT: {
        draft.editCompanyData = action.payload;
        return draft;
      }
      case CompaniesActionTypes.SET_COMPANY_SUCCESS: {
        draft.setSuccess = true;
        return draft;
      }
      case CompaniesActionTypes.RESET_COMPANY_SUCCESS: {
        draft.setSuccess = false;
        draft.editCompanyData = {
          name: '',
          city: '',
          time_zone: '',
          address: '',
          email: '',
          phone: '',
          description: '',
          color: '1',
          image: '',
          logo: false,
          video: false,
        };
        return draft;
      }
      case CompaniesActionTypes.FETCH_COMPANY_CITY: {
        draft.companyCity = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  },
  {
    companiesPage: {
      companiesPage: [],
      totalCount: '',
    },
    vacancy: [],
    staff: [],
    staffId: null,
    staffChange: '',
    editCompanyData: {
      name: '',
      city: '',
      time_zone: '',
      address: '',
      email: '',
      phone: '',
      description: '',
      color: '1',
      image: '',
      logo: false,
      video: false,
    },
    setSuccess: false,
    companyCity: [],
  },
);
export default companiesReducer;
