import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import CarouselActionsItem from './CarouseActionslItem';
import CarouselImportantItem from './CarouselImportantItem';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'owl.carousel/dist/assets/owl.carousel.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'owl.carousel/dist/assets/owl.theme.default.css';
import bg1 from '../../../assets/image/important-slider-item-bg-1-2.png';
import bg2 from '../../../assets/image/important-slider-item-bg-2-2.png';
import bg3 from '../../../assets/image/important-slider-item-bg-3-2.png';
import bg4 from '../../../assets/image/important-slider-item-bg-4-2.png';
import acsl1 from '../../../assets/image/actions-slider-item-icon-1.svg';
import acsl2 from '../../../assets/image/actions-slider-item-icon-2.svg';
import acsl3 from '../../../assets/image/actions-slider-item-icon-3.svg';
import acsl4 from '../../../assets/image/actions-slider-item-icon-4.svg';
import './Carousel.scss';

const carouselPropsImportant = {
  autoWidth: true,
  margin: 25,
  items: 3,
  navText: [
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1C4.68 1.32 2.2 3.8 1 5L5 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1C1.32 1.32 3.8 3.8 5 5L1 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
  ],
  responsive: {
    0: {
      nav: false,
      dots: false,
      margin: 15,
    },
    576: {
      margin: 25,
    },
    768: {
      nav: true,
      dots: true,
    },
  },
};
const carouselPropsActions = {
  nav: true,
  dots: false,
  margin: 30,
  items: 3,
  navText: [
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1C4.68 1.32 2.2 3.8 1 5L5 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
    "<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1C1.32 1.32 3.8 3.8 5 5L1 9' stroke='#828282' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    768: {
      items: 3,
    },
  },
};

const actionsData = [
  {
    icon: acsl1,
    title: 'Резюме',
    description: 'Получите новый пакет резюме.',
    count: 1000,
  },
  {
    icon: acsl2,
    title: 'Кандидаты',
    description: 'Провести процесс привлечения кандидатов.',
    count: 420,
  },
  {
    icon: acsl3,
    title: 'Тестирование',
    description: 'Тестирование кандидата на псих. и проф. критерии.',
    count: 2,
  },
  {
    icon: acsl4,
    title: 'Видео-интервью',
    description: 'Автоматическое тестирование человека.',
    count: 12,
  },
];

const importantData = [
  {
    bg: bg1,
    color: '#26cc6d',
    number: 14,
    text: 'Приступили к тестовому заданию',
  },
  {
    bg: bg2,
    color: '#159dff',
    number: 14,
    text: 'Приступили к тестовому заданию',
  },
  {
    bg: bg3,
    color: '#7432b2',
    number: 14,
    text: 'Приступили к тестовому заданию',
  },
  {
    bg: bg4,
    color: '#ffbe2e',
    number: 14,
    text: 'Приступили к тестовому заданию',
  },
];

interface Props {
  type: string;
}

const Carousel: React.FunctionComponent<Props> = ({ type }) => {
  switch (type) {
    case 'actions': {
      return (
        <OwlCarousel
          className="actions-slider owl-theme"
          {...carouselPropsActions}
        >
          {actionsData.map(data => {
            return (
              <CarouselActionsItem
                key={data.title}
                icon={data.icon}
                title={data.title}
                desc={data.description}
                count={data.count}
              />
            );
          })}
        </OwlCarousel>
      );
    }
    case 'important': {
      return (
        <OwlCarousel
          className="owl-theme important-slider owl-carousel"
          {...carouselPropsImportant}
        >
          {importantData.map(data => {
            return (
              <CarouselImportantItem
                key={data.text}
                icon={data.bg}
                color={data.color}
                text={data.text}
                count={data.number}
              />
            );
          })}
        </OwlCarousel>
      );
    }
    default: {
      return <div>123</div>;
    }
  }
};

export default Carousel;
