import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  StyledTitle,
  StyledCloseBtn,
  StyledText,
  StyledSubmitBtnWrap,
  StyledLoginText,
  StyledFormSection,
} from '../StyledLoginComponents/StyledLoginComponents';
import PasswordInput from '../PasswordInput/PasswordInput';
import StyledLabel from '../../Template/StyledComponents/StyledLabel';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import { phoneRegExp } from '../../Common/Data';
import { ILoginProps } from '../../../types/mainPage';

const StyledForm = styled.form`
  margin-top: 30px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const StyledFlexWrap = styled.div`
  display: grid;
  margin-bottom: 15px;
  grid-row-gap: 15px;

  &:last-of-type {
    margin-bottom: 24px;
  }

  .password-text {
    font-size: 10px;
    line-height: 12px;
    color: #828282;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 35px;
    margin-bottom: 21px;

    &:last-of-type {
      margin-bottom: 48px;
    }
  }

  @media (max-width: 991px) {
    & input {
      height: 42px;
    }

    & label {
      margin-bottom: 7px;
      font-size: 13px;
    }
  }
`;

const StyledResultText = styled.p<{
  success?: boolean;
}>`
  margin: 0;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  color: ${props => (props.success ? '#27ae60' : '#ff6363')};

  svg {
    margin-right: 10px;
  }
`;

const successIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#27AE60" />
    <path
      d="M4 6.09524L5.92308 8L9 4"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const errorIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#FF6363" />
    <path
      d="M5.68701 2.56787L5.81592 7.76709H7.16943L7.29834 2.56787H5.68701ZM6.49268 10.4526C6.98145 10.4526 7.36816 10.082 7.36816 9.60938C7.36816 9.13672 6.98145 8.76611 6.49268 8.76611C6.00391 8.76611 5.61719 9.13672 5.61719 9.60938C5.61719 10.082 6.00391 10.4526 6.49268 10.4526Z"
      fill="white"
    />
  </svg>
);

const closeFormIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03127 19.0943L19.094 7.03154M19.0939 19.0942L7.03111 7.03139"
      stroke="#4F4F4F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const getValidation = (
  val: boolean,
  submit: boolean,
): JSX.Element | string => {
  /*if (val && !submit) {
    return (
      <StyledResultText success>
        {successIcon}
        <span>Поля заполнены верно</span>
      </StyledResultText>
    );
  }
  if (!submit) {
    return (
      <StyledResultText>
        {errorIcon}
        <span>Заполните все поля.</span>
      </StyledResultText>
    );
  }*/
  return '';
};

const RegistrationForm: React.FunctionComponent<ILoginProps> = ({
  setAuth,
}) => {
  const handleCLickLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setAuth(false);
  };
  return (
    <>
      <div className="container">
        <StyledFormSection>
          <Formik
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Вы не заполнили поле'),
              surname: Yup.string().required('Вы не заполнили поле'),
              email: Yup.string().required('Вы не заполнили поле'),
              password: Yup.string().required('Вы не заполнили поле'),
              phone: Yup.string()
                .nullable()
                .required('Вы не заполнили поле')
                .matches(phoneRegExp, 'Введите валидный номер телефона'),
            })}
            enableReinitialize
            onSubmit={(values: any) => {
              console.log(values);
            }}
            initialValues={{
              name: '',
              surname: '',
              phone: '',
              email: '',
              password: '',
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <StyledTitle>Регистрация</StyledTitle>
                <StyledText>Заполните необходимые поля данными</StyledText>
                <StyledFlexWrap>
                  <div>
                    <StyledLabel htmlFor="login-name" text="Имя" />
                    <StyledInput
                      field
                      id="login-name"
                      type="text"
                      name="name"
                      placeholder="Александр"
                    />
                  </div>
                  <div>
                    <StyledLabel htmlFor="login-surname" text="Фамилия" />
                    <StyledInput
                      id="login-surname"
                      type="text"
                      field
                      name="surname"
                      placeholder="Филиппов"
                    />
                  </div>
                </StyledFlexWrap>
                <StyledFlexWrap>
                  <div>
                    <StyledLabel htmlFor="login-tel" text="Номер телефона" />
                    <StyledInput
                      field
                      id="login-tel"
                      type="text"
                      name="phone"
                      placeholder="+7 (___) ___-__-__"
                    />
                  </div>
                  <div>
                    <StyledLabel htmlFor="login-email" text="E-mail" />
                    <StyledInput
                      type="email"
                      field
                      id="login-email"
                      name="email"
                      placeholder="info@mebot24-hr.ru"
                    />
                  </div>
                </StyledFlexWrap>
                <StyledFlexWrap>
                  <div className="password-wrapper">
                    <StyledLabel htmlFor="login-password" text="Пароль" />
                    <PasswordInput id="login-password" />
                    <span className="password-text">
                      * только латиница и цифры, не менее 8 символов
                    </span>
                  </div>
                </StyledFlexWrap>
                {getValidation(
                  !Object.keys(errors).length,
                  !Object.keys(touched).length,
                )}
                <StyledSubmitBtnWrap>
                  <StyledLink type="submit" text="Зарегистрироваться" button />
                </StyledSubmitBtnWrap>
                <StyledLoginText>
                  Уже есть аккаунт?{' '}
                  <Link
                    to="/"
                    onClick={(
                      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                    ) => handleCLickLink(e)}
                  >
                    Войти
                  </Link>
                </StyledLoginText>
                <StyledCloseBtn type="button">{closeFormIcon}</StyledCloseBtn>
              </Form>
            )}
          </Formik>
        </StyledFormSection>
      </div>
    </>
  );
};
export default RegistrationForm;
