import styled from 'styled-components';

export const StyledCard = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 10px;

  @media (min-width: 768px) {
    grid-row-gap: 20px;
  }
`;

export const StyledButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
  box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
  border-radius: 5px;
  font: inherit;
  text-shadow: none;
  transition: box-shadow 0.5s;
  padding: 12px 26px 12px 12px;

  &:hover,
  &:focus {
    box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.3);
  }

  @media (min-width: 768px) {
    padding: 10px 26px 10px 23px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 70px;
  }

  .arrow {
    display: none;
    transition: transform 0.3s;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      width: 21px;
      height: 21px;
      top: 50%;
      margin-top: -10px;
      right: 26px;
    }
  }
`;

export const StyledNameWrap = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    flex-shrink: 0;
    margin-right: 15px;
  }
`;

export const StyledAttr = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  background-color: #fef2b3;
  border-radius: 50%;
  margin-right: 10px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 17px;
    width: 36px;
    height: 36px;
    margin-right: 26px;
  }
`;

export const StyledName = styled.h3`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 29px;
  }
`;

export const StyledStats = styled.dl`
  display: flex;
  flex-direction: column-reverse;
  padding-left: 40px;
  margin: 0;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    flex-grow: 1;
    justify-content: end;
  }

  .wrap {
    display: flex;
    align-items: baseline;

    @media (min-width: 768px) {
      &:nth-child(1) {
        margin-right: 32px;
      }
    }
  }

  dt {
    width: 114px;
    font-size: 12px;
    line-height: 13px;
    color: #828282;
    margin-right: 8px;
    font-weight: 400;
    text-align: left;

    @media (min-width: 768px) {
      width: auto;
    }
  }

  dd {
    background-color: #ff6363;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 11px;
    line-height: 20px;
    min-width: 85px;
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 6px;
    height: 22px;

    &.checked {
      background-color: #27ae60;
    }

    &.status {
      min-width: 47px;
      text-transform: uppercase;

      &.accepted {
        background-color: #27ae60;
      }

      &.rejected {
        background-color: #ff6363;
      }
    }

    svg {
      margin-right: 6px;
    }

    @media (max-width: 440px) {
      font-size: 10px;
    }
  }
`;

export const StyledContent = styled.div`
  display: none;
  background-color: #f3f3f7;
  border: 1px solid #cdcede;
  border-radius: 11px;

  &.show {
    display: block;
  }

  @media (min-width: 992px) {
    &.show {
      display: grid;
      grid-template-columns: 245px 1fr;
    }
  }
`;

export const StyledContentCard = styled.div`
  background-color: #ffffff;
  border-radius: 11px;
  padding: 16px;
  padding-bottom: 30px;

  @media (min-width: 992px) {
    padding: 40px;
  }

  .name-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    @media (min-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      margin-left: 60px;
      align-items: start;
    }

    @media (min-width: 992px) {
      margin-left: 0;
      align-items: center;
    }
  }

  .name {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #000000;

    @media (min-width: 768px) {
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
    }
  }

  .name-attr {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    font-weight: bold;
    font-size: 16px;
    line-height: 2px;
    background-color: #fef2b3;
    border-radius: 50%;
    margin-right: 14px;

    @media (min-width: 768px) {
      width: 60px;
      height: 60px;
      font-size: 20px;
      line-height: 29px;
    }

    @media (min-width: 992px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .text-block {
    margin-left: 60px;
    font-size: 12px;
    line-height: 21px;
    color: #4f4f4f;

    @media (min-width: 768px) {
      font-size: 13px;
      line-height: 21px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
    }
  }

  .text {
    margin: 0;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 21px;
    }
  }

  .text span {
    font-size: 13px;
  }

  .text a {
    font-size: 13px;
    color: #4f4f4f;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const StyledTests = styled.div`
  padding: 30px 15px 53px 21px;

  @media (min-width: 992px) {
    padding: 40px;
  }
`;

export const StyledTestsTitle = styled.h3`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  margin-bottom: 16px;

  span {
    font-weight: 400;
  }

  @media (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 20px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 22px;
  }
`;

export const StyledTestsWrapper = styled.div`
  position: relative;
  width: calc(100vw - 66px);
  min-width: 250px;
  overflow-x: auto;
  margin-bottom: 30px;

  @media (min-width: 576px) {
    width: 470px;
  }

  @media (min-width: 768px) {
    width: 650px;
  }

  @media (min-width: 1200px) {
    width: 780px;
    margin-bottom: 35px;
  }
`;

export const StyledTestsSlider = styled.div`
  display: flex;
`;

export const StyledText = styled.p`
  font-size: 13px;
  line-height: 21px;
  color: #4f4f4f;
  margin: 0;

  .show-link {
    text-decoration-line: underline;
    color: #4f4f4f;
    padding-right: 10px;
  }

  @media (min-width: 1200px) {
    font-size: 15px;
    line-height: 21px;
  }
`;

export const StyledTextLink = styled.a`
  margin-left: 10px;
  font-size: 15px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #4f4f4f;
  cursor: pointer;

  &.edit {
    display: none;
  }

  &.no-margin {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    margin-left: 20px;

    &.edit {
      display: block;
    }

    &.no-margin {
      margin-left: 0;
    }
  }
`;

export const StyledInterviewTitleWrap = styled.div`
  display: flex;
`;

export const StyledInterviewStats = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-right: 50px;
  }

  .wrap {
    display: flex;
    align-items: baseline;
    margin-bottom: 7px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      &:nth-child(1) {
        margin-right: 32px;
      }
    }
  }

  dt {
    width: 51px;
    font-size: 12px;
    line-height: 18px;
    color: #828282;
    margin-right: 8px;
    font-weight: 400;
    text-align: left;

    @media (min-width: 768px) {
      width: auto;
    }
  }

  dd {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;

    &.status {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 11px;
      line-height: 20px;
      min-width: 85px;
      border-radius: 3px;
      padding-left: 6px;
      padding-right: 6px;
      height: 22px;
      text-transform: uppercase;

      &.accepted {
        background-color: #27ae60;
      }

      &.rejected {
        background-color: #ff6363;
      }

      @media (max-width: 440px) {
        font-size: 11px;
      }
    }
  }
`;

export const StyledProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;

  & > span {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
    margin-top: 5px;

    @media (max-width: 767px) {
      font-size: 10px;
      line-height: 13px;
    }
  }

  @media (min-width: 768px) {
    width: 67px;
    margin-right: 15px;
  }
`;

export const StyledProgresswrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const StyledVideo = styled.video`
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 768px) {
    height: 350px;
  }
`;

export const StyledTaskWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 10px;

  @media (min-width: 576px) {
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
  }
`;

export const StyledBlock = styled.div`
  margin-bottom: 21px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    margin-bottom: 35px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StyledGridWrap = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  align-items: center;
  grid-row-gap: 10px;
  grid-column-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr min-content;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 50% 50%;
  }
`;

export const StyledAudio = styled.div`
  .rhap_container {
    outline: none;
    padding: 2px 15px;
  }

  .rhap_time {
    font-size: 13px;
    line-height: 21px;

    @media (min-width: 1200px) {
      font-size: 15px;
      line-height: 21px;
    }
  }
`;

export const StyledLoaderWrap = styled.div`
  grid-column: 1 /-1;
`;

export const StyledError = styled.div`
  padding: 20px;
  grid-column: 1 /-1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;

  p {
    margin: 0;
    margin-right: 10px;
  }

  button {
    font: inherit;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;
