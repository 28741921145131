import React from 'react';
import FormHeader from '../FormHeader/FormHeader';
import CompanyForm from '../CompanyForm/CompanyForm';

const EditCompany: React.FunctionComponent = () => {
  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany></FormHeader>
      <CompanyForm action="edit" />
    </>
  );
};

export default EditCompany;
