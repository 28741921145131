import * as MainPageActionCreators from './mainPage';
import * as VacanciesActionCreators from './vacancies';
import * as CompanyActionCreators from './companies';
import * as AuthActionCreators from './auth';
import * as AutomaticCreators from './automatic';
import * as ConnectorB24Creators from './connectorB24';

export default {
  ...MainPageActionCreators,
  ...VacanciesActionCreators,
  ...CompanyActionCreators,
  ...AuthActionCreators,
  ...AutomaticCreators,
  ...ConnectorB24Creators,
};
