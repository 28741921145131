import React from 'react';
import styled from 'styled-components';
import StyledLink from '../Template/StyledComponents/StyledLink';

const StyledWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 25px;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  align-items: center;
  margin-bottom: 50px;

  &.row {
    position: relative;
  }

  &.row .pagination {
    @media (min-width: 768px) {
      position: absolute;
      bottom: 0;
      right: 15px;
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  margin-right: -30px;

  .pagination-btn {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: #e0e0e0;
    }
  }

  .output {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    text-align: center;
    padding: 6px 3px;
    font-size: 15px;
    line-height: 21px;
    margin-right: 10px;
  }

  .rest {
    font-size: 12px;
    line-height: 18px;
    color: #4f4f4f;
  }
`;

interface IPagProps {
  handleChangeCount: (val: number) => void;
  count: number;
  companiesPage: number;
  row?: boolean;
}
const Pagination: React.FC<IPagProps> = ({
  handleChangeCount,
  count,
  companiesPage,
  row,
}) => {
  return (
    <StyledWrapper className={row ? 'container row' : 'container'}>
      <StyledLink
        button
        text="Следующая страница"
        type="button"
        handleСlick={() => handleChangeCount(1)}
        inlineStyles={{
          maxWidth: 288,
        }}
      />
      <StyledPagination className="pagination">
        <button
          onClick={() => handleChangeCount(-1)}
          type="button"
          className="pagination-btn"
          aria-hidden="true"
          aria-label="предыдущая страница"
        >
          <svg
            width="6"
            height="10"
            fill="none"
            viewBox="0 0 6 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 1C4.68 1.32 2.2 3.8 1 5L5 9"
              stroke="#BDBDBD"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <span className="output">{count + 1}</span>
        <button
          type="button"
          onClick={() => handleChangeCount(1)}
          className="pagination-btn"
          aria-hidden="true"
          aria-label="следующая страница"
        >
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1C1.32 1.32 3.8 3.8 5 5L1 9"
              stroke="#828282"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="rest">
          из <span>{companiesPage}</span>
        </div>
      </StyledPagination>
    </StyledWrapper>
  );
};
export default Pagination;
