import { combineReducers } from 'redux';
import mainDataReducer from './reducers/mainData';
import vacanciesReducer from './reducers/vacancies';
import companiesReducer from './reducers/companies';
import authReducer from './reducers/auth';
import automaticReducer from './reducers/automatic';
import connectorB24Reducer from './reducers/connectorB24';

const reducer = combineReducers({
  mainData: mainDataReducer,
  vacancies: vacanciesReducer,
  companies: companiesReducer,
  auth: authReducer,
  automatic: automaticReducer,
  connectorB24: connectorB24Reducer,
});

export default reducer;
export type RootState = ReturnType<typeof reducer>;
