import styled from 'styled-components';
import stickyOn from '../../../assets/image/sticky-on.svg';

export const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #000000;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 22px;
  }
`;

export const StyledBlock = styled.div`
  margin-bottom: 60px;
`;

export const StyledGrayWrapper = styled.div`
  position: relative;
  background: #f3f3f7;
  border-radius: 10px;
  padding: 24px;
  padding-left: 101px;
  margin-bottom: 24px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 24px;
    left: 24px;
    width: 58px;
    height: 58px;
    background-image: url(${stickyOn});
    background-repeat: no-repeat;
    background-position: center;
  }

  .title {
    margin: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 6px;
  }

  .text {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #828282;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .link {
    color: #3362a3;
    text-decoration: none;
  }

  @media (max-width: 576px) {
    padding: 86px 16px 34px 16px;
    flex-direction: column;

    &::before {
      top: 16px;
      left: 16px;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const StyledLinkWrap = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;
