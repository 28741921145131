import * as React from 'react';
import { IntegrationService } from '../../../types/companies';
import IntegrationCard from './IntergrationCard/IntegrationCard';
import * as Styled from './StyledIntergration';
import bitrixLogo from '../../../assets/image/bitrix-logo.png';
import hhLogo from '../../../assets/image/hh-logo.png';
import bitrixBg from '../../../assets/image/bitrix-bg.jpg';
import hhBg from '../../../assets/image/hh-bg.jpg';
import FormHeader from '../FormHeader/FormHeader';

interface IntegrationProps {}

const IntegrationServices: IntegrationService[] = [
  {
    path: '/bitrix',
    name: 'БИТРИКС 24',
    text:
      'Интеграция позволяет синхронизировать ваши вакансии в системе и в CRM Битрикс24',
    color: '#D0E5FA',
    logo: bitrixLogo,
    background: bitrixBg,
  },
  {
    path: '/hh',
    name: 'HH.RU',
    text:
      'Интеграция позволяет синхронизировать ваши вакансии в системе и на сайте HH.RU',
    color: '#EED4DD',
    logo: hhLogo,
    background: hhBg,
  },
];

const Integration: React.FunctionComponent<IntegrationProps> = () => {
  return (
    <>
      <FormHeader text="Редактировать компанию" editCompany></FormHeader>
      <Styled.Integration className="container">
        <Styled.IntegrationTitle>Интеграция</Styled.IntegrationTitle>
        <Styled.IntegrationWrapper>
          {IntegrationServices.map((item: IntegrationService) => (
            <IntegrationCard service={item} key={item.name} />
          ))}
        </Styled.IntegrationWrapper>
      </Styled.Integration>
    </>
  );
};

export default Integration;
