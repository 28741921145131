import styled from 'styled-components';

export const ButtonWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 290px;

  & > div {
    position: absolute;
    right: 40px;
    top: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px) {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .error {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #ff6363;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
`;
