import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';
import '../../Template/Calendar/calendar.scss';
import { Russian } from 'flatpickr/dist/l10n/ru';
import moment from 'moment';
import { useActions } from '../../../hooks/useActions';
import { CVS, InterviewState } from '../../../types/vacancies';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import useTypedSelector from '../../../hooks/useTypedSelector';
import { setRegisterInterviewCV } from '../../../store/actions/vacancies';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import timeIcon from '../../../assets/image/time-icon.svg';

const StyledColumn = styled.div`
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    &.column-time {
      max-width: 100%;
    }
  }
`;

const StyledBtnWrap = styled.div`
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 100%;
  }
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin: 0;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const StyledSubtitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  & ~ input {
    display: block;
    font: inherit;
    background-color: transparent;
    text-shadow: none;
    border: 1px solid #e0e0e0;
    outline: none;
    padding: 9px 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 212px;
    margin-bottom: 10px;
  }
`;

const StyledRadioItem = styled.div`
  label {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 9px 15px;
    padding-right: 50px;
    transition: border-color 0.5s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 100%;
      background-image: url(${timeIcon});
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.4;
    }

    @media (min-width: 768px) {
      font-size: 15px;
    }

    &:hover,
    &:focus {
      border-color: #fedc2d;
    }
  }

  input:checked ~ label {
    border-color: #fedc2d;
  }
`;

const VacancyCVsInvite: React.FC<{
  item: CVS;
  handleModal: (e: boolean) => void;
}> = ({ item, handleModal }) => {
  const { fetchSvInterview } = useActions();
  const { interviews } = useTypedSelector(r => r.vacancies.vacancy.interviews);
  const [date, setDate] = useState(moment().format('YYYYMMDD'));
  const [records, setRecords] = useState([]);
  const [recordDate, setRecordDate] = useState({
    interview: '',
    date: '',
  });
  const [period, setPeriod] = useState({
    from: moment()
      .startOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
    to: moment()
      .endOf('month')
      .format(DateTimeFormats.DATE_FORMAT_API),
  });

  useEffect(() => {
    fetchSvInterview(
      item.vacancy_id,
      {
        today: false,
      },
      period,
    );
  }, [period]);

  const handleRegisterCV = () => {
    setRegisterInterviewCV(recordDate.interview, item.id)
      .then(() => {
        handleModal(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    if (interviews[date]) {
      setRecords(interviews[date]);
    } else {
      setRecords([]);
    }
  }, [date]);

  return (
    <div className="container add-interview">
      <StyledTitle>Назначить собеседование</StyledTitle>
      <div className="row">
        <StyledColumn className="col-sm-6 mb-3">
          <StyledSubtitle>Выберите дату:</StyledSubtitle>
          <Flatpickr
            onChange={(dates, currentDateString) => {
              setDate(
                moment(
                  currentDateString,
                  DateTimeFormats.DATE_FORMAT_API,
                ).format('YYYYMMDD'),
              );
            }}
            onDayCreate={(dates, currentDateString, self, d) => {
              const iter = moment(
                d.dateObj,
                DateTimeFormats.DATE_FORMAT_API,
              ).format('YYYYMMDD');
              let classProp;
              // if (interviews[iter] && moment(d.dateObj).isAfter(moment())) {
              //   // eslint-disable-next-line no-param-reassign
              //   d.className += ' tempYellow';
              // }

              if (interviews[iter] && moment(d.dateObj).isAfter(moment())) {
                classProp = 'tempYellow';
              } else {
                classProp = 'disabled';
              }
              // eslint-disable-next-line no-param-reassign
              d.className += ` ${classProp}`;
            }}
            onMonthChange={(dates, currentDateString, d) => {
              setPeriod({
                from: moment(
                  `${d.currentMonth + 1}.${d.currentYear}`,
                  'MM.YYYY',
                )
                  .startOf('month')
                  .format(DateTimeFormats.DATE_FORMAT_API),
                to: moment(`${d.currentMonth + 1}.${d.currentYear}`, 'MM.YYYY')
                  .endOf('month')
                  .format(DateTimeFormats.DATE_FORMAT_API),
              });
            }}
            options={{
              locale: Russian,
              dateFormat: 'd.m.Y',
              mode: 'single',
              inline: true,
              defaultDate: moment().format(DateTimeFormats.DATE_FORMAT_API),
            }}
          />
        </StyledColumn>
        <StyledColumn className="col-sm-6 mb-3 column-time">
          <StyledSubtitle>Выберите время:</StyledSubtitle>
          <div>
            {records.map((i: InterviewState) => (
              <Fragment key={i.id}>
                {moment().unix() < Number(i.date) && (
                  <>
                    <StyledRadioItem>
                      <input
                        className="visually-hidden"
                        type="radio"
                        name="recordDate"
                        id={moment
                          .unix(Number(i.date))
                          .format(DateTimeFormats.TIME_FORMAT_API)}
                        onChange={e => {
                          setRecordDate({
                            interview: i.id,
                            date: e.target.value,
                          });
                        }}
                        disabled={i.seekers.length >= Number(i.max_place)}
                        checked={
                          recordDate.date ===
                          moment
                            .unix(Number(i.date))
                            .format(DateTimeFormats.TIME_FORMAT_API)
                        }
                        value={moment
                          .unix(Number(i.date))
                          .format(DateTimeFormats.TIME_FORMAT_API)}
                      />
                      <label
                        htmlFor={moment
                          .unix(Number(i.date))
                          .format(DateTimeFormats.TIME_FORMAT_API)}
                      >
                        {moment
                          .unix(Number(i.date))
                          .format(DateTimeFormats.TIME_FORMAT_API)}
                      </label>
                    </StyledRadioItem>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </StyledColumn>
      </div>
      <StyledBtnWrap>
        <StyledLink
          button
          type="button"
          handleСlick={handleRegisterCV}
          text="Назначить собеседование"
        ></StyledLink>
      </StyledBtnWrap>
    </div>
  );
};

export default VacancyCVsInvite;
