import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import VacancyAutomatic from './VacancyAutomatic';
import VacancyModules from './VacancyModules';
import VacancyProcess from './VacancyProcess';
import VacancyTestTask from './VacancyTestTask';
import VacancyTestSkills from './VacancyTestSkills';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import {
  IAddFiles,
  IFiles,
  ISelectType,
  ISelectVal,
  IStageItem,
  VacancyActionsI,
} from '../../../types/vacancies';
import {
  fetchStageVacancy,
  getTrialWork,
  sendVacancyAutoStage,
  sendVacancyTests,
  setTestingQuestion,
} from '../../../store/actions/vacancies';
import { dragVal, getItems } from './DragDrop/Dragable';
import useTypedSelector from '../../../hooks/useTypedSelector';

const StyledContainer = styled.div`
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 36px;
  }
`;

const StyledStickySection = styled.section`
  position: sticky;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  justify-content: space-between;
  top: 92px;
  left: 0;
  padding-bottom: 20px;

  @media (max-width: 991px) {
    position: static;
    top: 0;
    height: auto;
    margin-bottom: 30px;
  }
`;

const StyledStickyLink = styled.a`
  display: block;
  padding: 13px 16px;
  border: 1px solid #cdcede;
  border-radius: 6px;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #333;
  min-height: 52px;
  margin-bottom: 15px;
  transition: all 0.5s;

  &:hover,
  &:focus {
    background: #faf4c0;
    border-color: #faf4c0;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
    margin-bottom: 12px;
  }
`;

const StyledLinkWrap = styled.div`
  margin-top: auto;
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledModalWrap = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getItemsVal = (val: IStageItem[]) => {
  return val.map((k: IStageItem, i: number) => {
    const valueItem = dragVal.find(n => n.value === k.code) as any;
    return {
      id: `item-${i}-${new Date().getTime()}`,
      content: `item ${i}`,
      title: valueItem.text,
      value: k.code,
    };
  });
};

const VacancyActions: React.FunctionComponent<{ id: number | string }> = ({
  id,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { vacancy } = useTypedSelector(r => r.vacancies);
  const [formActions, setFormActions] = useState<VacancyActionsI>({
    testTask: {
      name: '',
      time_limit: '',
      add_files: [],
      files: [],
      task_details: '',
      task_details_2: '',
      task_details_3: '',
    },
  });
  const [select, setSelect] = useState<{
    testing: number[];
    avTesting: number[];
  }>({
    testing: [],
    avTesting: [],
  });

  const [todoValues, setValue] = useState<any>([[], getItems(dragVal)]);
  const [fieldText, setFileText] = useState<string | undefined>();
  const stages = todoValues[0].map((n: { [key: string]: string }) => n.value);

  useEffect(() => {
    if (vacancy.stage.length !== 0) {
      const todo1 = getItemsVal(vacancy.stage);
      const todo2 = todoValues[1];
      const res = todo2.filter(
        (per: { [key: string]: string }) =>
          !todo1.find((x: { [key: string]: string }) => per.value === x.value),
      );

      setValue([todo1, res]);
    }
  }, [vacancy.stage]);

  useEffect(() => {
    if (!fieldText)
      (async () => {
        await getTrialWork(id).then((e: any) => {
          setFormActions(prevState => {
            return {
              ...prevState,
              testTask: { add_files: [], ...e.data },
            };
          });
        });
      })();
  }, []);

  useEffect(() => {
    dispatch(fetchStageVacancy(id));
  }, [id]);

  const handleSetFormActions = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof VacancyActionsI,
  ) => {
    setFormActions((prevState: VacancyActionsI) => {
      return {
        ...prevState,
        [type]: {
          ...prevState[type],
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const handleSetFiles = (fil: IAddFiles[]) => {
    setFormActions(prevState => {
      return {
        ...prevState,
        testTask: {
          ...prevState.testTask,
          add_files: [...prevState.testTask.add_files, ...fil],
        },
      };
    });
  };

  const deleteItemFunction = (fileName: string) => {
    setFormActions((prevState: VacancyActionsI) => {
      const fil = prevState.testTask.files.filter(
        (n: IFiles) => n.title !== fileName,
      );
      const addFiles = prevState.testTask.add_files.filter(
        (n: IAddFiles) => n.name !== fileName,
      );
      return {
        ...prevState,
        testTask: { ...prevState.testTask, add_files: addFiles, files: fil },
      };
    });
  };

  const submitVacancyAction = () => {
    const result = formActions.testTask;
    if (formActions.testTask.vacancy_id) {
      delete formActions.testTask.vacancy_id;
      delete formActions.testTask.target;
      delete formActions.testTask.description;
      if (formActions.testTask.files) {
        formActions.testTask.files = formActions.testTask.files.map(
          (n: any) => n.id,
        );
      }
      result.time_limit = String(result.time_limit);
    }
    dispatch(sendVacancyTests(result, id));
  };

  const getStage = (type: string): boolean =>
    !!todoValues[0].filter((n: { [key: string]: string }) => n.value === type)
      .length;
  const isTesting = getStage('TESTING');
  const isVideoTesting = getStage('AVTESTING');
  const isTrialWork = getStage('TRIALWORK');

  const submitActions = () => {
    if (isTrialWork) {
      submitVacancyAction();
    }
    if (select.testing.length !== 0) {
      dispatch(setTestingQuestion(id, select.testing, true));
    }
    if (select.avTesting.length !== 0) {
      dispatch(setTestingQuestion(id, select.avTesting, false));
    }

    dispatch(sendVacancyAutoStage(stages, id));
    setModal(true);
  };

  function sendId(val: ISelectType, avTesting = false) {
    const value = val.map((n: ISelectVal) => n.value);
    setSelect((prev: any) => {
      if (avTesting) {
        return { ...prev, avTesting: value };
      }
      return { ...prev, testing: value };
    });
  }
  const handleChangeAvTesting = (val: ISelectType) => sendId(val, true);
  const handleChangeTesting = (val: ISelectType) => sendId(val, false);

  return (
    <>
      <StyledContainer className="container">
        <div className="row">
          <div className="col-lg-3">
            <StyledStickySection>
              <div>
                <StyledStickyLink href="#automatic">
                  Автоматическое управление
                </StyledStickyLink>
                <StyledStickyLink href="#connection">
                  Подключение модулей
                </StyledStickyLink>
                <StyledStickyLink href="#setting">
                  Настройки процесса найма
                </StyledStickyLink>
                <StyledStickyLink href="#test-work">
                  Тестовое задание
                </StyledStickyLink>
                <StyledStickyLink href="#testing">
                  Тестирование навыков
                </StyledStickyLink>
              </div>
              <StyledLinkWrap>
                <StyledLink
                  text="Сохранить изменения"
                  button
                  type="button"
                  handleСlick={submitActions}
                />
              </StyledLinkWrap>
            </StyledStickySection>
          </div>
          <div className="col-lg-9">
            <VacancyAutomatic vacancyId={id} />
            {/*<VacancyModules />*/}
            <VacancyProcess todoValues={todoValues} setValue={setValue} />
            {isTrialWork && (
              <VacancyTestTask
                deleteItemFunction={deleteItemFunction}
                testTask={formActions.testTask}
                submitActions={submitVacancyAction}
                handleSetFormActions={handleSetFormActions}
                handleSetFiles={handleSetFiles}
              />
            )}
            <VacancyTestSkills
              isTesting={isTesting}
              isVideoTesting={isVideoTesting}
              vacancyId={id}
              handleChangeAvTesting={handleChangeAvTesting}
              handleChangeTesting={handleChangeTesting}
            />
          </div>
        </div>
      </StyledContainer>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <StyledModalWrap>
            <h5 className="text-center">Данные успешно сохранились!</h5>
          </StyledModalWrap>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VacancyActions;
