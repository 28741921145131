import React, { useState } from 'react';
import styled from 'styled-components';
import {
  SeekerReportInfo,
  SeekerReport,
  VacancySeeker,
} from '../../../types/vacancies';
import { HandleModalReport } from './VacancyInterviewsDayItem';
import useTypedSelector from '../../../hooks/useTypedSelector';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import StyledRating from '../../Template/StyledComponents/StyledRating';

const StyledSubtitle = styled.h2`
  margin: 0;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
  }
`;

const StyledCard = styled.div`
  padding: 7px 18px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.16);
  border-radius: 15px;
  margin-bottom: 20px;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    margin-right: 10px;
    background-color: #feea81;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    border-radius: 50%;
  }

  .name {
    margin: 0;
    font-size: 15px;
    line-height: 29px;
  }

  @media (min-width: 992px) {
    padding: 12px 30px;

    .circle {
      width: 44px;
      height: 44px;
      font-size: 15px;
      line-height: 21px;
      margin-right: 16px;
    }

    .name {
      font-size: 17px;
      line-height: 29px;
    }
  }
`;

const StyledText = styled.p`
  margin: 0;
  margin-bottom: 38px;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #4f4f4f;

  a {
    color: #4f4f4f;
    text-decoration: underline;
    font-weight: 600;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

const StyledWrapper = styled.div`
  margin-bottom: 35px;
`;

const StyledTestName = styled.h3`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;

  @media (min-width: 992px) {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

const StyledStatusWrapper = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 84px 84px;
  grid-column-gap: 12px;

  label {
    margin: 0;
    display: block;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    font-weight: 400;
    transition: background-color 0.3s;
  }

  .cancelled {
    border-color: #ff6363;

    &:hover,
    &:focus-visible {
      background-color: #ff6363;
      color: #ffffff;
    }
  }

  .success {
    border-color: #27ae60;

    &:hover,
    &:focus-visible {
      background-color: #27ae60;
      color: #ffffff;
    }
  }

  input:checked ~ label.cancelled {
    background-color: #ff6363;
    color: #ffffff;
  }

  input:checked ~ label.success {
    background-color: #27ae60;
    color: #ffffff;
  }
`;

const StyledRatingWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content;
  grid-row-gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
`;

const VacancyInterviewsAddReport: React.FC<{
  seeker: VacancySeeker;
  handleShowModalReport: HandleModalReport;
  reportInfo: SeekerReportInfo;
}> = ({ seeker, handleShowModalReport, reportInfo }) => {
  const { interviewReport }: { interviewReport: [] } = useTypedSelector(
    r => r.mainData.dictionaries,
  );
  const [report, setReport] = useState<SeekerReport>({
    ID: 0,
    INTERVIEW_ID: seeker.interview_id,
    MARK_1: 0,
    MARK_2: 0,
    MARK_3: 0,
    MARK_4: 0,
    RESUME_ID: seeker.resume_id,
    TEXT: '',
    STATUS: '',
  });

  const handleReport = (name: string, value: string | number) => {
    setReport(prev => {
      return { ...prev, [name]: value };
    });
  };

  if (reportInfo.NAME !== '') {
    return (
      <div>
        <StyledSubtitle>
          Добавьте отчет по пройденному собеседованию с кандидатом
        </StyledSubtitle>
        <StyledCard>
          <span className="circle">
            {reportInfo.NAME.slice(0, 1)}
            {reportInfo.SURNAME.slice(0, 1)}
          </span>
          <p className="name">
            {reportInfo.NAME} {reportInfo.SURNAME}
          </p>
        </StyledCard>
        <StyledText>
          Заполните необходимые данные, что-бы они отразились в{' '}
          <a href="/">анкете кандидата</a>
        </StyledText>
        <StyledWrapper>
          <StyledTestName>Пройденное собеседование</StyledTestName>
          <StyledRatingWrapper>
            {interviewReport.map(
              (item: { id: number; title: string; i: number }) => {
                return (
                  <StyledRating
                    key={item.id}
                    legend={item.title}
                    max={10}
                    name={`MARK_${item.id}`}
                    id={item.id}
                    valueInfo={reportInfo[`MARK_${item.id}`]}
                    disabled
                  />
                );
              },
            )}
          </StyledRatingWrapper>
        </StyledWrapper>
        <StyledWrapper>
          <StyledTestName>
            Статус кандидата при приеме на должность
          </StyledTestName>
          <StyledStatusWrapper>
            <div>
              <input
                className="visually-hidden"
                id="success"
                type="radio"
                name="status"
                value="success"
                checked={reportInfo.STATUS === 'Y'}
              />
              <label htmlFor="success" className="success">
                Принят
              </label>
            </div>
            <div>
              <input
                className="visually-hidden"
                id="cancelled"
                type="radio"
                name="status"
                value="cancelled"
                checked={reportInfo.STATUS === 'N'}
              />
              <label htmlFor="cancelled" className="cancelled">
                Отклонен
              </label>
            </div>
          </StyledStatusWrapper>
        </StyledWrapper>
        <StyledWrapper>
          <StyledTestName>Комментарии руководителя</StyledTestName>
          <StyledInput
            textarea
            name="TEXT"
            value={reportInfo.TEXT}
            inlineStyles={{ minHeight: 88 }}
            readOnly
            disabled
          />
        </StyledWrapper>
        <StyledLink
          button
          type="button"
          handleСlick={() => {
            handleShowModalReport.closeWithSave(report);
          }}
          text="Добавить отчет"
          disabled
        />
      </div>
    );
  }
  return (
    <div>
      <StyledSubtitle>
        Добавьте отчет по пройденному собеседованию с кандидатом
      </StyledSubtitle>
      <StyledCard>
        <span className="circle">
          {seeker.first_name.slice(0, 1)}
          {seeker.last_name.slice(0, 1)}
        </span>
        <p className="name">
          {seeker.first_name} {seeker.last_name}
        </p>
      </StyledCard>
      <StyledText>
        Заполните необходимые данные, что-бы они отразились в{' '}
        <a href="/">анкете кандидата</a>
      </StyledText>
      <StyledWrapper>
        <StyledTestName>Пройденное собеседование</StyledTestName>
        <StyledRatingWrapper>
          {interviewReport.map((item: { id: number; title: string }) => (
            <div key={item.id}>
              <StyledRating
                legend={item.title}
                max={10}
                name={`MARK_${item.id}`}
                id={item.id}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleReport(e.target.name, Number(e.target.value));
                }}
              />
            </div>
          ))}
        </StyledRatingWrapper>
      </StyledWrapper>
      <StyledWrapper>
        <StyledTestName>
          Статус кандидата при приеме на должность
        </StyledTestName>
        <StyledStatusWrapper>
          <div>
            <input
              className="visually-hidden"
              id="success"
              type="radio"
              name="STATUS"
              value="Y"
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleReport(e.target.name, e.target.value)
                // eslint-disable-next-line
              }
            />
            <label htmlFor="success" className="success">
              Принят
            </label>
          </div>
          <div>
            <input
              className="visually-hidden"
              id="cancelled"
              type="radio"
              name="STATUS"
              value="N"
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleReport(e.target.name, e.target.value)
                // eslint-disable-next-line
              }
            />
            <label htmlFor="cancelled" className="cancelled">
              Отклонен
            </label>
          </div>
        </StyledStatusWrapper>
      </StyledWrapper>
      <StyledWrapper>
        <StyledTestName>Комментарии руководителя</StyledTestName>
        <StyledInput
          textarea
          name="TEXT"
          value={report.TEXT}
          handleChange={
            (e: React.ChangeEvent<HTMLInputElement>) =>
              handleReport(e.target.name, e.target.value)
            // eslint-disable-next-line
          }
          inlineStyles={{ minHeight: 88 }}
        />
      </StyledWrapper>
      <StyledLink
        button
        type="button"
        handleСlick={() => {
          handleShowModalReport.closeWithSave(report);
        }}
        text="Добавить отчет"
      />
    </div>
  );
};

export default VacancyInterviewsAddReport;
