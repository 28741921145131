export interface Vacancy {
  id: string;
  name: string;
  company_id: string;
  status: 'Y' | 'N';
  url: string;
  currency_code: Currency;
  salary_from: number;
  salary_to: number;
  type_location: number;
  type_schedule: number;
  type_frequency: number;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
  statistic: {
    resume: number;
    finished: number;
  };
}
export type ID = string | number;

export interface VacancyTestTaskI {
  task_details: string;
  task_details_2: string;
  task_details_3: string;
  vacancy_id?: number;
  target?: string;
  description?: string;
  name: string;
  time_limit: number | string;
  add_files: { name: string; base64: string }[];
  files?: any;
}

export interface VacancyActionsI {
  testTask: VacancyTestTaskI;
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
}

export interface NewVacancy {
  status: 'Y' | 'N';
  name: string;
  url: string;
  currency_code: Currency;
  salary_from: number | undefined;
  salary_to: number | undefined;
  type_location: number;
  type_schedule: number;
  type_frequency: number;
  text_conditions: string;
  text_responsibilities: string;
  text_requirements: string;
}

export interface NewVacancyError {
  [key: string]: boolean
}

export interface CVS {
  company_id: string;
  first_name: string;
  last_name: string;
  year_of_birthday: string;
  date_create: string;
  file_id: string;
  id: string;
  last_update: string;
  seeker_id: string;
  ualias_0?: string;
  url: string;
  vacancy_id: string;
}

export interface IkeyValString {
  [key: number]: string;
}
export interface IVacancyProcess {
  todoValues: IkeyValString;
  setValue: (val: IkeyValString) => void;
}
export interface ICVRes extends CVS {
  email: string;
  phone: string | number;
}
export interface ISelectVal {
  value: ID;
  label: string;
}

export interface IStageItem {
  code: string;
  sort: number;
  vacancy_id: number;
}
export interface IFiles {
  id: number;
  title: string;
  url: string;
}
export interface IAddFiles {
  name: string;
  base64: string;
}

export type ISelectType = ISelectVal[];

export interface CVI {
  vid?: string | number;
  id: string;
  vacancy_id: string;
  first_name: string;
  last_name: string;
  url: string;
  year_of_birthday?: string;
  phone: string;
  file?: string;
}

export interface VacancyInterview {
  id: string;
  name: string;
  max_place: string;
  date?: number | string;
  seekers: VacancySeeker[];
}

export interface VacancyInterviewUnixTime {
  id: string;
  name: string;
  last_name: string;
  date: number;
  vacancy_id: string;
  max_place: string;
  seekers: VacancySeeker[];
  responsible_user: string;
}

export interface VacancySeeker {
  vacancy_id: string;
  status: string;
  interview_id: number;
  resume_id: number;
  seeker_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  year_of_birthday: string;
  reported: string;
}

export interface VacancyStatistic {
  resume_load: number | string;
  contact_start: number;
  contact_finish: number;
  test_finish: number;
  task_finish: number;
  interview_finish: number;
}

export interface InterviewState {
  id: string;
  vacancy_id: string;
  date: string;
  responsible_user: string;
  max_place: number;
  seekers: CVS[];
}

export interface SeekerReport {
  ID: number;
  TEXT: string;
  MARK_1: number;
  MARK_2: number;
  MARK_3: number;
  MARK_4: number;
  INTERVIEW_ID: number;
  RESUME_ID: number;
  STATUS: string;
}

export interface SeekerReportInfo extends SeekerReport {
  NAME: string;
  SURNAME: string;
  // eslint-disable-next-line
  [key: `MARK_${number}`]: number;
}

export interface DateTimeInterface {
  date: string;
  time: string;
}

export interface SeekerSummaryReportAvQuestions {
  id: number;
  name: string;
  description: string;
  answer: string;
}

export interface SeekerSummaryReportTesting {
  id: number;
  student_id: number;
  test_id: number;
  result: number;
  max_result: number;
  attempts: number;
  completed: string;
  extra_attempts: number;
  test_name: string;
  course_id: number;
  test_approved: string;
  attempt_limit: number;
  course_name: string;
  linked_lesson_id: number;
  user_name: string;
  user_id: number;
}

export interface SeekerSummaryReportAvTesting {
  id: number;
  title: string;
  questions: SeekerSummaryReportAvQuestions[];
}

export interface SeekerSummaryReportTrialwork {
  vacancy_id: number;
  user_id: number;
  time_performanc: number;
  results: string;
  complexity: number;
  date_finish: string;
}

export interface SeekerSummaryReportInterview {
  id: number;
  text: string; 
  mark_1: number;
  mark_2: number;
  mark_3: number;
  mark_4: number;
  interview_id: number;
  resume_id: number;
  date: string;
}

export interface SeekerSummaryReport {
  testing: SeekerSummaryReportTesting[],
  avtesting: SeekerSummaryReportAvTesting[],
  trialwork: SeekerSummaryReportTrialwork,
  interview: SeekerSummaryReportInterview,
}

export enum VacanciesActionTypes {
  FETCH_SINGLE_VACANCY = 'FETCH_SINGLE_VACANCY',
  FETCH_SINGLE_VACANCY_STATISTIC = 'FETCH_SINGLE_VACANCY_STATISTIC',
  PUT_SINGLE_VACANCY = 'PUT_SINGLE_VACANCY',
  FETCH_LIST_VACANCIES = 'FETCH_LIST_VACANCIES',
  FETCH_LIST_VACANCIES_ARCHIVE = 'FETCH_LIST_VACANCIES_ARCHIVE',
  ERROR_FETCH_SINGLE_VACANCY = 'ERROR_FETCH_SINGLE_VACANCY',
  CLEAR_SINGLE_VACANCY = 'CLEAR_SINGLE_VACANCY',
  FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY = 'FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY',
  FETCH_SINGLE_VACANCY_INTERVIEWS_NEXT_DAY = 'FETCH_SINGLE_VACANCY_INTERVIEWS_NEXT_DAY',
  FETCH_SINGLE_VACANCY_INTERVIEWS = 'FETCH_SINGLE_VACANCY_INTERVIEWS',
  FETCH_SINGLE_VACANCY_CVS = 'FETCH_SINGLE_VACANCY_CVS',
  PUT_SINGLE_VACANCY_CV = 'FETCH_SINGLE_VACANCY_CV',
  PUT_SINGLE_VACANCY_INTERVIEW = 'PUT_SINGLE_VACANCY_INTERVIEW',
  FETCH_STAGE_VACANCY = 'FETCH_STAGE_VACANCY',
  FETCH_TESTING_DATA_LIST = 'FETCH_TESTING_DATA_LIST',
  FETCH_AV_TESTING_DATA_LIST = 'FETCH_AV_TESTING_DATA_LIST',
  FETCH_TESTING_VACANCY = 'FETCH_TESTING_VACANCY',
  FETCH_AV_TESTING_VACANCY = 'FETCH_AV_TESTING_VACANCY',
  FETCH_SUMMARY_REPORT = 'FETCH_SUMMARY_REPORT',
}

interface FetchSingleVacancy {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY;
  payload: {
    result: Vacancy;
  };
}
interface FetchTestingVacancyAction {
  type: VacanciesActionTypes.FETCH_TESTING_VACANCY;
  payload: {
    result: Vacancy;
  };
}
interface FetchAvTestingVacancyAction {
  type: VacanciesActionTypes.FETCH_AV_TESTING_VACANCY;
  payload: {
    result: Vacancy;
  };
}
interface FetchTestingDataListAction {
  type: VacanciesActionTypes.FETCH_TESTING_DATA_LIST;
  payload: {
    result: any[];
  };
}
interface FetchAvTestingDataListAction {
  type: VacanciesActionTypes.FETCH_AV_TESTING_DATA_LIST;
  payload: any[];
}

interface FetchSingleVacancyStatistic {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_STATISTIC;
  payload: {
    result: VacancyStatistic;
  };
}
interface FetchStageVacancy {
  type: VacanciesActionTypes.FETCH_STAGE_VACANCY;
  payload: string[];
}

interface PutSingleVacancyCV {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY_CV;
  payload: {
    result: CVI;
  };
}

interface PutSingleVacancyInterview {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY_INTERVIEW;
  payload: {
    result: InterviewState;
  };
}

interface PutSingleVacancy {
  type: VacanciesActionTypes.PUT_SINGLE_VACANCY;
  payload: {
    result: Vacancy;
  };
}

interface FetchSingleVacancyCVS {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_CVS;
  payload: {
    result: CVS;
  };
}

interface FetchListVacancies {
  type: VacanciesActionTypes.FETCH_LIST_VACANCIES;
  payload: {
    result: Vacancy[];
  };
}

interface FetchListVacanciesArchive {
  type: VacanciesActionTypes.FETCH_LIST_VACANCIES_ARCHIVE;
  payload: {
    result: Vacancy[];
  };
}

interface FetchSingleVacancyInterViews {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS;
  payload: {
    result: VacancyInterview[];
  };
}

interface FetchSingleVacancyInterViewsToday {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_TODAY;
  payload: {
    result: VacancyInterview[];
  };
}
interface FetchSingleVacancyInterViewsNextDay {
  type: VacanciesActionTypes.FETCH_SINGLE_VACANCY_INTERVIEWS_NEXT_DAY;
  payload: {
    result: VacancyInterview[];
  };
}

interface ClearSingleVacancy {
  type: VacanciesActionTypes.CLEAR_SINGLE_VACANCY;
}

interface ErrorFetchSingleVacancy {
  type: VacanciesActionTypes.ERROR_FETCH_SINGLE_VACANCY;
}

interface FetchSummaryReport {
  type: VacanciesActionTypes.FETCH_SUMMARY_REPORT;
  payload: {
    reports: SeekerSummaryReport;
  };
}

export type VacanciesActions =
  | FetchSingleVacancy
  | PutSingleVacancy
  | FetchSingleVacancyCVS
  | FetchListVacancies
  | FetchListVacanciesArchive
  | ClearSingleVacancy
  | FetchSingleVacancyInterViews
  | FetchSingleVacancyInterViewsToday
  | PutSingleVacancyInterview
  | PutSingleVacancyCV
  | FetchSingleVacancyStatistic
  | ErrorFetchSingleVacancy
  | FetchSingleVacancyInterViewsNextDay
  | FetchStageVacancy
  | FetchTestingDataListAction
  | FetchAvTestingDataListAction
  | FetchTestingVacancyAction
  | FetchAvTestingVacancyAction
  | FetchSummaryReport;
