import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  StyledBlock,
  StyledLinkWrap,
  StyledTitle,
} from './StyledVacancyComponents';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import StyledInput from '../../Template/StyledComponents/StyledInput';
import iconPdf from '../../../assets/image/icon-pdf.svg';
import iconPng from '../../../assets/image/icon-png.svg';
import iconJpg from '../../../assets/image/icon-jpg.svg';
import iconDoc from '../../../assets/image/icon-doc.svg';
import iconXls from '../../../assets/image/icon-xls.svg';
import { VacancyActionsI, VacancyTestTaskI } from '../../../types/vacancies';

const StyledLabel = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  @media (min-width: 992px) {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledColumn = styled.div`
  width: 100%;
  margin-right: 24px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledItemWrap = styled.div`
  margin-bottom: 22px;
`;

const StyledItemCustomWrap = styled.div`
  margin-bottom: 22px;

  p {
    margin: 0;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    @media (min-width: 992px) {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 21px;
    }
  }

  label {
    display: block;
    width: 168px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 14px;
    background: #fedc2d;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      background-color: #ffe600;
    }
  }

  input {
    position: absolute;
    z-index: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const StyledUploadBlock = styled.div`
  margin-bottom: 22px;
  background: #f3f3f7;
  border-radius: 11px;
  height: 225px;
  padding: 8px;

  div {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 20.1562px;
    line-height: 27px;
    color: #cdcede;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #cdcede;
    border-radius: 11px;
    overflow-y: auto;

    &:hover {
      border-color: #a0a2c1;
    }
  }

  span {
    margin-top: 80px;
  }

  .file {
    width: 100%;
    position: relative;
    display: flex;
    padding: 13px;
    align-items: center;
    margin-bottom: 14px;
    background: #f3f3f7;
    border-radius: 11px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #333333;

    img {
      display: block;
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    button {
      display: block;
      background-color: transparent;
      border: none;
      text-shadow: none;
      position: absolute;
      top: 0px;
      right: 15px;
      width: 10px;
      height: 10px;
      color: #ff6363;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
`;

const StyledError = styled.p`
  position: relative;
  font-size: 12px;
  line-height: 14px;
  color: #ff6363;
  margin: 0;
  margin-bottom: 5px;
  margin-top: -10px;

  span {
    display: block;
  }

  button {
    display: block;
    background-color: transparent;
    border: none;
    text-shadow: none;
    position: absolute;
    top: -15px;
    right: 0px;
    width: 10px;
    height: 10px;
    color: #ff6363;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
`;

const VacancyTestTask: React.FunctionComponent<{
  testTask: VacancyTestTaskI;
  handleSetFiles: (files: any) => void;
  submitActions: () => void;
  deleteItemFunction: (val: string) => void;
  handleSetFormActions: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: keyof VacancyActionsI,
  ) => void;
}> = ({
  testTask,
  handleSetFormActions,
  handleSetFiles,
  submitActions,
  deleteItemFunction,
}) => {
  const [fileData, setFileData] = useState([] as any);
  const [error, setError] = useState([] as any);
  const files = [...fileData];
  const [realFiles, setRealFiles] = useState({} as FileList);
  let errors = [...error];
  const FILE_TYPES = ['pdf', 'png', 'jpg', 'jpeg', 'xls', 'doc'];

  const filS =
    testTask.files && testTask.files.map((n: any) => (n ? n.title : 'ad.png'));

  useEffect(() => {
    const filAdd = testTask.add_files.map((n: any) => n.name);

    const res = [...filAdd, ...filS];
    setFileData(res);
  }, [testTask]);

  const checkDataType = (data: string) => {
    return FILE_TYPES.some(it => {
      return data.endsWith(it);
    });
  };

  const dropHandler = (e: React.DragEvent) => {
    e.preventDefault();
    for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
      if (e.dataTransfer.items[i].kind === 'file') {
        const el = e.dataTransfer.items[i].getAsFile();

        if (el !== null) {
          if (checkDataType(el.name)) {
            files.push(el.name);
          } else {
            errors.push(`${el.name} - данный тип файлa не поддерживается`);
          }
        }
      }
    }

    setFileData(files);
    setError(errors);
  };

  const dragOverHandler = (e: React.DragEvent) => {
    e.preventDefault();
  };
  const realFilesSetter = (e: any) => {
    const fls = e.target.files;
    setRealFiles(fls);
  };
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      for (let i = 0; i < e.target.files.length; i += 1) {
        if (checkDataType(e.target.files[i].name)) {
          files.push(e.target.files[i].name);
        } else {
          errors.push(
            `${e.target.files[i].name} - данный тип файлa не поддерживается`,
          );
        }
      }
      realFilesSetter(e);
      setFileData(files);
      setError(errors);
    }
  };

  const deleteItem = (e: React.MouseEvent<HTMLButtonElement>, val: any) => {
    deleteItemFunction(val);
  };

  const clearError = (e: React.MouseEvent<HTMLButtonElement>) => {
    errors = [];

    setError(errors);
  };

  const getBase64 = (file: Blob | any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const makeFiles = (filesList: FileList) => {
    return Object.keys(filesList).map(async key => {
      return getBase64(filesList[Number(key)]).then(rb => {
        return {
          name: filesList[Number(key)].name,
          body: rb,
        };
      });
    });
  };

  useEffect(() => {
    if (Object.keys(realFiles).length > 0) {
      Promise.all(makeFiles(realFiles)).then(r => {
        handleSetFiles(r);
      });
    }
  }, [realFiles]);

  return (
    <StyledBlock id="test-work">
      <StyledTitle>Тестовое задание</StyledTitle>
      <StyledWrapper>
        <StyledColumn>
          <StyledItemWrap>
            <StyledLabel htmlFor="name">Название задачи</StyledLabel>
            <StyledInput
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFormActions(e, 'testTask');
              }}
              id="task-name"
              type="text"
              name="name"
              placeholder="Написать статью"
              input
              value={testTask.name}
            />
          </StyledItemWrap>
          <StyledItemWrap>
            <StyledLabel htmlFor="time_limit">
              Время выполнения (в минутах)
            </StyledLabel>
            <StyledInput
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFormActions(e, 'testTask');
              }}
              id="time_limit"
              value={testTask.time_limit}
              type="text"
              name="time_limit"
              placeholder="45"
              input
            />
          </StyledItemWrap>
          <form encType="multipart/form-data">
            <StyledItemCustomWrap>
              <p className="label">Загрузка приложений</p>
              <input
                className="visually-hidden"
                type="file"
                id="upload-block-1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeHandler(e);
                }}
                accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .xls"
              />
              <label htmlFor="upload-block-1">+ Добавить файл</label>
            </StyledItemCustomWrap>
            <StyledUploadBlock>
              <div
                onDrop={(e: React.DragEvent) => {
                  dropHandler(e);
                }}
                onDragOver={(e: React.DragEvent) => {
                  dragOverHandler(e);
                }}
              >
                {fileData &&
                  fileData.map((item: string) => {
                    if (item) {
                      let icon;
                      if (item.endsWith('pdf')) {
                        icon = iconPdf;
                      } else if (item.endsWith('png')) {
                        icon = iconPng;
                      } else if (
                        item.endsWith('jpg') ||
                        item.endsWith('jpeg')
                      ) {
                        icon = iconJpg;
                      } else if (item.endsWith('doc')) {
                        icon = iconDoc;
                      } else if (item.endsWith('xls')) {
                        icon = iconXls;
                      }
                      return (
                        <p className="file" key={item} data-key={item}>
                          <img src={icon} alt="Иконка" />
                          {item}
                          <button
                            type="button"
                            aria-label="удалить"
                            onClick={(
                              e: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                              deleteItem(e, item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </button>
                        </p>
                      );
                    }
                    return undefined;
                  })}
                {fileData.length === 0 && <span>Или перетащите файл сюда</span>}
              </div>
            </StyledUploadBlock>

            {error.length !== 0 && (
              <StyledError>
                {error.map((item: string) => {
                  return <span>{item}</span>;
                })}
                <button
                  type="button"
                  aria-label="удалить"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    clearError(e);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </StyledError>
            )}
          </form>
        </StyledColumn>
        <StyledColumn>
          <StyledItemWrap>
            <StyledLabel htmlFor="task_details">Название задачи</StyledLabel>
            <StyledInput
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFormActions(e, 'testTask');
              }}
              id="task_details"
              name="task_details"
              value={testTask.task_details}
              placeholder="Расскажите про основные аспекты задачи"
              textarea
              inlineStyles={{ height: 130 }}
            />
          </StyledItemWrap>
          <StyledItemWrap>
            <StyledLabel htmlFor="task_details_2">Суть задачи</StyledLabel>
            <StyledInput
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFormActions(e, 'testTask');
              }}
              id="task_details_2"
              name="task_details_2"
              value={testTask.task_details_2}
              placeholder="Как должен выглядеть результат?"
              textarea
              inlineStyles={{ height: 130 }}
            />
          </StyledItemWrap>
          <StyledItemWrap>
            <StyledLabel htmlFor="task_details_3">
              Требования к задаче
            </StyledLabel>
            <StyledInput
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFormActions(e, 'testTask');
              }}
              id="task_details_3"
              value={testTask.task_details_3}
              name="task_details_3"
              placeholder="Что обязательно должно присутствовать в работе?"
              textarea
              inlineStyles={{ height: 130 }}
            />
          </StyledItemWrap>
        </StyledColumn>
      </StyledWrapper>
      <StyledLinkWrap>
        <StyledLink text="Сохранить" path="/" />
      </StyledLinkWrap>
    </StyledBlock>
  );
};
export default React.memo(VacancyTestTask);
