export enum AutomaticActionTypes {
  FETCH_AVAILABLE_COUNT = 'FETCH_AVAILABLE_COUNT',
  SET_AUTOLOAN_COUNT = 'SET_AUTOLOAN_COUNT',
}

interface FetchAvailibleCountAction {
  type: AutomaticActionTypes.FETCH_AVAILABLE_COUNT;
  payload: number;
}

interface SetAutoloanCountAction {
  type: AutomaticActionTypes.SET_AUTOLOAN_COUNT;
  payload: number;
}

export type AutomaticActions =
  | FetchAvailibleCountAction
  | SetAutoloanCountAction;
