import React from 'react';
import Loader from 'react-loader-spinner';
import StyledSelect from '../../../../Template/StyledComponents/StyledSelect';
import * as Styled from './StyledIntegrationSelect';

interface IntegrationSelectProps {
  label: string;
  name: string;
  placeholder: string;
  handleChange: (evt: React.FormEvent<HTMLSelectElement>) => void;
  options: [];
  error: string;
  loading: boolean;
  initialValue: string;
}

const IntegrationSelect: React.FunctionComponent<IntegrationSelectProps> = ({
  label,
  name,
  placeholder,
  handleChange,
  options,
  error,
  loading,
  initialValue,
}) => {
  return (
    <Styled.SelectWrapper>
      {loading && <Loader type="Oval" color="#828282" height={25} width={25} />}
      {error && <p className="error">{error}</p>}
      {!loading && !error && options.length !== 0 && (
        <>
          <div>
            <label className="label" htmlFor={name}>
              {label}
            </label>
            <StyledSelect
              id={name}
              name={name}
              defaultValue={initialValue !== '' ? initialValue : 'default'}
              handleChange={handleChange}
            >
              <option value="default" disabled>
                {placeholder}
              </option>
              {options.map((option: { id: number | string; name: string }) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </StyledSelect>
          </div>
          <p className="text">Подсказка</p>
        </>
      )}
    </Styled.SelectWrapper>
  );
};

export default IntegrationSelect;
