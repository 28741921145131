import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import StyledSelect from '../../Template/StyledComponents/StyledSelect';
import StyledLink from '../../Template/StyledComponents/StyledLink';
import useTypedSelector from '../../../hooks/useTypedSelector';
import VacancyInterviewsDay from './VacancyInterviewsDay';
import VacancyInterviewsAdd from './VacancyInterviewsAdd';
import { DateTimeFormats } from '../../../helpers/formatEnum';
import { useActions } from '../../../hooks/useActions';
import { CompanyStaff } from '../../../types/companies';
import {
  getInterviewCVReport,
  setAssignerInterview,
  setInterviewCVReport,
  setInterviewSeekerStatus,
  setPlacesInterview,
} from '../../../store/actions/vacancies';
import {
  SeekerReport,
  VacancySeeker,
  SeekerReportInfo,
} from '../../../types/vacancies';
import VacancyInterviewsAddReport from './VacancyInterviewsAddReport';
import { HandleInterViewSeeker } from './VacancyInterviewsDayItem';
import Loader from '../../Template/Loader/Loader';
import { useFetching } from '../../../hooks/useFetching';
import DataStatus from '../../Common/DataStatus/DataStatus';

const StyledTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-right: 38px;  
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 29px;
  }

  @media (min-width: 992px) {
    font-size: 25px;
    line-height: 29px;
  } 
}
`;

const StyledInterviewLabel = styled.label`
  margin: 0;
  font-size: 15px;
  line-height: 21px;
  color: #4f4f4f;
  margin-right: 27px;

  @media (min-width: 992px) {
    margin-right: 14px;
  }
`;

const StyledLinkWrap = styled.div`
  margin-bottom: 18px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    max-width: 288px;
    margin-right: 10px;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }

  &:last-of-type {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-left: auto;

    &:last-child {
      flex-grow: 1;
    }
  }
`;

const StyledSelectWrap = styled.div`
  margin-bottom: 18px;
  width: 154px;

  @media (min-width: 768px) {
    margin-right: 32px;
  }

  @media (min-width: 991px) {
    margin-bottom: 0px;
  }
`;

const StyledSelectWrap2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  select {
    flex-grow: 1;
  }

  @media (min-width: 768px) {
    max-width: 288px;
  }

  @media (min-width: 991px) {
    margin-left: auto;
  }
`;

const ScheduleWrapper = styled.div`
  display: flex;
  padding: 20px 20px 20px 40px;
  border-radius: 11px;
  background: rgba(205, 206, 222, 0.5);
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 16px;
  }
`;

const ScheduleDateColumn = styled.div`
  flex-shrink: 0;
  width: 15%;
  padding: 10px 0;
  @media (max-width: 991px) {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 11px;
  }

  span {
    color: #000000;
  }
`;

const ScheduleListColumn = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 16px;
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;

  @media (min-width: 991px) {
    grid-row-gap: 20px;
  }
`;

interface Params {
  id: string;
}

enum TypeOfView {
  NEXT_INTERVIEWS = 'Предстоящие',
  ARCHIVE_INTERVIEWS = 'Архивные',
  ALL_INTERVIEWS = 'Все',
}

const VacancyInterviews: React.FunctionComponent<Params> = ({ id }) => {
  const { fetchSvInterview, fetchCompanyStaff } = useActions();
  const { vacancy } = useTypedSelector(r => r.vacancies);
  const { staff } = useTypedSelector(r => r.companies);
  const [type, setType] = useState<TypeOfView>(TypeOfView.ALL_INTERVIEWS);
  const [interviewer, setInterviewer] = useState('');
  const [modalNewRecord, setModalNewRecord] = useState(false);
  const [reportData, setReportData] = useState<VacancySeeker>({
    email: '',
    first_name: '',
    interview_id: 0,
    last_name: '',
    status: '',
    phone: '',
    seeker_id: '',
    resume_id: 0,
    vacancy_id: '',
    year_of_birthday: '',
    reported: '',
  });

  const [reportInfo, setReportInfo] = useState<SeekerReportInfo>({
    NAME: '',
    SURNAME: '',
    ID: 0,
    TEXT: '',
    MARK_1: 0,
    MARK_2: 0,
    MARK_3: 0,
    MARK_4: 0,
    INTERVIEW_ID: 0,
    RESUME_ID: 0,
    STATUS: '',
  });

  const [modalReport, setModalReport] = useState(false);
  const [filteredRequest, setFilteredRequest] = useState({
    date: {
      from: '',
      to: '',
    },
    interviewer,
    fetched: false,
  });

  const [
    fetchSvInterviewNew,
    isInterviewsLoading,
    interviewError,
  ] = useFetching(() =>
    fetchSvInterview(
      id,
      { today: false, interviewer },
      { ...filteredRequest.date },
    ),
  );

  const clearReportData = () => {
    return setReportInfo({
      NAME: '',
      SURNAME: '',
      ID: 0,
      TEXT: '',
      MARK_1: 0,
      MARK_2: 0,
      MARK_3: 0,
      MARK_4: 0,
      INTERVIEW_ID: 0,
      RESUME_ID: 0,
      STATUS: '',
    });
  };

  useEffect(() => {
    switch (type) {
      case TypeOfView.NEXT_INTERVIEWS: {
        setFilteredRequest({
          ...filteredRequest,
          date: {
            from: moment().format(DateTimeFormats.DATE_FORMAT_API),
            to: '',
          },
          fetched: true,
        });
        break;
      }
      case TypeOfView.ARCHIVE_INTERVIEWS: {
        setFilteredRequest({
          ...filteredRequest,
          date: {
            from: '',
            to: moment()
              .subtract(1, 'days')
              .format(DateTimeFormats.DATE_FORMAT_API),
          },
          fetched: true,
        });
        break;
      }
      default: {
        setFilteredRequest({
          ...filteredRequest,
          date: { from: '', to: '' },
          fetched: true,
        });
        break;
      }
    }
  }, [type]);

  useEffect(() => {
    if (filteredRequest.fetched) {
      fetchSvInterview(
        id,
        { today: false, interviewer },
        { ...filteredRequest.date },
      );
      setFilteredRequest(prev => {
        return { ...prev, fetched: false };
      });
    }
  }, [filteredRequest]);

  useEffect(() => {
    fetchCompanyStaff(vacancy.singleVacancy.company_id);
    fetchSvInterviewNew();
    setFilteredRequest({
      ...filteredRequest,
      fetched: false,
    });
  }, []);

  const handleChangeTypeOfView = (value: TypeOfView | any) => {
    setType(value);
  };

  const handleSetInterviewSeeker: HandleInterViewSeeker = (
    status,
    interview_id,
    resume_id,
  ) => {
    setInterviewSeekerStatus(interview_id, resume_id, status).then(() =>
      setFilteredRequest({ ...filteredRequest, fetched: true }),
    );
  };

  const handleChangePlaces = (places: number, interviewId: string) => {
    setPlacesInterview(places, interviewId).then(() => {
      setFilteredRequest({
        ...filteredRequest,
        fetched: true,
      });
    });
  };
  const handleChangeAssigner = (assigner: string, interviewId: string) => {
    setAssignerInterview(assigner, interviewId).then(() => {
      setFilteredRequest({
        ...filteredRequest,
        fetched: true,
      });
    });
  };
  const handleInterviewer = (interviewer_id: string) => {
    setInterviewer(interviewer_id);
    setFilteredRequest({
      ...filteredRequest,
      fetched: true,
    });
  };

  const handleShowModal = {
    show: () => setModalNewRecord(true),
    close: () => setModalNewRecord(false),
    closeWithSave: () => {
      fetchSvInterview(
        id,
        { today: false, interviewer },
        {
          ...filteredRequest.date,
        },
      );
      setModalNewRecord(false);
    },
  };

  const handleShowModalReport = {
    show: (seeker: VacancySeeker) => {
      setReportData(seeker);
      setModalReport(true);
    },
    close: () => {
      setModalReport(false);
      clearReportData();
    },
    closeWithSave: (newReport: SeekerReport) => {
      setInterviewCVReport(
        reportData.interview_id,
        reportData.resume_id,
        newReport,
      ).then(r => {
        setFilteredRequest({ ...filteredRequest, fetched: true });
      });
      setModalReport(false);
    },
    openWithData: (
      name: string,
      surname: string,
      interview_id: number,
      resume_id: number,
    ) => {
      setModalReport(true);
      getInterviewCVReport(interview_id, resume_id).then(r => {
        setReportInfo({
          NAME: name,
          SURNAME: surname,
          ID: r.data.id,
          TEXT: r.data.text,
          MARK_1: r.data.mark_1,
          MARK_2: r.data.mark_2,
          MARK_3: r.data.mark_3,
          MARK_4: r.data.mark_4,
          INTERVIEW_ID: r.data.interview_id,
          RESUME_ID: r.data.resume_id,
          STATUS: r.data.status,
        });
      });
    },
  };

  return (
    <>
      <Modal
        show={modalNewRecord}
        onHide={() => {
          handleShowModal.close();
        }}
        className="modal fade addReport"
      >
        <Modal.Body>
          <VacancyInterviewsAdd handleShowModal={handleShowModal} />
        </Modal.Body>
      </Modal>
      <Modal
        show={modalReport}
        onHide={() => {
          handleShowModalReport.close();
        }}
        className="modal fade addReport"
      >
        <Modal.Body>
          <VacancyInterviewsAddReport
            seeker={reportData}
            handleShowModalReport={handleShowModalReport}
            reportInfo={reportInfo}
          />
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="d-flex flex-column flex-md-row flex-wrap flex-lg-nowrap align-items-lg-center mb-4 mt-4">
          <StyledTitle>График собеседований</StyledTitle>
          <StyledSelectWrap>
            <StyledSelect
              defaultValue={type}
              handleChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const { value } = e.target;
                handleChangeTypeOfView(value);
              }}
              inlineStyles={{
                height: '32px',
                padding: '5px 10px',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#4F4F4F',
                borderColor: '#CDCEDE',
              }}
            >
              <option value={TypeOfView.NEXT_INTERVIEWS}>
                {TypeOfView.NEXT_INTERVIEWS}
              </option>
              <option value={TypeOfView.ARCHIVE_INTERVIEWS}>
                {TypeOfView.ARCHIVE_INTERVIEWS}
              </option>
              <option value={TypeOfView.ALL_INTERVIEWS}>
                {TypeOfView.ALL_INTERVIEWS}
              </option>
            </StyledSelect>
          </StyledSelectWrap>
          <StyledLinkWrap>
            <StyledLink
              text="Добавить событие в график"
              type="button"
              dataToggle="modal"
              dataTarget="#addEvent"
              button
              handleСlick={() => {
                setModalNewRecord(true);
              }}
              inlineStyles={{
                height: '38px',
                padding: '10px',
              }}
            />
          </StyledLinkWrap>
          <StyledSelectWrap2>
            <StyledInterviewLabel htmlFor="select-interviewer">
              Собеседует:
            </StyledInterviewLabel>
            <StyledSelect
              id="select-interviewer"
              name="interviewer"
              value={interviewer}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInterviewer(e.target.value);
              }}
              inlineStyles={{
                height: '32px',
                padding: '5px 10px',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#4F4F4F',
                borderColor: '#CDCEDE',
              }}
            >
              <option value="">Любой сотрудник</option>
              {staff.map((item: CompanyStaff) => (
                <option key={item.user_id} value={item.user_id}>
                  {item.name} {item.last_name}
                </option>
              ))}
            </StyledSelect>
          </StyledSelectWrap2>
        </div>
        <div className="row">
          <StyledWrapper className="col-lg-12">
            <DataStatus
              loading={isInterviewsLoading}
              error={interviewError}
              items={vacancy.interviews.interviews}
            >
              {Object.keys(vacancy.interviews.interviews)
                .sort((a, b) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return b - a;
                })
                .map((i: string) => (
                  <ScheduleWrapper key={i}>
                    <ScheduleDateColumn>
                      <span>
                        {moment(i, 'YYYYMMDD').format(
                          DateTimeFormats.DATE_FORMAT_API,
                        )}
                      </span>
                    </ScheduleDateColumn>
                    <ScheduleListColumn>
                      <VacancyInterviewsDay
                        handleSetInterviewSeeker={handleSetInterviewSeeker}
                        handleModalReport={handleShowModalReport}
                        vacancyDate={i}
                        handleChangePlaces={handleChangePlaces}
                        handleChangeAssigner={handleChangeAssigner}
                      />
                    </ScheduleListColumn>
                  </ScheduleWrapper>
                ))}
            </DataStatus>
            {interviewError && (
              <h4 className="text-center">{interviewError}</h4>
            )}
          </StyledWrapper>
        </div>
      </div>
    </>
  );
};
export default VacancyInterviews;
